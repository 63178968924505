import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Link,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";

// Custom components
import Card from "../../../components/card/Card";
import Menu from "./menu/InvoicesTableMenu";

// Assets
import {
  MdOutlinePendingActions,
  MdOutlineError,
  MdMoreHoriz,
  MdCheckCircle,
} from "react-icons/md";
const RecentInvoicesTable = (props) => {
  const { columnsData, tableData, self } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const getColor = (value) => {
    switch (value) {
      case "pending":
        return "black.500";
      case "paid":
        return "green.500";
      case "overdue":
        return "red.500";
      default:
        return "black.500";
    }
  };

  const getIcon = (value) => {
    switch (value) {
      case "pending":
        return MdOutlinePendingActions;
      case "paid":
        return MdCheckCircle;
      case "overdue":
        return MdOutlineError;
      default:
        return MdMoreHoriz;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 20;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          All Invoices
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, parentIndex) => {
            // console.log("row index", {
            //   parentIndex,
            //   data: tableData[parentIndex].amount,
            // });
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={parentIndex}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Invoice Number") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value.toUpperCase()}
                      </Text>
                      // <Link
                      //   // onClick={() =>
                      //   //   window.open(tableData[parentIndex].url, "_blank")
                      //   // }
                      //   href={tableData[parentIndex].url}
                      //   target="_blank"
                      //   cursor="pointer"
                      //   //color={textColor}
                      //   color="navy.500"
                      //   fontSize="sm"
                      //   fontWeight="700"
                      // >
                      //   {cell.value}
                      // </Link>
                    );
                  } else if (cell.column.Header === "Payable By") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value.toUpperCase()}
                      </Text>
                    );
                  }
                  //  else if (cell.column.Header === "STATUS") {
                  //   data = (
                  //     <Flex align="center">
                  //       <Icon
                  //         w="24px"
                  //         h="24px"
                  //         me="5px"
                  //         color={getColor(cell.value)}
                  //         as={getIcon(cell.value)}
                  //       />
                  //       <Text color={textColor} fontSize="sm" fontWeight="700">
                  //         {cell.value}
                  //       </Text>
                  //     </Flex>
                  //   );
                  //}
                  else if (cell.column.Header === "Created on") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {moment.unix(cell.value).format("DD MMM, YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "Due By") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {moment.unix(cell.value).format("DD MMM, YYYY")}
                      </Text>
                    );
                  }

                  if (cell.column.Header === "Amount") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "Details") {
                    data = (
                      <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="16px"
                        py="5px"
                        onClick={() => window.open(cell.value, "_blank")}
                      >
                        View Invoice
                      </Button>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
};

export default RecentInvoicesTable;
