import React from "react";
import { Box, styled } from "@mui/material";
import CustomModal from "../../../components/CustomModal";
import Text from "../../../components/Text";
import ShipmentForm from "./ShipmentForm";
import { Flex } from "@chakra-ui/react";
import useResponsive from "../../../hooks/useResponsive";
import { useAPIActions } from "../../../hooks/useActions";

const ShipmentModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",
  minWidth: !isMobile && 500,

  width: isMobile && "95vw",

  //commenting it for now
  // [theme.breakpoints.down("500")]: {
  //   width: "90vw",
  // },
}));

const ShipmentModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,
  paddingTop: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const ShipmentModal = ({
  id,
  open,
  setOpen,
  userId,
  onShipmentSuccess,
  organizationId,
  organizationEmail,
  currentUser,
}) => {
  const isMobile = useResponsive("down", "900");

  console.log("ShipmentModal => ", {
    id,
    open,
    setOpen,
    userId,
    onShipmentSuccess,
    organizationId,
    organizationEmail,
    currentUser,
  });

  const { setSnackbarAction } = useAPIActions();
  return (
    <CustomModal open={open} setOpen={setOpen}>
      <ShipmentModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          Create Shipment
        </Text>

        <ShipmentModalContentContainer>
          <ShipmentForm
            currentUser={currentUser}
            onShipmentSuccess={onShipmentSuccess}
            userId={userId}
            organizationId={organizationId}
            organizationEmail={organizationEmail}
            onSuccess={(shipmentId) => {
              setOpen(false);

              onShipmentSuccess(shipmentId);
            }}
          />
        </ShipmentModalContentContainer>
      </ShipmentModalStyle>
    </CustomModal>
  );
};
export default ShipmentModal;
