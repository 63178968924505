export const appRoutes = {
  driverSignIn: "/auth/driver/sign-in",
  driverSignUp: "/auth/driver/sign-up",
  transportSignIn: "/auth/transport/sign-in",
  transportSignUp: "/auth/transport/sign-up",
  organizationSignIn: "/auth/organization/sign-in",
  organizationForgotPassword: "/auth/organization/forgot-password",
  driverForgotPassword: "/auth/driver/forgot-password",
  organizationSignUp: "/auth/organization/sign-up",
  customerSignIn: "/auth/customer/sign-in",
  customerSignUp: "/auth/customer/sign-up",

  app: "/app",
};
