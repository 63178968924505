export default function Alert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: "#388e3c",
          color: "#fff",
        },
        standardError: {
          backgroundColor: "#0A1929",
          color: "white",
        },
        standardWarning: {
          backgroundColor: "#fff",
          color: "#f57c00",
        },
        standardInfo: {
          backgroundColor: "#fff",
          color: "#4fc3f7",
        },
      },
    },
  };
}
