import React from "react";
import { Box, styled } from "@mui/material";
import Text from "../../components/Text";
import CustomModal from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { Avatar, Flex } from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import { useHistory } from "react-router-dom";
import { useAPIActions } from "../../hooks/useActions";
import { SHIPMENT } from "../../redux/constants";
import useResponsive from "../../hooks/useResponsive";

const DriverSearchModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const DriverSearchModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,
  paddingTop: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const DriverSearchModal = ({
  shipmentId,
  open,
  setOpen,
  search,
  organization2,
}) => {
  const { drivers } = useSelector((state) => state.driver);
  const { transport, chats } = useSelector((state) => state.transport);

  const { organizationDrivers, organization } = useSelector(
    (state) => state.organization
  );

  const { currentUser, searchResults } = useSelector((state) => state.ui);
  const isMobile = useResponsive("down", "600");

  const {
    createChatroomOrganizationToDriver,
    updateShipment,
    setShipmentIdToBeAssigned,
  } = useAPIActions();

  const history = useHistory();

  // console.log("drivers", {
  //   organizationDrivers,
  //   currentUser,
  //   searchResults,
  //   search,
  // });

  const onChatClick = (driverId) => {
    setOpen(false);
    if (Object.values(chats).find((item) => item.driver === driverId)) {
      const chatItem = Object.values(chats).find(
        (item) => item.driver === driverId
      );

      console.log("onAssignClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id },
      });
    } else {
      createChatroomOrganizationToDriver(
        organization._id,
        driverId,
        (chatId) => {
          setTimeout(() => {
            history.push({
              pathname: "/organization/chat",
              state: { chatId },
            });
          }, 1000);
        }
      );
    }
  };

  const onAssignClick = (driverId) => {
    setOpen(false);

    setShipmentIdToBeAssigned(shipmentId);
    console.log("chatId", { driverId, chats, transport });
    if (Object.values(chats).find((item) => item.driver === driverId)) {
      const chatItem = Object.values(chats).find(
        (item) => item.driver === driverId
      );

      console.log("onAssignClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id, shipmentId },
      });
    } else {
      createChatroomOrganizationToDriver(
        organization._id,
        driverId,
        (chatId) => {
          setTimeout(() => {
            history.push({
              pathname: "/organization/chat",
              state: { chatId, shipmentId },
            });
          }, 1000);
        }
      );
    }
  };

  const RenderSingleDriver = ({ driver }) => {
    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Flex alignItems="center">
          <Avatar src={driver.profilePicture} border="1px solid #000" mr={3} />
          <Flex flexDirection="column" justifyContent="center">
            <Text>{driver.name}</Text>
            {/* <Text>{driver._id}</Text> */}
          </Flex>
        </Flex>
        {search ? (
          <CustomButton onClick={() => onChatClick(driver._id)}>
            <Text>Chat</Text>
          </CustomButton>
        ) : (
          <CustomButton onClick={() => onAssignClick(driver._id)}>
            <Text>Send Shipment</Text>
          </CustomButton>
        )}
      </Flex>
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <DriverSearchModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          {search ? `Search Results for "${search}"` : "All Drivers"}
        </Text>

        <DriverSearchModalContentContainer>
          {!search && organizationDrivers.length > 0 ? (
            organizationDrivers.map((driver, index) => (
              <RenderSingleDriver key={index} driver={driver} />
            ))
          ) : searchResults.length > 0 ? (
            searchResults.map((driver, index) => (
              <RenderSingleDriver key={index} driver={driver} />
            ))
          ) : (
            <Text center>You have no drivers</Text>
          )}
        </DriverSearchModalContentContainer>
      </DriverSearchModalStyle>
    </CustomModal>
  );
};
export default DriverSearchModal;
