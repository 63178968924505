import {
  CREATE_INVOICE,
  RESET_INVOICE_DATA,
  SET_ALL_INVOICES,
  SET_CURRENT_INVOICE,
} from "../actions/invoiceActions.js";

const initialState = {
  invoices: [],
  invoice: null,
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INVOICE:
      return { ...state, invoices: [...state.invoices, action.payload] };

    case SET_ALL_INVOICES:
      return { ...state, invoices: action.payload };

    case SET_CURRENT_INVOICE:
      return { ...state, invoice: action.payload };

    case RESET_INVOICE_DATA:
      return initialState;

    default:
      return state;
  }
};
