import { Checkbox } from "@mui/material";
import React from "react";

const CustomCheckbox = ({ name, mr, value, setValue, hidden, sx }) => {
  console.log(value);
  return (
    <Checkbox
      color="theme"
      name={name}
      checked={value}
      onChange={(e) => {
        setValue(e.target.checked);
        // console.log(e.target.checked);
      }}
      sx={{
        mr: mr,
        maxWidth: "50px",
        visibility: hidden ? "hidden" : "visible",
        ...sx,
      }}
    />
  );
};

export default CustomCheckbox;
