import * as React from "react"

const Logo = (props) => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135.7 135.7"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#002c49}.cls-2{fill:#fff}"}</style>
    </defs>
    <rect
      width={135.7}
      height={135.7}
      rx={10.52}
      ry={10.52}
      style={{
        fill: "#e2007a",
      }}
    />
    <path
      className="cls-2"
      d="M117.47 107.48 104.15 89.3l12.61-16.61h-12.1l-6.45 9.64h-.07l-6.38-9.64h-13.1l12.4 17.07-12.87 17.72h12.39l6.81-10.46h.1l6.98 10.46h13z"
    />
    <path
      className="cls-1"
      d="M63.22 25.83h11.32v28.65H63.22zM81.16 50.15c1.04 6.28 6.23 11.23 17.9 11.23s17.39-4.83 17.39-11.46c0-5.23-2.55-9.36-15.69-11.71-5.4-.98-6.85-1.74-6.85-3.34s1.45-2.63 4.51-2.63c4.31 0 5.3 1.78 5.69 3.33h10.99c-.45-5.15-5.1-10.49-16.66-10.49-9.5 0-15.98 4.01-15.98 10.89 0 4.58 1.85 8.96 14.69 11.21 6.52 1.13 7.64 2.22 7.64 4.06 0 1.73-1.25 2.94-5.27 2.94-4.53 0-6.32-1.73-7.05-4.03H81.15ZM68.87 72.69a5.661 5.661 0 1 0-.002-11.322 5.661 5.661 0 0 0 .002 11.322"
    />
    <path
      className="cls-2"
      d="M63.22 78.83h11.32v28.65H63.22zM30.47 107.48v-8.46c0-6.83-.02-11.44-.35-15.41h.17c2.07 4.23 10.62 19.04 13.33 23.87h12.44V72.7H45.88v6.14c0 5.75-.01 12.04.3 15.32h-.19c-2.24-4.48-4.84-9-12.55-21.46H20.27v34.78h10.2Z"
    />
    <path
      className="cls-1"
      d="M55.91 25.83H19.6v11.32h12.5v24.23h11.31V37.15h12.5V25.83z"
    />
  </svg>
)

export default Logo