import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { checkUserSignedIn } from "./utils";

const CustomRedirect = ({ from, to }) => {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    (async () => {
      const signedIn = await checkUserSignedIn();

      if (signedIn) setRedirect(signedIn);
      else setRedirect("/auth");
    })();

    return () => {
      setRedirect(null);
    };
  }, []);

  return redirect && <Redirect from={from} to={redirect} />;
};

export default CustomRedirect;
