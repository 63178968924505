import React, { useEffect, useState } from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
// Chakra imports
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import DefaultAuth from "../../../../layouts/auth/Default";
// Assets
import illustration from "../../../../assets/img/logo.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAPIActions } from "../../../../hooks/useActions";
import { useHistory } from "react-router-dom";
import SignInOptions from "../SignInOptions";
import { Box, Link } from "@mui/material";
import Text from "../../../../components/Text";
import SignInForm from "./SignInForm";
import { Colors } from "../../../../components/config";
import { appRoutes } from "../../../../assets/constants";

function DriverSignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);

  const [showContent, setShowContent] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const type = await localStorage.getItem("tisnix_user_signed_in");

      if (!type) setShowContent(true);
      else if (type === "/driver") history.push("/driver/home");
      else setShowContent(true);
    })();
  }, []);
  if (!showContent) return <></>;

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Driver Sign In
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={16}>
            Enter your email and password to sign in
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box mb={2}>
            <SignInForm />
          </Box>

          {/* <Flex
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text>
              Not registered yet?
              <Link
                variant="subtitle2"
                color="text.theme"
                component={RouterLink}
                to={appRoutes.driverSignUp}
              >
                {"   "}
                Create an Account
              </Link>
            </Text>
          </Flex> */}

          <SignInOptions current="driver" />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default DriverSignIn;
