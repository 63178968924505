import {
  VStack,
  Box,
  useColorModeValue,
  Stack,
  Avatar,
  AvatarBadge,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import { Colors } from "../../../components/config";
import Text from "../../../components/Text";

const ChatBubble = ({
  message,
  dateSent,
  from,
  selfAvatar,
  otherAvatar,
  isActive,
  onOtherClick,
  onSelfClick,
  name,
  username,
  senderName,
}) => {
  const isMe = from === "me";
  const alignment = isMe ? "flex-end" : "flex-start";
  const bottomRightRadius = isMe ? 0 : 32;
  const bottomLeftRadius = isMe ? 32 : 0;

  const chatDateTextColor = useColorModeValue(
    "blackAlpha.900",
    "whiteAlpha.900"
  );

  console.log("otherAvatar", {
    otherAvatar,
    name,
    dateSent,
    message,
    from,
    senderName,
  });

  return (
    <>
      <VStack mt={6} alignItems={alignment} alignSelf={alignment}>
        <Flex alignItems="center">
          {!isMe && (
            <VStack
              mr={3}
              alignItems={"center"}
              onClick={onOtherClick}
              cursor="pointer"
            >
              <Avatar boxSize="2em" src={otherAvatar}>
                {isActive ? (
                  <AvatarBadge boxSize="1em" bg="green.500" />
                ) : (
                  <AvatarBadge />
                )}
              </Avatar>
            </VStack>
          )}
          <Box
            bg={isMe ? "brand.500" : "gray.200"}
            px={4}
            py={4}
            maxW={80}
            color={isMe ? "white" : "blackAlpha.900"}
            borderTopLeftRadius={32}
            borderTopRightRadius={32}
            borderBottomLeftRadius={bottomLeftRadius}
            borderBottomRightRadius={bottomRightRadius}
          >
            <Text fontSize={14}>{message}</Text>
          </Box>
          {isMe && (
            <VStack
              ml={3}
              alignItems={"center"}
              onClick={onSelfClick}
              cursor="pointer"
            >
              <Avatar boxSize="2em" src={selfAvatar}>
                {isActive ? (
                  <AvatarBadge boxSize="1em" bg="green.500" />
                ) : (
                  <AvatarBadge />
                )}
              </Avatar>
            </VStack>
          )}
        </Flex>
        {name && (
          <Text
            fontSize={12}
            fontWeight={500}
            color={isMe ? "#ff33a0" : Colors.darkgreyText}
          >
            {name}
          </Text>
        )}
        {username && isMe ? (
          <Flex alignItems="center">
            <Text
              fontSize={10}
              color={Colors.darkgreyText}
              // color={isMe ? "#ff33a0" : Colors.darkgreyText}
              mr={0.5}
            >
              {username}
            </Text>
            <Text fontSize={10} color={Colors.darkgreyText}>
              {"-"}
            </Text>
            <Text fontSize={10} color={Colors.darkgreyText} ml={0.5}>
              {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
            </Text>
          </Flex>
        ) : senderName && !isMe ? (
          <Flex alignItems="center">
            <Text
              fontSize={10}
              color={Colors.darkgreyText}
              // color={isMe ? "#ff33a0" : Colors.darkgreyText}
              mr={0.5}
            >
              {senderName}
            </Text>
            <Text fontSize={10} color={Colors.darkgreyText}>
              {"-"}
            </Text>
            <Text fontSize={10} color={Colors.darkgreyText} ml={0.5}>
              {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
            </Text>
          </Flex>
        ) : (
          <Text
            fontSize={10}
            color={Colors.darkgreyText}
            sx={{
              marginTop: "5px !important",
            }}
          >
            {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
          </Text>
        )}

        {name && (
          <Text
            fontSize={10}
            color={Colors.darkgreyText}
            sx={{
              marginTop: "5px !important",
            }}
          >
            {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
          </Text>
        )}
      </VStack>
    </>
  );
};

export default ChatBubble;
