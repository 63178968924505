import React from "react";
import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { Avatar, Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import Text from "../../../components/Text";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal";
import { SHIPMENT } from "../../../redux/constants";
import { useHistory } from "react-router-dom";
import { getShipmentsByStatus } from "../utils";
import Iconify from "../../../components/Iconify";
import { Colors } from "../../../components/config";

const TransportAssignableShipmentsModalStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  minWidth: 500,
  padding: "20px",

  //commenting it for now
  // [theme.breakpoints.down("500")]: {
  //   width: "90vw",
  // },
}));

const TransportAssignableShipmentsModalContentContainer = styled(Box)(
  ({ theme }) => ({
    backgroundColor: "#fff",
    maxHeight: 400,
    paddingTop: 10,

    overflowY: "scroll",
    "::-webkit-scrollbar": {
      display: "none",
    },
  })
);
const TransportAssignableShipmentsModal = ({
  shipmentId,
  open,
  setOpen,
  user,
  driverId,
  onShipmentAssignSuccess,
}) => {
  const { shipments } = useSelector((state) => state.shipment);

  const { updateShipment } = useAPIActions();

  const history = useHistory();
  const { currentUser } = useSelector((state) => state.ui);

  //console.log("shipments", shipments);

  const onAssignClick = (shipmentId) => {
    onShipmentAssignSuccess(shipmentId);
    setOpen(false);

    // updateShipment(
    //   shipmentId,
    //   {
    //     status: SHIPMENT.DRIVER_ASSIGNED,
    //     driver: driverId,
    //   },
    //   currentUser,
    //   () => {
    //     //setShipmentIdToBeAssigned(shipmentId);
    //     onShipmentAssignSuccess(shipmentId);
    //     setOpen(false);
    //   }
    // );
  };

  const RenderSingleShipment = ({ shipment }) => {
    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
        onClick={() => onAssignClick(shipment._id)}
      >
        <Flex alignItems="center">
          <Iconify
            icon="carbon:delivery-truck"
            sx={{ height: 40, width: 40, color: Colors.themeColor, mr: 1 }}
          />
          <Flex flexDirection="column" justifyContent="center">
            <Text fontWeight={600}>Shipment # {shipment.shipmentNumber}</Text>
            <Text fontSize={14}>{shipment.goods.join(",")}</Text>
          </Flex>
        </Flex>
        <CustomButton>
          <Text>Send</Text>
        </CustomButton>
      </Flex>
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <TransportAssignableShipmentsModalStyle>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          Assignable Shipments
        </Text>

        <TransportAssignableShipmentsModalContentContainer>
          {getShipmentsByStatus(shipments, SHIPMENT.ASSIGNED).length > 0 ? (
            getShipmentsByStatus(shipments, SHIPMENT.ASSIGNED).map(
              (shipment, index) => (
                <RenderSingleShipment key={index} shipment={shipment} />
              )
            )
          ) : (
            <Text center fontSize={14} fontWeight={400} mb={2}>
              No Assignable Shipments
            </Text>
          )}
        </TransportAssignableShipmentsModalContentContainer>
      </TransportAssignableShipmentsModalStyle>
    </CustomModal>
  );
};
export default TransportAssignableShipmentsModal;
