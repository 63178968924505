import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  customerActionCreators,
  driverActionCreators,
  organizationActionCreators,
  shipmentActionCreators,
  transportActionCreators,
  uiActionCreators,
  invoiceActionCreators,
} from "../redux";

export const useAPIActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      ...organizationActionCreators,
      ...transportActionCreators,
      ...customerActionCreators,
      ...driverActionCreators,
      ...shipmentActionCreators,
      ...invoiceActionCreators,
      ...uiActionCreators,
    },
    dispatch
  );
};
