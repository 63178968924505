// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import tableDataComplex from "../../../views/app/shipments/variables/tableDataComplex.json";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";
import { useSelector } from "react-redux";
import {
  columnsDataComplex,
  columnsDataComplexForDriver,
} from "../../sharedComponents/home/variables/columnsData";

import { cleanShipmentData } from "../../sharedComponents/home/util";
import { SHIPMENT } from "../../../redux/constants";
import { useAPIActions } from "../../../hooks/useActions";

export default function Shipments() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { shipments } = useSelector((state) => state.shipment);

  const { currentUser } = useSelector((state) => state.ui);
  const { driver } = useSelector((state) => state.driver);

  const { updateShipment, sendShipmentStatusEmail, setSnackbarAction } =
    useAPIActions();

  const getShipmentNotificationContent = (status, shipmentNumber) => {
    if (status === "Picked") {
      return {
        heading: "Shipment Picked",
        message: `Shipment#${shipmentNumber} has been picked by driver`,
      };
    } else if (status === "InTransit") {
      return {
        heading: "Shipment In Transit",
        message: `Shipment#${shipmentNumber} is in transit`,
      };
    } else if (status === "Delivered") {
      return {
        heading: "Shipment Delivered",
        message: `Shipment#${shipmentNumber} has been delivered`,
      };
    }
  };

  const onStatusChangeClick = (shipment, shipmentStatus) => {
    console.log("shipment", shipment);
    console.log("shipmentStatus", shipmentStatus);

    let status = "";
    let emailStatus = "";
    let snackbarStatus = "";

    if (shipmentStatus === "Picked Up") {
      status = SHIPMENT.PICKED;
      emailStatus = "Picked";
      snackbarStatus = "Picked Up";
    } else if (shipmentStatus === "In Transit") {
      status = SHIPMENT.TRANSIT;
      emailStatus = "InTransit";
      snackbarStatus = "In Transit";
    } else if (shipmentStatus === "Delivered") {
      status = SHIPMENT.DELIVERED;
      emailStatus = "Delivered";
      snackbarStatus = "Delivered";
    }

    updateShipment(
      shipment.shipmentId,
      {
        status,
      },
      currentUser,
      (response) => {
        const { heading, message } = getShipmentNotificationContent(
          emailStatus,

          response.shipmentNumber
        );
        sendShipmentStatusEmail(
          {
            shipmentNumber: response.shipmentNumber,
            status: emailStatus,
            to: driver.email,
            customer: response.customer,
            organization2: response?.organization2,
            organization: response.organization,
            notificationData: {
              heading,
              message,
              customer: response.customer,
              organization2: response?.organization2,
              organization: response.organization,
            },
          },
          true
        );

        setSnackbarAction({
          open: true,
          message: `Shipment ${snackbarStatus}`,
          severity: "success",
        });
      }
    );
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Shipments"
          value={shipments.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Active Shipments"
          value={
            shipments.filter(
              (item) =>
                item.status !== SHIPMENT.DELIVERED &&
                item.status !== SHIPMENT.POD_ATTACHED
            ).length
          }
        />
      </SimpleGrid>

      {shipments.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="All Shipments"
            columnsData={columnsDataComplexForDriver}
            tableData={cleanShipmentData(shipments)}
            onStatusChangeClick={onStatusChangeClick}
          />
        </SimpleGrid>
      )}
    </Box>
  );
}
