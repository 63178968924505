import axios from "axios";
import {
  SET_ORGANIZATION,
  SET_ORGANIZATION_USERS,
  SET_ALL_ORGANIZATIONS,
  SET_ORGANIZATION_USER,
  SET_ORGANIZATIONS_CHATS,
  SET_ORGANIZATION_CHATS_MESSAGES,
  SET_ORGANIZATION_CHATS,
  SET_ORGANIZATION_DRIVERS,
  SET_ORGANIZATION_REQUESTS,
  SET_ORGANIZATION_CONNECTIONS,
  SET_GROUP_CHATS,
  SET_GROUP_CHATS_MESSAGES,
} from "../actions/organizationActions";
import { SERVER_URL } from "../constants";
import { APIRequest } from "../utils/axios";
import { setLoadingAction, setSnackbarAction } from "./uiActionCreators";
import socketIO from "socket.io-client";
import {
  SET_CURRENT_USER_UI,
  SET_SEARCH_RESULTS,
  SET_SOCKET,
} from "../actions/uiActions";
import moment from "moment";

let socket;

export const addOrganization =
  (company, file, user, callback) => async (dispatch) => {
    try {
      console.log("company", company);
      console.log("user", user);
      dispatch(setLoadingAction(true));

      let formData = new FormData();
      formData.append("file", file);

      let imageUrlResponse = await axios({
        method: "post",
        url: `${SERVER_URL}/organization/logo-upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("imageUrlResponse", imageUrlResponse);
      if (imageUrlResponse.data) {
        company.logo = imageUrlResponse.data;
        user.profilePicture = imageUrlResponse.data;
      }

      let companyResponse = await APIRequest("/organization", "POST", company);

      console.log("companyResponse", companyResponse);

      let userResponse = await APIRequest(
        `/organization/addUser/${companyResponse._id}`,
        "POST",
        user
      );

      console.log("userResponse", userResponse);

      callback();

      dispatch({
        type: SET_ORGANIZATION,
        payload: companyResponse,
      });
      dispatch({
        type: SET_ORGANIZATION_USERS,
        payload: userResponse,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const updateOrganization =
  (organization, orgInfo, callback) => async (dispatch) => {
    console.log("updateOrganization start => ", organization, orgInfo);
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/${organization}`,
        "PUT",
        orgInfo
      );

      console.log("updateOrganization => ", response);

      if (callback) callback(response);

      dispatch({
        type: SET_ORGANIZATION,
        payload: response,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const makeOrganizationRequest =
  (email, organizationId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/handleOrganizationRequest`,
        "POST",
        {
          email,
          organizationId,
        }
      );
      console.log("makeOrganizationRequest response", response);
      dispatch(getPendingOrganizationRequests(organizationId));
      dispatch(setLoadingAction(false));
      callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const getPendingOrganizationRequests =
  (organizationId, callback) => async (dispatch) => {
    try {
      console.log(
        "getPendingOrganizationRequests organizationId",
        organizationId
      );
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/getPendingOrganizationRequests/${organizationId}`,
        "GET"
      );
      console.log("getPendingOrganizationRequests response", response);

      dispatch({
        type: SET_ORGANIZATION_REQUESTS,
        payload: response,
      });
      dispatch(setLoadingAction(false));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const getOrganizationConnections =
  (organizationId, callback) => async (dispatch) => {
    try {
      console.log("getOrganizationConnections organizationId", organizationId);
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/getOrganizationConnections/${organizationId}`,
        "GET"
      );
      console.log("getOrganizationConnections response", response);

      dispatch({
        type: SET_ORGANIZATION_CONNECTIONS,
        payload: response,
      });
      dispatch(setLoadingAction(false));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const deleteOrganizationConnection =
  (organizationId, organizationConnectionId, callback) => async (dispatch) => {
    try {
      console.log(
        "deleteOrganizationConnection organizationId",
        organizationId,
        organizationConnectionId
      );
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/deleteOrganizationConnection/${organizationId}`,
        "DELETE",
        {
          organizationConnectionId,
        }
      );
      console.log("deleteOrganizationConnection response", response);

      dispatch(getOrganizationConnections(organizationId));

      dispatch(setLoadingAction(false));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const updateOrganizationRequest =
  (requestId, status, organizationId, callback) => async (dispatch) => {
    try {
      console.log("updateOrganizationRequest", {
        requestId,
        status,
      });
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/updateOrganizationRequest/${requestId}`,
        "PUT",
        {
          status,
        }
      );
      console.log("updateOrganizationRequest response", response);

      dispatch(getPendingOrganizationRequests(organizationId));
      dispatch(getOrganizationConnections(organizationId));

      dispatch(setLoadingAction(false));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const getAllOrganizations = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/organization/all", "GET");
    console.log("getAllOrganizations response", response);
    dispatch({
      type: SET_ALL_ORGANIZATIONS,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getUsersByOrganization = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await await APIRequest(
      `/organization/${orgId}/users`,
      "GET"
    );

    console.log("getUsersByOrganization response", response);

    dispatch({
      type: SET_ORGANIZATION_USERS,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getDriversByOrganization = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await await APIRequest(
      `/organization/${orgId}/drivers`,
      "GET"
    );

    console.log("getDriversByOrganization response", response);

    dispatch({
      type: SET_ORGANIZATION_DRIVERS,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getOrganizationUserLinkData = async (linkId) => {
  try {
    let response = await APIRequest(`/organization/link/${linkId}`, "GET");

    console.log("getOrganizationUserLinkData response", response);

    if (response.organization) {
      return {
        organization: response.organization,
        email: response.link.email,
        organizationId: response.link.organizationId,
      };
    }
  } catch (error) {
    console.log(error);
    if (error.message) alert(error.message);
  }
};

export const getOrganizationLinkData = async (linkId) => {
  try {
    let response = await APIRequest(`/organization/request/${linkId}`, "GET");

    console.log("getOrganizationLinkData response", response);

    if (response.link) {
      return {
        email: response.link.email,
      };
    }
  } catch (error) {
    console.log(error);
    if (error.message) alert(error.message);
  }
};

export const getPasswordRequestEmail = async (email) => {
  try {
    let response = await APIRequest(
      `/organization/getPasswordRequestEmail`,
      "POST",
      {
        email,
      }
    );

    console.log("getPasswordRequestEmail response", response);

    // if (response.link) {
    //   return {
    //     email: response.link.email,
    //   };
    // }

    if (response.success) {
      return response;
    }
  } catch (error) {
    console.log(error);

    return {
      error: true,
      message: error?.message || "Something went wrong!",
    };
  }
};

export const resetPassword =
  (email, password, link, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(`/organization/resetPassword`, "POST", {
        email,
        password,
        link,
      });

      console.log("resetPassword response", response);

      // if (response.link) {
      //   return {
      //     email: response.link.email,
      //   };
      // }
      dispatch(setLoadingAction(false));
      if (response.success) {
        callback();
      }
    } catch (error) {
      console.log(error);

      dispatch(setLoadingAction(false));
      return {
        error: true,
        message: error?.message || "Something went wrong!",
      };
    }
  };

export const verifyEmail = (link, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/organization/verifyEmail`, "POST", {
      link,
    });

    console.log("verifyEmail response", response);

    dispatch(setLoadingAction(false));
    if (response.success) {
      callback(true);
    }
  } catch (error) {
    console.log(error);

    dispatch(setLoadingAction(false));
    dispatch(
      setSnackbarAction({
        message: error?.message || "Something went wrong!",
        severity: "error",
      })
    );
    callback(false);
  }
};

export const checkVerification = (email, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(`/organization/checkVerification`, "POST", {
      email,
    });

    console.log("checkVerification response", response);

    dispatch(setLoadingAction(false));
    if (response.success) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log(error);

    dispatch(setLoadingAction(false));
    dispatch(
      setSnackbarAction({
        message: error?.message || "Something went wrong!",
        severity: "error",
      })
    );
  }
};

export const getVerificationEmail = async (email) => {
  try {
    let response = await APIRequest(
      `/organization/getVerificationEmail`,
      "POST",
      {
        email,
      }
    );

    console.log("getVerificationEmail response", response);

    if (response.success) {
      return response;
    }
  } catch (error) {
    console.log(error);

    return {
      error: true,
      message: error?.message || "Something went wrong!",
    };
  }
};

export const getOrganizationDriverLinkData = async (linkId) => {
  try {
    let response = await APIRequest(`/organization/link/${linkId}`, "GET");

    console.log("getOrganizationDriverLinkData response", response);

    if (response.organization) {
      return {
        organization: response.organization,
        email: response.link.email,
        organizationId: response.link.organizationId,
      };
    }
  } catch (error) {
    console.log(error);
    if (error.message) alert(error.message);
  }
};

export const getOrganizationResetPasswordLinkData = async (linkId) => {
  try {
    let response = await APIRequest(
      `/organization/reset-password-link/${linkId}`,
      "GET"
    );

    console.log("getOrganizationResetPasswordLinkData response", response);

    if (response.user && response.link) {
      return response;
    }
  } catch (error) {
    console.log(error);
    // if (error.message) alert(error.message);
    return {
      message: "Link expired or broken",
      error: true,
    };
  }
};

export const getLinkForAddingUserToOrganization =
  (email, organizationId, callback) => async (dispatch) => {
    try {
      console.log("email", email);
      console.log("organizationId", organizationId);

      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/getLinkForAddingUser`,
        "POST",
        {
          email,
          organizationId,
        }
      );

      console.log("getLinkForAddingUserToOrganization response", response);

      if (response) {
        callback(response);
      }
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const getLinkForAddingDriverToOrganization =
  (email, organizationId, callback) => async (dispatch) => {
    try {
      console.log("email", email);
      console.log("organizationId", organizationId);

      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/getLinkForAddingDriver`,
        "POST",
        {
          email,
          organizationId,
        }
      );

      console.log("getLinkForAddingDriverToOrganization response", response);

      if (response) {
        callback(response);
      }
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const updateOrganizationUserPermissions =
  (permissions, userId, callback) => async (dispatch) => {
    try {
      console.log("updateOrganizationUserPermissions", {
        permissions,
        userId,
      });

      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/updateOrganizationUserPermissions/${permissions._id}`,
        "PUT",
        {
          ...permissions,
        }
      );

      console.log("updateOrganizationUserPermissions response", response);

      if (response) {
        callback(response);
      }
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const addUserPermissionsByUserId =
  (permissions, userId, callback) => async (dispatch) => {
    try {
      console.log("addUserPermissionsByUserId", {
        permissions,
        userId,
      });

      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/addUserPermissionsByUserId/${userId}`,
        "PUT",
        {
          ...permissions,
        }
      );

      console.log("addUserPermissionsByUserId response", response);

      if (response) {
        callback(response);
      }
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const getOrganization = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/organization/${orgId}`, "GET");

    console.log("getOrganization", response);
    dispatch({
      type: SET_ORGANIZATION,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getTransportByName = (transportName) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/transport/getTransportByName/${transportName}`,
      "GET"
    );

    console.log("getTransportByName", response);

    if (response) {
      dispatch({
        type: SET_SEARCH_RESULTS,
        payload: [response],
      });
    } else
      dispatch({
        type: SET_SEARCH_RESULTS,
        payload: [],
      });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const addOrganizationUser =
  (orgId, file, user, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      let formData = new FormData();
      formData.append("file", file);

      let imageUrlResponse = await axios({
        method: "post",
        url: `${SERVER_URL}/organization/logo-upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (imageUrlResponse.data) {
        user.profilePicture = imageUrlResponse.data;
      }

      let response = await APIRequest(
        `/organization/addUser/${orgId}`,
        "POST",
        user
      );

      console.log("addOrganizationUser response", response);
      if (callback) callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      setSnackbarAction({
        severity: "error",
        message: error.message || error,
      });
      dispatch(setLoadingAction(false));
    }
  };

export const getAllOrganizationsUsers = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(`/organization/users`, "GET");

    console.log("getAllOrganizationsUsers response", response);

    dispatch({
      type: SET_ORGANIZATION,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const deleteOrganization = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(`/organization/${orgId}`, "DELETE");

    console.log("deleteOrganization response", response);

    dispatch({
      type: SET_ORGANIZATION,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

// Auth

export const loginOrganizationUser =
  (loginInfo, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        "/auth/organization/login",
        "POST",
        loginInfo
      );
      console.log("loginOrganizationUser => ", response);

      await localStorage.setItem(
        "tisnix_user_access_token",
        response.access_token
      );
      await localStorage.setItem(
        "tisnix_user_signed_in",
        JSON.stringify("/organization")
      );

      let user = await APIRequest(
        "/organization/getCurrentUser",
        "GET",
        null,
        true
      );

      console.log("loginOrganizationUser getCurrentUser => ", user);

      await localStorage.setItem("tisnix_user", JSON.stringify(user));

      callback();

      dispatch({
        type: SET_ORGANIZATION_USER,
        payload: user,
      });

      dispatch({
        type: SET_CURRENT_USER_UI,
        payload: {
          id: response._id,
          name: user.name,
          permissions: user.permissions,
          type: "organization",
          role: user.role,
          userId: user._id,
        },
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const getCurrentOrganizationUser = (fallback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let user = await APIRequest(
      "/organization/getCurrentUser",
      "GET",
      null,
      true
    );

    if (!user) {
      dispatch(setLoadingAction(false));
      fallback();
      return;
    }

    let response = await APIRequest(
      `/organization/${user.organizationId}`,
      "GET"
    );

    console.log("getCurrentOrganizationUser", { response, user });

    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: response._id,
        name: user.name,
        permissions: user.permissions,
        type: "organization",
        role: user.role,
        userId: user._id,
        profilePicture: user.profilePicture,
        users: response.users,
      },
    });

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    socket = socketIO(SERVER_URL, {
      query: `userId=${response._id}`,
    });

    console.log("socket", socket);

    socket.on("online", (data) => {
      console.log("online", data);
    });

    dispatch({
      type: SET_ORGANIZATION_USER,
      payload: user,
    });
    dispatch({
      type: SET_ORGANIZATION,
      payload: response,
    });

    dispatch({
      type: SET_SOCKET,
      payload: Boolean(socket),
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    fallback();
    dispatch(setLoadingAction(false));
  }
};

export const createChatroomForOrganization =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomForOrganization", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/organizationToTransport`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomForOrganization ", response);

      dispatch(
        getOrganizationChatRooms(senderId, () => {
          callback(response?.res?._id);
        })
      );

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const createChatroomOrganizationToDriver =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomOrganizationToDriver", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/organizationToDriver`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomOrganizationToDriver ", response);

      dispatch(
        getOrganizationChatRooms(senderId, () => {
          callback(response?.res?._id);
        })
      );

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const createChatroomOrganizationToOrganization =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomOrganizationToOrganization", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/organizationToOrganization`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomOrganizationToOrganization ", response);

      dispatch(
        getOrganizationChatRooms(senderId, () => {
          callback(response?.res?._id);
        })
      );

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getOrganizationChatRooms =
  (organizationId, callback) => async (dispatch) => {
    try {
      console.log("getOrganizationChatRooms", organizationId);
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/chat/all/organization/${organizationId}`,
        "GET",
        null,
        true
      );
      console.log("getOrganizationChatRooms => ", response);

      let chatrooms = response.chats;

      // not a good one but for now
      let receiverResponse = (
        await Promise.all(
          chatrooms.map((chatroom) => {
            if (chatroom.customer) {
              return APIRequest(
                `/customer/${chatroom.customer}`,
                "GET",
                null,
                true
              );
            } else if (chatroom.transport) {
              return APIRequest(
                `/transport/${chatroom.transport}`,
                "GET",
                null,
                true
              );
            } else if (chatroom.driver) {
              return APIRequest(
                `/driver/${chatroom.driver}`,
                "GET",
                null,
                true
              );
            } else if (
              chatroom.organization &&
              chatroom.organization !== organizationId
            ) {
              return APIRequest(
                `/organization/${chatroom.organization}`,
                "GET",
                null,
                true
              );
            } else if (
              chatroom.organization2 &&
              chatroom.organization2 !== organizationId
            ) {
              return APIRequest(
                `/organization/${chatroom.organization2}`,
                "GET",
                null,
                true
              );
            }
          })
        )
      ).filter((item) => item);

      console.log("receiverResponse", receiverResponse);

      let finalChatrooms = {};

      chatrooms.map((chatroom) => {
        receiverResponse.map((receiver) => {
          if (chatroom.customer === receiver._id) {
            chatroom.receiver = receiver;
            finalChatrooms[chatroom._id] = chatroom;
          } else if (chatroom.transport === receiver._id) {
            chatroom.receiver = receiver;
            finalChatrooms[chatroom._id] = chatroom;
          } else if (chatroom.driver === receiver._id) {
            chatroom.receiver = receiver;
            finalChatrooms[chatroom._id] = chatroom;
          } else if (
            chatroom.organization === receiver._id &&
            chatroom.organization !== organizationId
          ) {
            chatroom.receiver = receiver;
            finalChatrooms[chatroom._id] = chatroom;
          } else if (
            chatroom.organization2 === receiver._id &&
            chatroom.organization2 !== organizationId
          ) {
            chatroom.receiver = receiver;
            finalChatrooms[chatroom._id] = chatroom;
          }
        });
      });

      console.log("final chatrooms", finalChatrooms);

      if (callback) callback();

      dispatch({
        type: SET_ORGANIZATION_CHATS,
        payload: finalChatrooms,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getOrganizationChatroomMessages =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);

      let response = await APIRequest(`/chat/${chatroomId}`, "GET", null, true);

      console.log("getChatroomMessages => ", response);

      let payload = {
        chatroomId,
        messages: response,
      };

      dispatch({
        type: SET_ORGANIZATION_CHATS_MESSAGES,
        payload: payload,
      });

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getGroupChatroomMessages =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);

      let response = await APIRequest(
        `/chat/group/${chatroomId}`,
        "GET",
        null,
        true
      );

      console.log("getGroupChatroomMessages => ", response);

      let payload = {
        chatroomId,
        messages: response,
      };

      dispatch({
        type: SET_GROUP_CHATS_MESSAGES,
        payload: payload,
      });

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const addUserToOrganizationChat =
  (chatroomId, userId, organizationId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);
      console.log("userId", userId);

      let response = await APIRequest(
        `/chat/addUserToOrganizationChat/${chatroomId}/${userId}`,
        "PUT",
        null,
        true
      );

      console.log("addUserToOrganizationChat => ", response);

      if (callback) callback();

      dispatch(getOrganizationChatRooms(organizationId));

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: "Error adding user to chat",
        })
      );
      if (callback) callback(true);
    }
  };

export const sendOrganizationChatMessage =
  (
    message,
    senderId,
    receiverId,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoadingAction(true));

      let { chats, chatsMessages } = getState().organization;

      socket.emit("send-message", {
        message,
        senderId,
        receiverId,
        chatroomId,
        type: "cto",
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
        timeStamp: Date.now(),
      });

      let newChatsObject = { ...chats };
      let newChatsMessagesObject = { ...chatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiverId,
        chatroomId,
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        // dispatch({
        //   type: SET_ORGANIZATION_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_ORGANIZATION_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const stopListeningToOrganizationSocketEvents = async () => {
  if (socket) {
    socket.off("receive-message");
    socket.off("receive-group-message");
  }
};

export const listenToOrganizationSocketEvents =
  () => async (dispatch, getState) => {
    try {
      console.log("listenToSocketEvents ", socket);

      socket.on("receive-message", (data) => {
        const { chats, chatsMessages } = getState().organization;
        let {
          message,
          messageType,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        } = data.payload;
        console.log("receive-message", {
          message,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          messageType,
          attachmentUrl,
          shipmentId,
          data,
        });

        let newChatsObject = { ...chats };
        let newChatsMessagesObject = { ...chatsMessages };

        // first check if chatroom exists

        console.log("newChatsObject", newChatsObject[chatroomId]);

        if (newChatsObject[chatroomId]) {
          let chatroom = newChatsObject[chatroomId];
          let chatroomMessages = newChatsMessagesObject[chatroomId];

          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatMessages = [...chatroomMessages, messageObject];

          newChatsObject[chatroomId] = { ...chatroom };

          let payload = {
            chatroomId,
            messages: chatMessages,
          };

          dispatch({
            type: SET_ORGANIZATION_CHATS_MESSAGES,
            payload,
          });
        } else {
          // chatroom does not exist
          // so create a new chatroom

          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };
          let chatroom = {
            chatroomId,
          };
          console.log("new chatroom", chatroom);

          newChatsObject[chatroomId] = chatroom;

          let payload = {
            chatroomId,
            messages: [messageObject],
          };

          dispatch({
            type: SET_ORGANIZATION_CHATS_MESSAGES,
            payload,
          });
        }

        dispatch({
          type: SET_ORGANIZATION_CHATS,
          payload: newChatsObject,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

// Group Chat Routes
export const createGroupChat = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    console.log("createGroupChat => ", data);

    let response = await APIRequest("/chat/groupChat", "POST", data, true);

    console.log("createGroupChat => ", response);

    if (callback) callback(response);

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getAllGroupChats =
  (
    driverId = "111111111111111111111111",
    organizationId = "111111111111111111111111",
    organizationUserId = "111111111111111111111111",
    callback
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("getAllGroupChats => ", {
        organizationId,
        driverId,
        organizationUserId,
      });

      let response = await APIRequest(
        `/chat/allGroup/${driverId}/${organizationId}/${organizationUserId}`,
        "GET",
        null,
        true
      );

      console.log("getAllGroupChats => ", response);

      if (response) {
        let finalChatrooms = {};

        if (response.length > 0) {
          Promise.all(
            response.forEach(async (chatroom) => {
              if (chatroom.drivers.length > 0) {
                chatroom.drivers = Promise.all(
                  chatroom.drivers.map(async (driver) => {
                    let driverData = await APIRequest(
                      `/driver/${driver}`,
                      "GET",
                      null,
                      true
                    );
                    return driverData;
                  })
                );
              }
              finalChatrooms[chatroom._id] = chatroom;
            })
          );
        } else if (Object.keys(response).length > 0) {
          if (response.drivers.length > 0) {
            response.drivers = await Promise.all(
              response.drivers.map(async (driver) => {
                let driverData = await APIRequest(
                  `/driver/${driver}`,
                  "GET",
                  null,
                  true
                );
                return driverData;
              })
            );
          }

          if (response.organizationUsers.length > 0) {
            response.organizationUsers = await Promise.all(
              response.organizationUsers.map(async (organizationUser) => {
                let organizationUsersData = await APIRequest(
                  `/organization/users/${organizationUser}`,
                  "GET",
                  null,
                  true
                );
                return organizationUsersData;
              })
            );
          }
          finalChatrooms[response._id] = response;
        }
        console.log("finalChatrooms", finalChatrooms);

        dispatch({
          type: SET_GROUP_CHATS,
          payload: finalChatrooms,
        });
      }

      if (callback) callback(response);

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const getGroupChatById = (chatroomId, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(
      `/chat/group/${chatroomId}`,
      "GET",
      null,
      true
    );

    console.log("getGroupChatById => ", response);

    if (callback) callback(response);

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const removeMemberFromGroupChat =
  (chatroomId, driverId, userId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("removeMemberFromGroupChat => ", {
        chatroomId,
        driverId,
        userId,
      });

      let response = await APIRequest(
        `/chat/removeUserFromGroup/${chatroomId}/${driverId}/${userId}`,
        "DELETE",
        null,
        true
      );

      console.log("removeMemberFromGroupChat => ", response);

      if (callback) callback(response);

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const deleteGroupChatRoom =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("deleteGroupChatRoom => ", chatroomId);

      let response = await APIRequest(
        `/chat/groupChatroom/${chatroomId}`,
        "DELETE",
        null,
        true
      );

      console.log("deleteGroupChatRoom => ", response);

      if (callback) callback(response);

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };
export const listenToGroupChatMessages =
  (currentUser) => async (dispatch, getState) => {
    try {
      console.log("listenToGroupChatMessages socket => ", socket);
      socket.on("receive-group-message", (data) => {
        const { groupChats, groupChatsMessages } = getState().organization;
        console.log("receive-group-message", data);
        if (data.payload.senderId === currentUser.userId) return;
        let {
          message,
          messageType,
          senderId,
          receiversIds,
          groupChatRoomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        } = data.payload;

        let newChatsObject = { ...groupChats };
        let newChatsMessagesObject = { ...groupChatsMessages };

        // first check if chatroom exists

        console.log("newChatsObject", newChatsObject[groupChatRoomId]);

        if (newChatsObject[groupChatRoomId]) {
          let chatroom = newChatsObject[groupChatRoomId];
          let chatroomMessages = newChatsMessagesObject[groupChatRoomId];

          let messageObject = {
            senderId: senderId,
            receiversIds: receiversIds,
            message: message,
            timeStamp,
            chatroomId: groupChatRoomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatMessages = [...chatroomMessages, messageObject];

          newChatsObject[groupChatRoomId] = { ...chatroom };

          let payload = {
            chatroomId: groupChatRoomId,
            messages: chatMessages,
          };

          dispatch({
            type: SET_GROUP_CHATS_MESSAGES,
            payload,
          });
        } else {
          // chatroom does not exist
          // so create a new chatroom

          let messageObject = {
            senderId: senderId,
            receiversIds: receiversIds,
            message: message,
            timeStamp,
            chatroomId: groupChatRoomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };
          let chatroom = {
            chatroomId: groupChatRoomId,
          };
          console.log("new chatroom", chatroom);

          newChatsObject[groupChatRoomId] = chatroom;

          let payload = {
            chatroomId: groupChatRoomId,
            messages: [messageObject],
          };

          dispatch({
            type: SET_GROUP_CHATS_MESSAGES,
            payload,
          });
        }

        dispatch({
          type: SET_GROUP_CHATS,
          payload: newChatsObject,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

export const emitJoinRoomEvent = (chatroomId, userId) => async (dispatch) => {
  try {
    console.log("emitJoinRoomEvent => ", { chatroomId, userId });
    socket.emit("joinRoom", {
      room: chatroomId,
      userId,
    });

    socket.on("users", (data) => {
      console.log("emitJoinRoomEvent users => ", data);
    });

    socket.on("message", (data) => {
      console.log("emitJoinRoomEvent message => ", data);
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendGroupChatMessage =
  (
    message,
    senderId,
    receiversIds,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      console.log("sendGroupChatMessage => ", {
        message,
        senderId,
        receiversIds,
        chatroomId,
        messageType,
        attachmentUrl,
      });
      dispatch(setLoadingAction(true));

      let { groupChats, groupChatsMessages } = getState().organization;

      socket.emit("send-message-group", {
        message,
        senderId,
        receiversIds,
        groupChatRoomId: chatroomId,
        timeStamp: Date.now(),

        messageType,
        attachmentUrl,
      });

      let newChatsObject = { ...groupChats };
      let newChatsMessagesObject = { ...groupChatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiversIds,
        chatroomId,
        messageType,
        attachmentUrl,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiversIds: receiversIds,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        // dispatch({
        //   type: SET_ORGANIZATION_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_GROUP_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const listenToUsersAdditionInGroupChat =
  (chatroomId) => async (dispatch) => {
    try {
      console.log("listenToUsersAdditionInGroupChat => ", chatroomId);
      socket.on("users", (data) => {
        console.log("listenToUsersAdditionInGroupChat => ", data);
      });
    } catch (error) {
      console.log(error);
    }
  };
