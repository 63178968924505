import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";

import { useSelector } from "react-redux";

import { Flex } from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import useResponsive from "../../hooks/useResponsive";
import CustomModal from "../../components/CustomModal";
import Text from "../../components/Text";
import { isInt } from "../../utils";

const WaitingHourModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "10px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const WaitingHourModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const WaitingHourModal = ({ open, setOpen, onClick }) => {
  const { currentUser } = useSelector((state) => state.ui);

  const [waitingHours, setWaitingHours] = useState("");
  const isMobile = useResponsive("down", "600");

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <WaitingHourModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Set Waiting Hours
          </Text>
        </HeaderContainer>

        <WaitingHourModalContentContainer>
          <Flex flexDirection="column" width="100%">
            <CustomInput
              value={waitingHours}
              setValue={setWaitingHours}
              placeholder="Enter Waiting Hour(s)"
              type="number"
              sx={{
                marginBottom: 1,
              }}
            />
            {waitingHours && !isInt(waitingHours) && (
              <Text fontSize={12} color="#FF0000" ml={1}>
                Hour(s) can only be integer value (e.g. 1, 2, 3)
              </Text>
            )}
          </Flex>
        </WaitingHourModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton
            // onClick={() => onClick(email)}
            onClick={() => {
              console.log("waitingHours", Math.trunc(waitingHours));
              onClick(Math.trunc(waitingHours));
            }}
            height={50}
            width="50%"
            disabled={!waitingHours || !isInt(waitingHours)}
          >
            <Text center fontSize={18} fontWeight={600}>
              Mark as Delivered
            </Text>
          </CustomButton>
        </Flex>
      </WaitingHourModalStyle>
    </CustomModal>
  );
};
export default WaitingHourModal;
