// Chakra imports
import { Box, Flex, Grid, Textarea, useColorModeValue } from "@chakra-ui/react";

// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/avatar5.png";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Banner,
  Projects,
  Notifications,
  Project,
} from "../../sharedComponents/profile";
import { cleanOrganizationDataForProfile } from "../../sharedComponents/profile/util";
import Text from "../../../components/Text";
import Card from "../../../components/card/Card";
import OrganizationUsers from "./OrganizationUsers";
import RateList from "../../sharedComponents/profile/RateList";
import { useAPIActions } from "../../../hooks/useActions";
import HourlyRateSection from "../../sharedComponents/profile/HourlyRateSection";
import ProfileEditModal from "./ProfileEditModal";

export default function Profile() {
  const { organization } = useSelector((state) => state.organization);
  const { shipments } = useSelector((state) => state.shipment);

  const [showProfileEditModal, setShowProfileEditModal] = useState(false);

  const { updateOrganization } = useAPIActions();

  const { currentUser } = useSelector((state) => state.ui);

  console.log("Profile organization", { organization, currentUser });
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={organization?.logo}
          name={organization?.companyName}
          job="Organization"
          shipments={shipments?.length}
          invoices="0"
          followers="15"
          following="274"
        />
      </Grid>
      {organization && (
        <Grid
          // mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "2fr 1fr",
            // "2xl": "2fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            // lg: "repeat(2, 1fr)",
            // "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <Projects
            data={cleanOrganizationDataForProfile(organization)}
            onProfileEdit={() => {
              setShowProfileEditModal(true);
            }}
            isAdmin={currentUser?.role === "admin"}
          />
          {/* <Notifications used={25.6} total={50} /> */}
        </Grid>
      )}

      {organization && (
        <Grid
          // mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "2fr 1fr",
            // "2xl": "2fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            // lg: "repeat(2, 1fr)",
            // "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <RateList
            value={organization?.rateList}
            onSave={(value) => {
              updateOrganization(organization?._id, {
                rateList: value,
              });
            }}
          />
        </Grid>
      )}
      {organization && (
        <Grid
          // mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "2fr 1fr",
            // "2xl": "2fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            // lg: "repeat(2, 1fr)",
            // "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <HourlyRateSection
            value={organization?.hourlyRate}
            onSave={(value) => {
              updateOrganization(organization?._id, {
                hourlyRate: value,
              });
            }}
          />
        </Grid>
      )}

      {organization && showProfileEditModal && (
        <ProfileEditModal
          open={showProfileEditModal}
          setOpen={setShowProfileEditModal}
          organizationData={cleanOrganizationDataForProfile(organization)}
          organizationId={organization?._id}
        />
      )}
    </Box>
  );
}
