import React, { useState } from "react";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DefaultAuth from "../../../../layouts/auth/Default";
import Text from "../../../../components/Text";
import illustration from "../../../../assets/img/logo.png";
import { Colors } from "../../../../components/config";
import { LoadingButton } from "@mui/lab";
import { getPasswordRequestEmail } from "../../../../redux/actionCreators/organizationActionCreators";
import { appRoutes } from "../../../../assets/constants";
import { setSnackbarAction } from "../../../../redux/actionCreators/uiActionCreators";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getPasswordRequestEmailForDriver } from "../../../../redux/actionCreators/driverActionCreators";

const DriverForgotPassword = () => {
  const textColor = useColorModeValue("navy.700", "white");

  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    const { email } = methods.getValues();

    try {
      const response = await getPasswordRequestEmailForDriver(email);
      if (!response.error) {
        setEmailSent(true);

        dispatch(
          setSnackbarAction({
            message: "Reset password link sent to your email",
            severity: "success",
          })
        );

        setTimeout(() => {
          history.push(appRoutes.driverSignIn);
        }, 3000);
      } else {
        setEmailSent(false);
        dispatch(
          setSnackbarAction({
            message: response?.message || "Something went wrong",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error);
      setEmailSent(false);
      dispatch(
        setSnackbarAction({
          message: error?.message || "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  if (emailSent) {
    return <></>;
  }

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="70vh"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Forgot Password
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={16}>
            Enter your email to get reset password link
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box mb={2}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={10}>
                <RHFTextField name="email" label="Email" />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="theme"
                  loading={isSubmitting}
                >
                  Request Reset Link
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Box>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default DriverForgotPassword;
