export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "Shipment Number",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Created On",
    accessor: "date",
  },
  {
    Header: "Created By",
    accessor: "user",
  },
  {
    Header: "Details",
    accessor: "details",
  },
];

export const columnsDataInvoice = [
  {
    Header: "Invoice Number",
    accessor: "count",
  },
  {
    Header: "Payable By",
    accessor: "type",
  },
  // {
  //   Header: "STATUS",
  //   accessor: "status",
  // },
  {
    Header: "Created on",
    accessor: "date",
  },
  {
    Header: "Due By",
    accessor: "due",
  },

  {
    Header: "Amount",
    accessor: "amount",
  },

  {
    Header: "Details",
    accessor: "view",
  },
];
