import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultSupport from "../../layouts/privacy/Default";
import Text from "../../components/Text";
import illustration from "../../assets/img/logo.png";
import { Colors } from "../../components/config";

const SupportPage = () => {
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <DefaultSupport>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="center"
        justifyContent="start"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Support
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={18} fontWeight={"bold"}>
            Contact us to seek support with TisNix
          </Text>
          <Text color={"#000"} fontSize={16}>
            Email address: <b>tisnix@aiow.io</b>
            <br></br><br></br><br></br><br></br><br></br>
          </Text>
        </Box>

      </Flex>
    </DefaultSupport>
  );
};

export default SupportPage;
