import React, { useEffect } from "react";
import { Box, styled } from "@mui/material";
import Text from "../../components/Text";
import CustomModal from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { Avatar, Flex } from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import { useHistory } from "react-router-dom";
import { useAPIActions } from "../../hooks/useActions";
import useResponsive from "../../hooks/useResponsive";

const OrganizationSearchModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",
  minWidth: !isMobile && 500,

  width: isMobile && "95vw",

  //commenting it for now
  // [theme.breakpoints.down("500")]: {
  //   width: "90vw",
  // },
}));

const OrganizationSearchModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,
  paddingTop: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const OrganizationSearchModal = ({
  shipmentId,
  shipment,
  open,
  setOpen,
  search,
}) => {
  const { organizations, organizationConnections } = useSelector(
    (state) => state.organization
  );
  const { organization, chats } = useSelector((state) => state.organization);

  const { customer } = useSelector((state) => state.customer);

  const { currentUser, searchResults } = useSelector((state) => state.ui);

  const isMobile = useResponsive("down", "600");

  const {
    createChatroomOrganizationToOrganization,
    setShipmentIdToBeAssigned,
    createChatroomForCustomer,
  } = useAPIActions();

  const history = useHistory();

  console.log("OrganizationModal =>", {
    organizations,
    currentUser,
    searchResults,
    search,
    organizationConnections,
    chats,
    shipment,
  });

  const onChatClick = (organizationId) => {
    setOpen(false);
    if (
      Object.values(chats).find((item) => item.organization === organizationId)
    ) {
      const chatItem = Object.values(chats).find(
        (item) => item.organization === organizationId
      );

      console.log("onAssignClick chatItem", chatItem);
      history.push({
        pathname: "/customer/chat",
        state: { chatId: chatItem._id },
      });
    } else {
      createChatroomForCustomer(customer._id, organizationId, (chatId) => {
        console.log("createChatroomForCustomer chatId", chatId);
        setTimeout(() => {
          history.push({
            pathname: "/customer/chat",
            state: { chatId },
          });
        }, 1000);
      });
    }
  };

  const onAssignClick = (organizationId) => {
    setOpen(false);

    setShipmentIdToBeAssigned(shipmentId);
    console.log("chatId", { organizationId, chats, organization });
    if (
      Object.values(chats).find(
        (item) =>
          item.organization2 === organizationId ||
          item.organization === organizationId
      )
    ) {
      const chatItem = Object.values(chats).find(
        (item) =>
          item.organization2 === organizationId ||
          item.organization === organizationId
      );

      console.log("onAssignClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id, shipmentId },
      });
    } else {
      createChatroomOrganizationToOrganization(
        organization._id,
        organizationId,
        (chatId) => {
          setTimeout(() => {
            history.push({
              pathname: "/organization/chat",
              state: { chatId, shipmentId },
            });
          }, 1000);
        }
      );
    }
  };

  const RenderSingleOrganization = ({ organization }) => {
    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Flex alignItems="center">
          <Avatar src={organization.logo} border="1px solid #000" mr={3} />
          <Flex flexDirection="column" justifyContent="center">
            <Text>{organization.companyName}</Text>
            {/* <Text>{organization._id}</Text> */}
          </Flex>
        </Flex>

        {search ? (
          <CustomButton
            onClick={() => {
              onChatClick(organization._id);
            }}
          >
            <Text>Chat</Text>
          </CustomButton>
        ) : (
          <CustomButton onClick={() => onAssignClick(organization._id)}>
            <Text>Send Shipment</Text>
          </CustomButton>
        )}
      </Flex>
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <OrganizationSearchModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          {search ? `Search Results for "${search}"` : "Connections"}
        </Text>

        <OrganizationSearchModalContentContainer>
          {!search &&
          organizationConnections.filter(
            (item) => item._id !== shipment?.customer?._id
          ).length > 0 ? (
            organizationConnections
              .filter((item) => item._id !== shipment?.customer?._id)
              .map((organization, index) => (
                <RenderSingleOrganization
                  key={index}
                  organization={organization}
                />
              ))
          ) : searchResults.filter(
              (item) => item._id !== shipment?.customer?._id
            ).length > 0 ? (
            searchResults
              .filter((item) => item._id !== shipment?.customer?._id)
              .map((organization, index) => (
                <RenderSingleOrganization
                  key={index}
                  organization={organization}
                />
              ))
          ) : (
            <Text center>No Organization Found</Text>
          )}
        </OrganizationSearchModalContentContainer>
      </OrganizationSearchModalStyle>
    </CustomModal>
  );
};
export default OrganizationSearchModal;
