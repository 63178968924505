import { Avatar, Box, Flex, Icon, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Colors } from "../../components/config";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import { useAPIActions } from "../../hooks/useActions";
import useResponsive from "../../hooks/useResponsive";
import { SHIPMENT } from "../../redux/constants";
import OrganizationProfileModal from "../organization/profile/OrganizationProfileModal";
import { getIcon, getColor } from "../sharedComponents/utils";
import DriverShipment from "./DriverShipment";
import OrganizationShipment from "./OrganizationShipment";
import PodsDisplayModal from "./PodsDisplayModal";
import TransportShipment from "./TransportShipment";

const SingleShipment = () => {
  const params = useParams();

  const { getShipmentById, clearShipment } = useAPIActions();
  const location = useLocation();

  const { shipment } = useSelector((state) => state.shipment);

  const { currentUser } = useSelector((state) => state.ui);

  const { organization } = useSelector((state) => state.organization);

  const { driver } = useSelector((state) => state.driver);

  const isMobile = useResponsive("down", "600");

  const [showPodsDisplayModal, setShowPodsDisplayModal] = useState(false);
  const [priceInput, setPriceInput] = useState("");
  const [waitingHours, setWaitingHours] = useState("");
  const [showOrganizationProfileModal, setShowOrganizationProfileModal] =
    useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  console.log("SingleShipment => ", { shipment, params, location });

  useEffect(() => {
    if (params?.id) {
      getShipmentById(params.id);
    }

    return () => {
      // cleanup
      clearShipment();
    };
  }, [params?.id]);

  const RenderShipmentText = ({
    title,
    value,
    valUppercase,
    icon,
    color,
    valueFs,
    titleFs,
    avatar,
    onClick,
  }) => {
    return (
      <Flex direction="column" mb={4}>
        <Text
          fontSize={titleFs || 18}
          letterSpacing={1}
          fontWeight={500}
          uppercase
          // color="#A0AEC0"
          color={Colors.themeColor}
        >
          {title}
        </Text>
        <Flex
          align="center"
          mt={avatar && 2}
          onClick={onClick}
          cursor={onClick && "pointer"}
        >
          {icon && <Icon as={icon} color={color} mr={2} w="24px" h="24px" />}
          {avatar && <Avatar src={avatar} size="sm" mr={1} />}
          <Text
            fontSize={valueFs || 18}
            fontWeight={500}
            uppercase={valUppercase}
          >
            {value}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {shipment && currentUser && (
        <Box
          mt={{ base: "130px", md: "80px", xl: "80px" }}
          boxShadow="xl"
          borderRadius={"20px"}
          px={4}
          py={4}
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Flex direction="column">
            <Text fontSize={22} fontWeight={600} mb={5}>
              Shipment # {shipment.shipmentNumber}
            </Text>

            <Flex
              flexDirection={isMobile ? "column" : "row"}
              justifyContent={isMobile ? "flex-start" : "space-between"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              <RenderShipmentText
                title="Status"
                value={
                  shipment.status === SHIPMENT.POD_ATTACHED
                    ? "POD-Attached"
                    : shipment.status === SHIPMENT.DRIVER_ASSIGNED
                    ? "Driver-Assigned"
                    : shipment.status
                }
                icon={getIcon(shipment.status)}
                color={getColor(shipment.status)}
                valueFs={24}
                titleFs={20}
                valUppercase
              />

              {shipment.podUrls.length > 0 && (
                <CustomButton
                  height={40}
                  width={isMobile ? 200 : 150}
                  onClick={() => setShowPodsDisplayModal(true)}
                >
                  See PODs
                </CustomButton>
              )}
            </Flex>

            <Flex
              borderBottom={`1px solid ${Colors.lightgrey3}`}
              borderTop={`1px solid ${Colors.lightgrey3}`}
              mb={4}
              mt={2}
              py={4}
              direction={isMobile ? "column" : "row"}
            >
              <Flex direction="column" flex={1}>
                <RenderShipmentText
                  title="Shipment Number"
                  value={shipment.shipmentNumber}
                />

                {currentUser.type === "organization" &&
                  shipment.customerCost &&
                  shipment.organization2?._id !== currentUser.id &&
                  shipment.customer._id !== currentUser.id && (
                    <>
                      <RenderShipmentText
                        title="Organization Earnings"
                        value={`${shipment.customerCost} €`}
                      />
                    </>
                  )}

                {currentUser.type === "organization" &&
                  shipment.customerCost &&
                  shipment.customer._id === currentUser.id && (
                    <>
                      <RenderShipmentText
                        title="Cost"
                        value={`${shipment.customerCost} €`}
                      />
                    </>
                  )}

                {currentUser.type === "organization" &&
                shipment.organizationCost &&
                shipment.organization2 &&
                shipment.customer?._id !== currentUser.id ? (
                  <>
                    <RenderShipmentText
                      title={
                        shipment.organization2?._id === currentUser.id
                          ? "Earnings"
                          : "Organization2 Cost"
                      }
                      value={`${shipment.organizationCost} €`}
                    />
                  </>
                ) : (
                  currentUser.type !== "customer" &&
                  currentUser.type !== "driver" &&
                  shipment.organizationCost &&
                  shipment.customer?._id !== currentUser.id && (
                    <>
                      <RenderShipmentText
                        title="Driver Cost"
                        value={`${shipment.organizationCost} €`}
                      />
                    </>
                  )
                )}

                {currentUser.type === "organization" &&
                  shipment.organization2?._id === currentUser.id &&
                  shipment.transportCost && (
                    <>
                      <RenderShipmentText
                        title="Driver Cost"
                        value={`${shipment.transportCost} €`}
                      />
                    </>
                  )}

                {currentUser.type === "customer" && shipment.customerCost && (
                  <>
                    <RenderShipmentText
                      title="Cost"
                      value={`${shipment.customerCost} €`}
                    />
                  </>
                )}

                {currentUser.type === "transport" &&
                  shipment.organizationCost && (
                    <>
                      <RenderShipmentText
                        title="Transport Earnings"
                        value={`${shipment.organizationCost} €`}
                      />
                    </>
                  )}

                {currentUser.type === "transport" && shipment.transportCost && (
                  <>
                    <RenderShipmentText
                      title="Driver Cost"
                      value={`${shipment.transportCost} €`}
                    />
                  </>
                )}

                {currentUser.type === "driver" && shipment.transportCost ? (
                  <>
                    <RenderShipmentText
                      title="Earnings"
                      value={`${shipment.transportCost} €`}
                    />
                  </>
                ) : (
                  currentUser.type === "driver" &&
                  shipment.organizationCost && (
                    <>
                      <RenderShipmentText
                        title="Earnings"
                        value={`${shipment.organizationCost} €`}
                      />
                    </>
                  )
                )}

                <RenderShipmentText title="Size" value={shipment.sizes} />
                <RenderShipmentText
                  title="Weight"
                  value={`${shipment.weight} kg`}
                />
                <RenderShipmentText
                  title="Goods"
                  value={`${shipment.goods.join(",")}`}
                  valUppercase
                />

                <RenderShipmentText
                  title="Extra Note"
                  value={shipment.extraNote}
                  valUppercase
                />
              </Flex>
              <Flex direction="column" flex={1}>
                <RenderShipmentText
                  title="Picked From"
                  value={shipment.pickupAddress}
                  valUppercase
                />
                <RenderShipmentText
                  title="Delivery Address"
                  value={shipment.deliveryAddress}
                  valUppercase
                />

                <RenderShipmentText
                  title="Loading Reference"
                  value={shipment.loadRef}
                  valUppercase
                />

                {currentUser.type === "driver" && shipment.waitingHours && (
                  <RenderShipmentText
                    title="Waiting Time(hours)"
                    value={`${shipment.waitingHours} hr(s)`}
                  />
                )}

                {currentUser.type === "organization" &&
                  shipment.waitingHours && (
                    <RenderShipmentText
                      title="Waiting Time(hours)"
                      value={`${shipment.waitingHours} hr(s)`}
                    />
                  )}

                {currentUser.type === "organization" &&
                  organization.hourlyRate && (
                    <RenderShipmentText
                      title="Hourly Rate"
                      value={`${organization.hourlyRate} €/hr`}
                    />
                  )}

                {shipment.organization &&
                  currentUser.type !== "organization" &&
                  currentUser.type !== "driver" && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.organization.logo}
                        title="Organization"
                        value={shipment.organization.companyName}
                        valUppercase
                        onClick={() => {
                          setSelectedOrganization(shipment.organization);
                          setShowOrganizationProfileModal(true);
                        }}
                      />
                    </>
                  )}

                {shipment.customer &&
                  currentUser.type === "organization" &&
                  shipment.organization2?._id !== currentUser.id &&
                  shipment.customer?._id !== currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.customer.logo}
                        title="Organization"
                        value={shipment.customer.companyName}
                        valUppercase
                      />
                    </>
                  )}

                {shipment.customer &&
                  currentUser.type === "organization" &&
                  shipment.organization2?._id !== currentUser.id &&
                  shipment.customer?._id === currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.organization.logo}
                        title="Organization"
                        value={shipment.organization.companyName}
                        valUppercase
                      />
                    </>
                  )}

                {shipment.organization &&
                  currentUser.type === "organization" &&
                  shipment.organization2?._id === currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.organization.logo}
                        title="Organization"
                        value={shipment.organization.companyName}
                        valUppercase
                      />
                    </>
                  )}

                {shipment.organization &&
                  currentUser.type === "organization" &&
                  shipment.customer === currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.organization.logo}
                        title="Organization 2"
                        value={shipment.organization.companyName}
                        valUppercase
                      />
                    </>
                  )}
                {shipment.organization2 &&
                  currentUser.type === "organization" &&
                  currentUser.id !== shipment.organization2?._id &&
                  currentUser.id !== shipment.customer?._id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.organization2.logo}
                        title="Organization 2"
                        value={shipment.organization2.companyName}
                        valUppercase
                        onClick={() => {
                          setSelectedOrganization(shipment.organization2);
                          setShowOrganizationProfileModal(true);
                        }}
                      />
                    </>
                  )}

                {shipment.transport &&
                  currentUser.type !== "transport" &&
                  currentUser.type !== "customer" && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.transport.logo}
                        title="Transport"
                        value={shipment.transport.companyName}
                        valUppercase
                      />
                    </>
                  )}

                {shipment.driver &&
                  currentUser.type !== "driver" &&
                  currentUser.type !== "customer" &&
                  !shipment.organization2 &&
                  shipment.organization2?._id !== currentUser.id &&
                  shipment.customer?._id !== currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.driver.profilePicture}
                        title="Driver"
                        value={shipment.driver.name}
                        valUppercase
                      />
                    </>
                  )}

                {shipment.driver &&
                  currentUser.type !== "driver" &&
                  shipment.customer?._id !== currentUser.id &&
                  shipment.organization2 &&
                  shipment.organization2?._id === currentUser.id && (
                    <>
                      <RenderShipmentText
                        avatar={shipment.driver.profilePicture}
                        title="Driver"
                        value={shipment.driver.name}
                        valUppercase
                      />
                    </>
                  )}
              </Flex>
            </Flex>

            {currentUser.type === "organization" &&
              currentUser.id !== shipment.customer?._id &&
              shipment.status === SHIPMENT.PENDING && (
                // ||
                // (currentUser.type === "organization" &&
                //   shipment.status === SHIPMENT.ACCEPTED) ||
                // (currentUser.type === "transport" &&
                //   shipment.status === SHIPMENT.ASSIGNED)

                <Flex
                  borderBottom={`1px solid ${Colors.lightgrey3}`}
                  mb={4}
                  mt={2}
                  py={4}
                  direction={"column"}
                >
                  <Text
                    fontSize={18}
                    letterSpacing={1}
                    fontWeight={500}
                    uppercase
                    mb={1}
                    // color="#A0AEC0"
                    color={Colors.themeColor}
                  >
                    {currentUser.type === "organization" &&
                    shipment.status === SHIPMENT.PENDING
                      ? `${shipment.customer.companyName} Cost`
                      : "Cost"}
                  </Text>
                  <Flex alignItems="center">
                    <Input
                      // variant="unstyled"
                      placeholder="Price"
                      value={priceInput}
                      onChange={(e) => setPriceInput(e.target.value)}
                      type="number"
                      sx={{
                        fontSize: 16,

                        height: "50px",
                        width: "300px",
                        padding: "0px 10px",
                        borderRadius: "10px",

                        "&:focus": {
                          borderColor: Colors.themeColor,
                          boxShadow: `0 0 0 1px ${Colors.themeColor}`,
                        },
                      }}
                    />
                    <Text ml={1} fontSize={24}>
                      €
                    </Text>
                  </Flex>
                </Flex>
              )}
            {/* {currentUser.type === "driver" &&
              shipment.status === SHIPMENT.TRANSIT && (
                <Flex
                  borderBottom={`1px solid ${Colors.lightgrey3}`}
                  mb={4}
                  mt={2}
                  py={4}
                  direction={"column"}
                >
                  <Text
                    fontSize={18}
                    letterSpacing={1}
                    fontWeight={500}
                    uppercase
                    mb={1}
                    // color="#A0AEC0"
                    color={Colors.themeColor}
                  >
                    Waiting Hours
                  </Text>
                  <Flex alignItems="center">
                    <Input
                      // variant="unstyled"
                      placeholder="Input Waiting Hour(s)"
                      value={waitingHours}
                      onChange={(e) => setWaitingHours(e.target.value)}
                      type="number"
                      sx={{
                        fontSize: 16,

                        height: "50px",
                        width: "300px",
                        padding: "0px 10px",
                        borderRadius: "10px",

                        "&:focus": {
                          borderColor: Colors.themeColor,
                          boxShadow: `0 0 0 1px ${Colors.themeColor}`,
                        },
                      }}
                    />
                    <Text ml={1} fontSize={20}>
                      hr
                    </Text>
                  </Flex>
                </Flex>
              )} */}
            <Flex width="100%">
              {currentUser.type === "organization" &&
              shipment.organization2?._id === currentUser.id ? (
                <OrganizationShipment
                  shipment={shipment}
                  priceInput={priceInput}
                  setPriceInput={setPriceInput}
                  self={organization}
                  other={shipment.customer}
                  organization2={true}
                />
              ) : currentUser.type === "organization" &&
                shipment?.customer?._id !== currentUser.id ? (
                <OrganizationShipment
                  shipment={shipment}
                  priceInput={priceInput}
                  setPriceInput={setPriceInput}
                  self={organization}
                  other={shipment.customer}
                />
              ) : (
                <OrganizationShipment
                  owner={true}
                  shipment={shipment}
                  priceInput={priceInput}
                  setPriceInput={setPriceInput}
                  self={organization}
                  other={shipment.customer}
                />
              )}

              {currentUser.type === "transport" && (
                <TransportShipment shipment={shipment} />
              )}

              {currentUser.type === "driver" && (
                <DriverShipment
                  shipment={shipment}
                  self={driver}
                  other={shipment.transport}
                  isPod={location.state?.isPod && shipment.podUrls.length === 0}
                />
              )}
            </Flex>
          </Flex>

          <PodsDisplayModal
            open={showPodsDisplayModal}
            setOpen={setShowPodsDisplayModal}
            podUrls={shipment.podUrls}
          />
        </Box>
      )}

      <OrganizationProfileModal
        open={showOrganizationProfileModal}
        setOpen={setShowOrganizationProfileModal}
        organization={selectedOrganization}
        onClose={() => setShowOrganizationProfileModal(false)}
      />
    </>
  );
};

export default SingleShipment;
