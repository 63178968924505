import React from "react";

// Chakra imports
import { Flex, Spacer, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "../../../components/icons/Icons";
import { HSeparator } from "../../../components/separator/Separator";
import Logo from "../../../assets/img/svg/logo";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <Logo height="50px" width="50px" />
      <Spacer mb="20px" />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
