// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/avatar5.png";
import React from "react";
import {
  Banner,
  Projects,
  Notifications,
} from "../../sharedComponents/profile/";
import { useSelector } from "react-redux";
import { cleanCustomerDataForProfile } from "../../sharedComponents/profile/util";

const Profile = () => {
  const { customer } = useSelector((state) => state.customer);
  const { shipments } = useSelector((state) => state.shipment);

  console.log("Profile customer", customer);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={customer?.profilePicture}
          name={customer?.name}
          job="Customer"
          shipments={shipments.length}
          invoices="0"
        />
      </Grid>
      {customer && (
        <Grid
          mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "2fr 1fr",
            "2xl": "2fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <Projects data={cleanCustomerDataForProfile(customer)} />
          {/* <Notifications used={25.6} total={50} /> */}
        </Grid>
      )}
    </Box>
  );
};

export default Profile;
