// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components

import React from "react";
// Assets
import { MdEdit } from "react-icons/md";
import Card from "../../../components/card/Card";

export default function Project(props) {
  const { title, ranking, link, image, onEdit, rankingFs, avatar, ...rest } =
    props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex direction={{ base: "column", md: "row" }}>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="sm"
            mb="10px"
          >
            {title}
          </Text>
          <Flex align="center">
            {avatar && <Avatar size="sm" mr={2} src={avatar} />}

            <Text
              fontWeight="500"
              color={textColorSecondary}
              fontSize={rankingFs ? rankingFs : "md"}
              me="4px"
              whiteSpace="break-spaces"
            >
              {ranking}
            </Text>
          </Flex>
        </Box>
        {onEdit && (
          <Link
            href={link}
            variant="no-hover"
            me="16px"
            ms="auto"
            p="0px !important"
            onClick={onEdit}
          >
            <Icon as={MdEdit} color="secondaryGray.500" h="18px" w="18px" />
          </Link>
        )}
      </Flex>
    </Card>
  );
}
