import { Box, Flex, VStack } from "@chakra-ui/react";
import { styled } from "@mui/material";

import moment from "moment";
import React from "react";
import { Colors } from "../../../components/config";
import Text from "../../../components/Text";

const PictureMessageStyle = styled(Box)(({ theme }) => ({
  marginBottom: 0,
}));

const PictureMessage = ({ url, from, dateSent, name }) => {
  const isMe = from === "me";
  const alignment = isMe ? "flex-end" : "flex-start";
  const bottomRightRadius = isMe ? 0 : 32;
  const bottomLeftRadius = isMe ? 32 : 0;

  return (
    <PictureMessageStyle>
      <VStack mt={6} alignItems={alignment} alignSelf={alignment}>
        <Box
          px={4}
          py={4}
          maxW={80}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <img
            src={url}
            style={{
              height: "100%",
            }}
          />
        </Box>
        {name && (
          <Text
            fontSize={12}
            fontWeight={500}
            color={isMe ? "#ff33a0" : Colors.darkgreyText}
          >
            {name}
          </Text>
        )}
        <Text
          fontSize={10}
          color={Colors.darkgreyText}
          sx={{
            marginTop: "5px !important",
          }}
        >
          {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
        </Text>
      </VStack>
    </PictureMessageStyle>
  );
};

export default PictureMessage;
