import {
  Flex,
  HStack,
  Input,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  calc,
  Avatar,
  AvatarBadge,
  VStack,
  Button,
} from "@chakra-ui/react";

import { IoSend, IoDocuments } from "react-icons/io5";
import { HiChat } from "react-icons/hi";

import Iconify from "../../../components/Iconify";

import ChatBubble from "./chatBubble";
import { useRef, useState } from "react";
import ShipmentModal from "./ShipmentModal";
import { useSelector } from "react-redux";
import { Box as MuiBox, IconButton } from "@mui/material";

import ShipmentMessage from "./ShipmentMessage";
import { getMimeType } from "../../../utils";
import PictureMessage from "./PictureMessage";
import PictureDisplay from "./PictureDisplay";
import { uploadChatImage } from "../../../redux/actionCreators/customerActionCreators";
import { useAPIActions } from "../../../hooks/useActions";
import AssignableShipmentsModal from "./AssigneableShipmentsModal";
import TransportAssignableShipmentsModal from "./TransportAssigneableShipmentsModal";
import OrganizationProfileModal from "../../organization/profile/OrganizationProfileModal";

const ChatView = ({
  onChatHistoryOpen,
  onChatFilesOpen,
  currentChat,
  setCurrentChat,
  messages,
  mobile,
  self,
  onSendChatMessage,
  other,
  isAdmin,
  onAddUserToChatClick,
}) => {
  console.log("ChatView messages", { messages, self });
  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [showAssignableShipmentsModal, setShowAssignableShipmentsModal] =
    useState(false);

  const [
    showTransportAssignableShipmentsModal,
    setShowTransportAssignableShipmentsModal,
  ] = useState(false);

  const [showOrganizationProfileModal, setShowOrganizationProfileModal] =
    useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  console.log("ChatView currentChat", { currentChat });

  const [chatInput, setChatInput] = useState("");
  const [picture, setPicture] = useState("");

  let chatBottomRef = useRef(null);

  const {
    sendCustomerChatMessage,
    setSnackbarAction,
    sendShipmentStatusEmail,
  } = useAPIActions();

  const { currentUser } = useSelector((state) => state.ui);

  const chatBorderColor = useColorModeValue("whiteAlpha.900", "whiteAlpha.200");
  const chatTextColor = useColorModeValue("blackAlpha.900", "whiteAlpha.900");
  console.log("messages", messages);
  const onSendClick = async (Id) => {
    if (picture.localUri) {
      // upload image
      let url = await uploadChatImage(picture.file);

      if (url) {
        onSendChatMessage({
          type: "image",
          url,
          chatInput,
        });
        // sendCustomerChatMessage(
        //   chatInput,
        //   self._id,
        //   currentChat.receiverId,
        //   currentChat.chatroomId,
        //   "image",
        //   url
        // );
        chatBottomRef.current?.scrollIntoView({ behavior: "smooth" });
        setChatInput("");
        setPicture("");
      }
    } else if (showShipmentModal) {
      onSendChatMessage({
        type: "shipment",
        shipmentId: Id,
        chatInput,
      });

      // sendCustomerChatMessage(
      //   chatInput,
      //   self._id,
      //   currentChat.receiverId,
      //   currentChat.chatroomId,
      //   "shipment",
      //   null,
      //   Id
      // );
      chatBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setChatInput("");
      setShowShipmentModal(false);
    } else {
      onSendChatMessage({
        type: "text",
        chatInput,
      });
      // sendCustomerChatMessage(
      //   chatInput,
      //   customer._id,
      //   currentChat.receiverId,
      //   currentChat.chatroomId
      // );
      chatBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setChatInput("");
    }
  };

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPicture(result);
  };

  if (currentChat?.type === "group") {
    return (
      <>
        <Flex
          w="full"
          justifyContent={"space-between"}
          flexDirection="column"
          display={"flex"}
          zIndex={1}
          height={mobile && "0%"}
          backgroundColor="#fff"
          sx={
            mobile && {
              top: 0,
              position: "fixed",
              width: "100%",
            }
          }
        >
          <Box
            flexDirection="row"
            background={chatBorderColor}
            boxShadow="xl"
            margin={"5px"}
            borderRadius={"20px"}
            width="100%"
          >
            <HStack px={4} py={4} borderRadius={"15px"} boxShadow="xl">
              <Flex flex={1} alignItems="center">
                {mobile && (
                  <IconButton onClick={() => setCurrentChat(null)}>
                    <Iconify
                      icon="material-symbols:arrow-back"
                      sx={{
                        fontSize: 30,
                        color: "#000",
                      }}
                    />
                  </IconButton>
                )}

                {currentChat.type === "group" ? (
                  <Avatar
                    mr={2}
                    boxSize="2em"
                    icon={
                      <Iconify
                        icon="mdi:users-group"
                        sx={{
                          height: 30,
                          width: 30,
                        }}
                      />
                    }
                    cursor="pointer"
                    // onClick={() => {
                    //   if (
                    //     currentChat.type === "organization" ||
                    //     currentChat.type === "organization2"
                    //   ) {
                    //     setSelectedOrganization(currentChat);
                    //     setShowOrganizationProfileModal(true);
                    //   }
                    // }}
                  >
                    {currentChat.isActive ? (
                      <AvatarBadge boxSize="1em" bg="green.500" />
                    ) : (
                      <AvatarBadge />
                    )}
                  </Avatar>
                ) : (
                  <Avatar
                    mr={2}
                    boxSize="2em"
                    src={currentChat.profilePicture}
                    cursor="pointer"
                    // onClick={() => {
                    //   if (
                    //     currentChat.type === "organization" ||
                    //     currentChat.type === "organization2"
                    //   ) {
                    //     setSelectedOrganization(currentChat);
                    //     setShowOrganizationProfileModal(true);
                    //   }
                    // }}
                  >
                    {currentChat.isActive ? (
                      <AvatarBadge boxSize="1em" bg="green.500" />
                    ) : (
                      <AvatarBadge />
                    )}
                  </Avatar>
                )}

                <Stat
                  cursor="pointer"
                  // onClick={() => {
                  //   if (
                  //     currentChat.type === "organization" ||
                  //     currentChat.type === "organization2"
                  //   ) {
                  //     setSelectedOrganization(currentChat);
                  //     setShowOrganizationProfileModal(true);
                  //   }
                  // }}
                >
                  <StatNumber color={chatTextColor}>
                    {currentChat.name}
                  </StatNumber>
                  {/* <StatLabel color={chatTextColor}>
                    Chatting with {other.type}
                  </StatLabel>
                
                  <StatNumber color={chatTextColor}>
                    {currentChat.name}
                  </StatNumber> */}
                </Stat>
              </Flex>
            </HStack>
          </Box>
          <Flex direction="column" position={"relative"}>
            <Box
              background={chatBorderColor}
              boxShadow={!mobile && "lg"}
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
                overflow: "scroll",
                height: !mobile ? "calc(100vh - 300px)" : "100vh",
                paddingBottom: mobile && 200,
              }}
              my={"5px"}
              borderRadius={"20px"}
              py={"10px"}
              px={"20px"}
              overflowY={"scroll"}

              // height={window.innerHeight - 380}
            >
              {messages.map(
                (
                  {
                    message,
                    from,
                    dateSent,
                    senderId,
                    timeStamp,
                    messageType,
                    attachmentUrl,
                    shipmentId,
                  },
                  index
                ) =>
                  messageType === "shipment" ? (
                    <ShipmentMessage
                      shipmentId={shipmentId}
                      dateSent={timeStamp}
                      from={self._id === senderId ? "me" : "others"}
                      other={other}
                      self={self}
                    />
                  ) : messageType === "image" ? (
                    <PictureMessage
                      name={currentChat.names[senderId]}
                      url={attachmentUrl}
                      from={
                        currentChat.sender[0]._id === senderId ? "me" : "others"
                      }
                      dateSent={timeStamp}
                    />
                  ) : (
                    <ChatBubble
                      key={index}
                      isActive={currentChat.isActive}
                      selfAvatar={self.logo || self.profilePicture}
                      otherAvatar={currentChat.receiverAvatars[senderId]}
                      message={message}
                      name={currentChat.names[senderId]}
                      from={
                        currentChat.sender[0]._id === senderId ? "me" : "others"
                      }
                      dateSent={timeStamp}
                      onOtherClick={() => {
                        if (
                          currentChat.type === "organization" ||
                          currentChat.type === "organization2"
                        ) {
                          setSelectedOrganization(currentChat);
                          setShowOrganizationProfileModal(true);
                        }
                      }}
                      onSelfClick={() => {
                        // console.log("self", self);
                        if (self.companyName) {
                          setSelectedOrganization(self);
                          setShowOrganizationProfileModal(true);
                        }
                      }}
                    />
                  )
              )}

              <MuiBox height={100} ref={chatBottomRef}></MuiBox>
              {picture.localUri && (
                <PictureDisplay picture={picture} setPicture={setPicture} />
              )}
            </Box>
          </Flex>

          <Box
            flexDirection="row"
            background={chatBorderColor}
            boxShadow={!mobile && "xl"}
            borderTop={mobile && "2px solid #d9d9d9"}
            margin={"5px"}
            borderRadius={!mobile && "20px"}
            backgroundColor="#fff"
            sx={
              mobile && {
                bottom: -1,
                position: "fixed",
                width: "100%",
              }
            }
          >
            <Flex pl={4} pr={2} py={2} height="100%">
              <Input
                color={chatTextColor}
                variant="unstyled"
                placeholder="Type your message"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                disabled={picture.localUri}
                onKeyDown={(e) => {
                  console.log(e.key);
                  if (e.key === "Enter" && (chatInput || picture.localUri)) {
                    onSendClick();
                  }
                }}
              />
              <IconButton
                color="theme"
                disabled={chatInput.length === 0 && !picture.localUri}
                onClick={onSendClick}
              >
                <Iconify icon="fluent:send-28-filled" />
              </IconButton>
              <IconButton
                sx={{ mr: 1 }}
                variant="contained"
                color="theme"
                component="label"
              >
                <Iconify icon="ant-design:plus-outlined" />
                <input type="file" hidden onChange={onChangeImage} />
              </IconButton>
            </Flex>
          </Box>
        </Flex>

        <OrganizationProfileModal
          open={showOrganizationProfileModal}
          setOpen={setShowOrganizationProfileModal}
          organization={selectedOrganization}
          onClose={() => setShowOrganizationProfileModal(false)}
        />
      </>
    );
  }

  // console.log("currentChat", currentChat);
  return (
    <>
      <Flex
        w="full"
        justifyContent={"space-between"}
        flexDirection="column"
        display={"flex"}
        zIndex={1}
        height={mobile && "0%"}
        backgroundColor="#fff"
        sx={
          mobile && {
            top: 0,
            position: "fixed",
            width: "100%",
          }
        }
      >
        <Box
          flexDirection="row"
          background={chatBorderColor}
          boxShadow="xl"
          margin={"5px"}
          borderRadius={"20px"}
          width="100%"
        >
          <HStack px={4} py={4} borderRadius={"15px"} boxShadow="xl">
            <Flex flex={1} alignItems="center">
              {mobile && (
                <IconButton onClick={() => setCurrentChat(null)}>
                  <Iconify
                    icon="material-symbols:arrow-back"
                    sx={{
                      fontSize: 30,
                      color: "#000",
                    }}
                  />
                </IconButton>
              )}
              <Avatar
                mr={2}
                boxSize="2em"
                src={currentChat.profilePicture}
                cursor="pointer"
                onClick={() => {
                  if (
                    currentChat.type === "organization" ||
                    currentChat.type === "organization2"
                  ) {
                    setSelectedOrganization(currentChat);
                    setShowOrganizationProfileModal(true);
                  }
                }}
              >
                {currentChat.isActive ? (
                  <AvatarBadge boxSize="1em" bg="green.500" />
                ) : (
                  <AvatarBadge />
                )}
              </Avatar>
              <Stat
                cursor="pointer"
                onClick={() => {
                  if (
                    currentChat.type === "organization" ||
                    currentChat.type === "organization2"
                  ) {
                    setSelectedOrganization(currentChat);
                    setShowOrganizationProfileModal(true);
                  }
                }}
              >
                <StatLabel color={chatTextColor}>
                  Chatting with {other.type}
                </StatLabel>
                <StatNumber color={chatTextColor}>
                  {currentChat.name}
                </StatNumber>
              </Stat>
              {isAdmin && (
                <Button
                  variant="darkBrand"
                  color="white"
                  fontSize="sm"
                  fontWeight="500"
                  borderRadius="70px"
                  px="20px"
                  py="5px"
                  onClick={onAddUserToChatClick}
                >
                  Add User
                </Button>
              )}
            </Flex>
          </HStack>
        </Box>
        <Flex direction="column" position={"relative"}>
          <Box
            background={chatBorderColor}
            boxShadow={!mobile && "lg"}
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
              overflow: "scroll",
              height: !mobile ? "calc(100vh - 300px)" : "100vh",
              paddingBottom: mobile && 200,
            }}
            my={"5px"}
            borderRadius={"20px"}
            py={"10px"}
            px={"20px"}
            overflowY={"scroll"}

            // height={window.innerHeight - 380}
          >
            {messages.map(
              (
                {
                  message,
                  from,
                  dateSent,
                  senderId,
                  timeStamp,
                  messageType,
                  attachmentUrl,
                  shipmentId,
                  sender,
                },
                index
              ) =>
                messageType === "shipment" ? (
                  <ShipmentMessage
                    shipmentId={shipmentId}
                    dateSent={timeStamp}
                    from={self._id === senderId ? "me" : "others"}
                    other={other}
                    self={self}
                  />
                ) : messageType === "image" ? (
                  <PictureMessage
                    url={attachmentUrl}
                    from={self._id === senderId ? "me" : "others"}
                    dateSent={timeStamp}
                  />
                ) : (
                  <ChatBubble
                    key={index}
                    isActive={currentChat.isActive}
                    selfAvatar={
                      self.logo ||
                      (self.userId === senderId
                        ? self.profilePicture
                        : currentUser.users.find(
                            (user) => user._id === senderId
                          )?.profilePicture)
                    }
                    otherAvatar={currentChat.profilePicture || currentChat.logo}
                    message={message}
                    senderName={sender?.name}
                    username={
                      self.userId === senderId
                        ? currentUser.name
                        : currentUser.users.find(
                            (user) => user._id === senderId
                          )?.name
                    }
                    from={
                      self.userId === senderId ||
                      currentUser.users.find((user) => user._id === senderId)
                        ? "me"
                        : "others"
                    }
                    dateSent={timeStamp}
                    onOtherClick={() => {
                      if (
                        currentChat.type === "organization" ||
                        currentChat.type === "organization2"
                      ) {
                        setSelectedOrganization(currentChat);
                        setShowOrganizationProfileModal(true);
                      }
                    }}
                    onSelfClick={() => {
                      // console.log("self", self);
                      if (self.companyName) {
                        setSelectedOrganization(self);
                        setShowOrganizationProfileModal(true);
                      }
                    }}
                  />
                )
            )}

            <MuiBox height={100} ref={chatBottomRef}></MuiBox>
            {picture.localUri && (
              <PictureDisplay picture={picture} setPicture={setPicture} />
            )}
          </Box>
        </Flex>

        <Box
          flexDirection="row"
          background={chatBorderColor}
          boxShadow={!mobile && "xl"}
          borderTop={mobile && "2px solid #d9d9d9"}
          margin={"5px"}
          borderRadius={!mobile && "20px"}
          backgroundColor="#fff"
          sx={
            mobile && {
              bottom: -1,
              position: "fixed",
              width: "100%",
            }
          }
        >
          <Flex pl={4} pr={2} py={2} height="100%">
            <Input
              color={chatTextColor}
              variant="unstyled"
              placeholder="Type your message"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              disabled={picture.localUri}
              onKeyDown={(e) => {
                console.log(e.key);
                if (e.key === "Enter" && (chatInput || picture.localUri)) {
                  onSendClick();
                }
              }}
            />
            <IconButton
              color="theme"
              disabled={chatInput.length === 0 && !picture.localUri}
              onClick={onSendClick}
            >
              <Iconify icon="fluent:send-28-filled" />
            </IconButton>
            <IconButton
              sx={{ mr: 1 }}
              variant="contained"
              color="theme"
              component="label"
            >
              <Iconify icon="ant-design:plus-outlined" />
              <input type="file" hidden onChange={onChangeImage} />
            </IconButton>

            {currentUser?.type === "organization" && (
              <IconButton
                color="theme"
                variant="contained"
                onClick={() => setShowShipmentModal(true)}
                // onClick={sendShipmentStatusEmail}
              >
                <Iconify
                  icon="carbon:delivery-add"
                  sx={{ height: 30, width: 30 }}
                />
              </IconButton>
            )}
            {currentUser?.type === "organization" &&
              other.type === "organization2" && (
                <IconButton
                  color="theme"
                  variant="contained"
                  onClick={() => setShowAssignableShipmentsModal(true)}
                >
                  <Iconify
                    icon="carbon:delivery-truck"
                    sx={{ height: 30, width: 30 }}
                  />
                </IconButton>
              )}

            {currentUser?.type === "transport" && other.type === "driver" && (
              <IconButton
                color="theme"
                variant="contained"
                onClick={() => setShowTransportAssignableShipmentsModal(true)}
              >
                <Iconify
                  icon="carbon:delivery-truck"
                  sx={{ height: 30, width: 30 }}
                />
              </IconButton>
            )}
          </Flex>
        </Box>
      </Flex>

      <ShipmentModal
        userId={self._id}
        organizationId={currentChat.receiverId}
        organizationEmail={currentChat.email}
        open={showShipmentModal}
        setOpen={setShowShipmentModal}
        onShipmentSuccess={onSendClick}
        currentUser={currentUser}
      />
      <AssignableShipmentsModal
        transportId={currentChat._id}
        open={showAssignableShipmentsModal}
        setOpen={setShowAssignableShipmentsModal}
        onShipmentAssignSuccess={(shipmentId) => {
          onSendChatMessage({
            type: "shipment",
            shipmentId,
            chatInput,
          });
        }}
      />
      <TransportAssignableShipmentsModal
        driverId={currentChat._id}
        open={showTransportAssignableShipmentsModal}
        setOpen={setShowTransportAssignableShipmentsModal}
        onShipmentAssignSuccess={(shipmentId) => {
          onSendChatMessage({
            type: "shipment",
            shipmentId,
            chatInput,
          });
        }}
      />
      <OrganizationProfileModal
        open={showOrganizationProfileModal}
        setOpen={setShowOrganizationProfileModal}
        organization={selectedOrganization}
        onClose={() => setShowOrganizationProfileModal(false)}
      />
    </>
  );
};

export default ChatView;
