import {
  Avatar,
  AvatarBadge,
  Box,
  Divider,
  Heading,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { Colors } from "../../../components/config";
import Text from "../../../components/Text";
import Iconify from "../../../components/Iconify";

const ChatList = ({
  chatsList,
  currentChat,
  setCurrentChat,
  onSingleChatClick,
  isDesktop,
}) => {
  const chatListBackgroundColor = useColorModeValue(
    "brand.500",
    "whiteAlpha.500"
  );
  const chatBorderColor = useColorModeValue("whiteAlpha.900", "whiteAlpha.200");
  const chatTextColor = useColorModeValue("blackAlpha.900", "whiteAlpha.900");
  const chatBackgroundColor = useColorModeValue("brand.500", "whiteAlpha.500");

  return (
    <Box
      bg={"white"}
      height={"100%"}
      background={chatBorderColor}
      boxShadow="xl"
      borderRadius={"20px"}
      px={4}
      py={4}
    >
      <Heading as="h3" size="sm" pb={3}>
        Recent Chats
      </Heading>
      <List spacing={3}>
        {chatsList.map((singleChat, key) => {
          return (
            <>
              <ListItem
                backgroundColor={
                  currentChat?.chatroomId === singleChat.chatroomId &&
                  Colors.greyBackground
                }
                display="flex"
                alignItems="center"
                onClick={() => onSingleChatClick(singleChat)}
                cursor="pointer"
                overflow={"hidden"}
              >
                <Stack pr={"10px"}>
                  {singleChat.type === "group" ? (
                    <Avatar
                      boxSize="1.7em"
                      mr={1}
                      icon={
                        <Iconify
                          icon="mdi:users-group"
                          sx={{
                            height: 30,
                            width: 30,
                          }}
                        />
                      }
                      backgroundColor={"transparent"}
                    >
                      {singleChat.isActive ? (
                        <AvatarBadge boxSize="1em" bg="green.500" />
                      ) : (
                        <AvatarBadge />
                      )}
                    </Avatar>
                  ) : (
                    <Avatar
                      boxSize="1.7em"
                      mr={1}
                      src={singleChat.profilePicture}
                    >
                      {singleChat.isActive ? (
                        <AvatarBadge boxSize="1em" bg="green.500" />
                      ) : (
                        <AvatarBadge />
                      )}
                    </Avatar>
                  )}
                </Stack>
                <VStack spacing={0} align="stretch">
                  <Text>{singleChat.name}</Text>
                  <Box fontSize="0.7em">
                    <Text
                      fontSize={14}
                      color={Colors.darkgreyText}
                      sx={{
                        width: singleChat.message?.length > 20 ? "40%" : "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {singleChat.message}
                    </Text>
                    <Text fontSize={12} color={Colors.darkgreyText}>
                      {moment(Math.round(singleChat.timeStamp)).format(
                        "DD MMM, YYYY"
                      )}
                    </Text>
                  </Box>
                </VStack>
              </ListItem>
              <Divider orientation="horizontal" />
            </>
          );
        })}
      </List>
    </Box>
  );
};

export default ChatList;
