import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Avatar,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  actions,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";

// Custom components
import Card from "../../../components/card/Card";

import { useHistory, useLocation } from "react-router-dom";

import { SHIPMENT } from "../../../redux/constants";
import { getShipmentNextStatus } from "../../sharedComponents/home/util";
import { getColor, getIcon } from "../../sharedComponents/utils";

export default function OrganizationDataTable(props) {
  const {
    columnsData,
    tableData,
    dataType,
    currentUser,
    onRequestActionClick,
    organizationId,
    onDelete,
    onChat,
    onProfileClick,
    organizationEdit,
  } = props;

  //console.log("currentUser", { organizationId });
  const userType = currentUser?.type;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const location = useLocation();

  console.log("location", location);

  const history = useHistory();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {dataType || "Recent Shipments"}
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  // console.log("cell", { cell, index, row: cell.row.original });
                  let data = "";
                  if (cell.column.Header === "From") {
                    data = (
                      <Flex align="center">
                        <Avatar size="sm" mr={2} src={cell.value.logo} />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.companyName}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "To") {
                    data = (
                      <Flex align="center">
                        <Avatar size="sm" mr={2} src={cell.value.logo} />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.companyName}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Status") {
                    data = (
                      <Flex align="center">
                        <Icon
                          w="24px"
                          h="24px"
                          me="5px"
                          color={getColor(cell.value)}
                          as={getIcon(cell.value)}
                        />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value === SHIPMENT.POD_ATTACHED
                            ? "POD-ATTACHED"
                            : cell.value === SHIPMENT.DRIVER_ASSIGNED
                            ? "DRIVER-ASSIGNED"
                            : cell.value.toUpperCase()}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Address") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "Organization") {
                    data = (
                      <Flex
                        align="center"
                        cursor="pointer"
                        onClick={() => onProfileClick(cell.value)}
                      >
                        <Avatar size="sm" mr={2} src={cell.value.logo} />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.name}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Created On") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {moment.unix(cell.value).format("DD MMM, YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "Created By") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (
                    cell.column.Header === "Action" &&
                    cell.value.organization2 === organizationId
                  ) {
                    data = (
                      <Flex>
                        <Button
                          variant="darkBrand"
                          color="white"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="70px"
                          px="16px"
                          py="5px"
                          mr="5px"
                          onClick={() =>
                            onRequestActionClick(
                              cell.value.requestId,
                              "accepted",
                              cell.value.organization1
                            )
                          }
                        >
                          Accept
                        </Button>

                        <Button
                          variant="outline"
                          color="red.500"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="70px"
                          px="16px"
                          py="5px"
                          onClick={() =>
                            onRequestActionClick(
                              cell.value.requestId,
                              "rejected"
                            )
                          }
                        >
                          Reject
                        </Button>
                      </Flex>
                    );
                  } else if (
                    cell.column.Header === "Action" &&
                    cell.value.organization1 === organizationId
                  ) {
                    data = (
                      <Flex>
                        <Button
                          variant="darkBrand"
                          color="white"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="70px"
                          px="16px"
                          py="5px"
                          mr="5px"
                          onClick={() =>
                            onRequestActionClick(
                              cell.value.requestId,
                              "deleted"
                            )
                          }
                        >
                          Cancel
                        </Button>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Action") {
                    data = (
                      <Flex>
                        <Button
                          variant="darkBrand"
                          color="white"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="70px"
                          px="24px"
                          py="5px"
                          mr="5px"
                          onClick={() => {
                            onChat(cell.value.organizationId);
                          }}
                        >
                          Chat
                        </Button>
                        <Button
                          variant="darkBrand"
                          color="white"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="70px"
                          px="24px"
                          py="5px"
                          onClick={() => {
                            if (cell.value.requestId) {
                              onRequestActionClick(
                                cell.value.requestId,
                                "deleted"
                              );
                            } else {
                              onDelete(cell.value.organizationId);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Shipment") {
                    data = (
                      <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="24px"
                        py="5px"
                        onClick={() =>
                          history.push(
                            `${location.pathname
                              .toLowerCase()
                              .split("/")
                              .slice(0, 2)
                              .join("/")}/shipments/${cell.value}`
                          )
                        }
                      >
                        Details
                      </Button>
                    );
                  } else if (cell.column.Header === "Change Status to") {
                    data = (
                      <Button
                        width="100%"
                        variant={
                          getShipmentNextStatus(cell.value.status) ===
                          "Completed"
                            ? "unstyled"
                            : "darkBrand"
                        }
                        color={
                          getShipmentNextStatus(cell.value.status) ===
                          "Completed"
                            ? "#000"
                            : "white"
                        }
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="16px"
                        py="5px"
                        disabled={
                          getShipmentNextStatus(cell.value.status) ===
                          "Completed"
                        }
                        onClick={() => {
                          if (cell.value.status === SHIPMENT.DELIVERED) {
                            history.push({
                              pathname: `${location.pathname
                                .toLowerCase()
                                .split("/")
                                .slice(0, 2)
                                .join("/")}/shipments/${cell.value.shipmentId}`,
                              state: { isPod: true },
                            });
                          } else {
                            props.onStatusChangeClick(
                              cell.value,
                              getShipmentNextStatus(cell.value.status)
                            );
                          }
                        }}
                      >
                        {getShipmentNextStatus(cell.value.status) ===
                        "Completed"
                          ? "Completed"
                          : getShipmentNextStatus(cell.value.status)}
                      </Button>
                    );
                  } else if (cell.column.Header === "Chat") {
                    data = (
                      <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="24px"
                        py="5px"
                        onClick={() => props.onChatClick(cell.value)}
                      >
                        Chat
                      </Button>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
