import { combineReducers } from "redux";
import { customerReducer } from "./customerReducer";
import { driverReducer } from "./driverReducer";

import { organizationReducer } from "./organizationReducer";
import { shipmentReducer } from "./shipmentReducer";
import { invoiceReducer } from "./invoiceReducer";

import { transportReducer } from "./transportReducer";

import uiReducer from "./uiReducer";

const reducers = combineReducers({
  organization: organizationReducer,
  transport: transportReducer,
  customer: customerReducer,
  driver: driverReducer,
  shipment: shipmentReducer,
  invoice: invoiceReducer,
  ui: uiReducer,
});

export default reducers;
