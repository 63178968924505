export const CREATE_SHIPMENT = "CREATE_SHIPMENT";
export const SET_ALL_SHIPMENTS = "SET_ALL_SHIPMENTS";

export const SET_CURRENT_SHIPMENT = "SET_CURRENT_SHIPMENT";

export const SET_SHIPMENT_ID_TO_BE_ASSIGNED = "SET_SHIPMENT_ID_TO_BE_ASSIGNED";

export const RESET_SHIPMENT_DATA = "RESET_SHIPMENT_DATA";

export const SET_SHIPMENTS_ORGANIZATIONS = "SET_SHIPMENTS_ORGANIZATIONS";
