import {
  CREATE_SHIPMENT,
  RESET_SHIPMENT_DATA,
  SET_ALL_SHIPMENTS,
  SET_CURRENT_SHIPMENT,
  SET_SHIPMENTS_ORGANIZATIONS,
  SET_SHIPMENT_ID_TO_BE_ASSIGNED,
} from "../actions/shipmentActions";

const initialState = {
  shipments: [],
  shipment: null,
  shipmentIdToBeAssigned: null,
  shipmentsOrganizations: [],
};

export const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SHIPMENT:
      return { ...state, shipments: [...state.shipments, action.payload] };

    case SET_ALL_SHIPMENTS:
      return { ...state, shipments: action.payload };

    case SET_CURRENT_SHIPMENT:
      return { ...state, shipment: action.payload };

    case SET_SHIPMENT_ID_TO_BE_ASSIGNED:
      return { ...state, shipmentIdToBeAssigned: action.payload };

    case SET_SHIPMENTS_ORGANIZATIONS:
      return { ...state, shipmentsOrganizations: action.payload };

    case RESET_SHIPMENT_DATA:
      return initialState;

    default:
      return state;
  }
};
