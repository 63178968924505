import { Flex, Text, Textarea } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useState } from "react";
import Card from "../../../components/card/Card";
import CustomButton from "../../../components/CustomButton";
import Project from "./Project";

const RateList = ({ value, onSave }) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [editRateList, setEditRateList] = useState(false);
  const [rateList, setRateList] = useState(value);

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="20px"
        mt="10px"
        mb="20px"
      >
        Rate list
      </Text>
      {!editRateList ? (
        <Flex>
          <Project
            boxShadow={cardShadow}
            mb="20px"
            ranking={value || "Define your rate list"}
            link="#"
            rankingFs="18px"
            // title="Rate list"
            onEdit={() => {
              console.log("Edit");
              setEditRateList(true);
            }}
          />
        </Flex>
      ) : (
        <Flex direction="column" align="center">
          <Textarea
            placeholder="Rate list"
            mb="20px"
            rows={2}
            resize="none"
            value={rateList}
            sx={{
              "&:focus": {
                zIndex: 1,
                borderColor: "#ff33a0",
                boxShadow: "0 0 0 1px #ff33a0",
                height: 200,
              },
              "::-webkit-scrollbar": {
                display: "none",
              },

              fontSize: "18px",
            }}
            onChange={(e) => {
              console.log("e", e.target.value);
              setRateList(e.target.value);
            }}
          />
          <Flex>
            <CustomButton
              large
              width={100}
              mr="10px"
              disabled={!rateList}
              onClick={() => {
                setEditRateList(false);
                onSave(rateList);
              }}
            >
              Save
            </CustomButton>
            <CustomButton
              large
              width={100}
              onClick={() => {
                setEditRateList(false);
              }}
              variant="outlined"
            >
              Cancel
            </CustomButton>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};

export default RateList;
