import { Flex } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import { useAPIActions } from "../../hooks/useActions";
import useResponsive from "../../hooks/useResponsive";
import { SHIPMENT } from "../../redux/constants";
import DriverSearchModal from "./DriverSearchModal";
import OrganizationSearchModal from "./OrganizationSearchModal";
import TransportSearchModal from "./TransportSearchModal";

const OrganizationShipment = ({
  shipment,
  priceInput,
  setPriceInput,
  self,
  other,
  organization2,
  owner,
}) => {
  console.log("OrganizationShipment => ", {
    shipment,
    organization2,
    self,
    other,
  });

  const [showTransportSearchModal, setShowTransportSearchModal] =
    useState(false);

  const [showDriverSearchModal, setShowDriverSearchModal] = useState(false);

  const [showOrganizationSearchModal, setShowOrganizationSearchModal] =
    useState(false);

  const {
    updateShipment,
    setSnackbarAction,
    createInvoiceForCustomer,
    sendShipmentStatusEmail,
  } = useAPIActions();

  const { currentUser } = useSelector((state) => state.ui);

  const showMobileButtons = useResponsive("down", "500");

  const onAcceptShipment = () => {
    console.log("accept shipment");

    setPriceInput("");

    updateShipment(
      shipment._id,
      {
        status: SHIPMENT.ACCEPTED,
        customerCost: priceInput,
      },
      currentUser,
      (shipment) => {
        console.log("shipment updated => ", shipment);

        setSnackbarAction({
          open: true,
          message: "Shipment Accepted",
          severity: "success",
        });

        //  createInvoiceForCustomer(shipment);

        let adminUserOfOrganization = other.users.find(
          (user) => user.role === "admin"
        );

        console.log("adminUserOfOrganization", adminUserOfOrganization);

        if (adminUserOfOrganization) {
          sendShipmentStatusEmail({
            shipmentNumber: shipment.shipmentNumber,
            status: "Accepted",
            to: adminUserOfOrganization.email,
            notificationData: {
              organization: shipment.customer,
              heading: "Shipment Accepted",
              message: `Shipment#${shipment.shipmentNumber} has been accepted`,
            },
          });
        }
      }
    );
  };

  const onRejectShipment = () => {
    console.log("reject shipment");

    updateShipment(
      shipment._id,
      {
        status: SHIPMENT.CANCELLED,
      },
      currentUser,
      (shipment) => {
        console.log("shipment updated => ", shipment);

        setSnackbarAction({
          open: true,
          message: "Shipment Rejected",
          severity: "success",
        });

        //  createInvoiceForCustomer(shipment);

        let adminUserOfOrganization = other.users.find(
          (user) => user.role === "admin"
        );

        console.log("adminUserOfOrganization", adminUserOfOrganization);

        if (adminUserOfOrganization) {
          sendShipmentStatusEmail({
            shipmentNumber: shipment.shipmentNumber,
            status: "Rejected",
            to: adminUserOfOrganization.email,
            notificationData: {
              organization: shipment.customer,
              heading: "Shipment Rejected",
              message: `Shipment#${shipment.shipmentNumber} has been rejected`,
            },
          });
        }
      }
    );
  };

  const onCancelShipment = () => {
    console.log("cancel shipment");

    updateShipment(
      shipment._id,
      {
        status: SHIPMENT.CANCELLED,
      },
      currentUser,
      (shipment) => {
        console.log("shipment updated => ", shipment);

        setSnackbarAction({
          open: true,
          message: "Shipment Cancelled",
          severity: "success",
        });

        //  createInvoiceForCustomer(shipment);

        let adminUserOfOrganization = other.users.find(
          (user) => user.role === "admin"
        );

        console.log("adminUserOfOrganization", adminUserOfOrganization);

        if (adminUserOfOrganization) {
          sendShipmentStatusEmail({
            shipmentNumber: shipment.shipmentNumber,
            status: "Cancelled",
            to: adminUserOfOrganization.email,
            notificationData: {
              organization: shipment.customer,
              heading: "Shipment Cancelled",
              message: `Shipment#${shipment.shipmentNumber} has been cancelled`,
            },
          });
        }
      }
    );
  };

  const onSendShipment = () => {
    console.log("onSendShipment shipment");

    setShowTransportSearchModal(true);

    // updateShipment(shipment._id, {
    //   status: "assigned",
    // });
  };

  return (
    <Flex justifyContent="center" flex={1}>
      {shipment.status !== SHIPMENT.PICKED && owner && (
        <Flex direction={showMobileButtons ? "column-reverse" : "row"}>
          <CustomButton
            onClick={onCancelShipment}
            large
            mr={showMobileButtons ? 0 : 2}
            variant={"outlined"}
          >
            <Text fontSize={18} fontWeight={600}>
              Cancel Shipment
            </Text>
          </CustomButton>
        </Flex>
      )}

      {shipment.status === SHIPMENT.PENDING && !owner && (
        <Flex direction={showMobileButtons ? "column-reverse" : "row"}>
          <CustomButton
            onClick={onRejectShipment}
            large
            mr={showMobileButtons ? 0 : 2}
            variant={"outlined"}
          >
            <Text fontSize={18} fontWeight={600}>
              Reject Shipment
            </Text>
          </CustomButton>
          <CustomButton
            large
            onClick={onAcceptShipment}
            mb={showMobileButtons ? 1 : 0}
            disabled={!priceInput}
          >
            <Text fontSize={18} fontWeight={600}>
              Accept Shipment
            </Text>
          </CustomButton>
        </Flex>
      )}

      {shipment.status === SHIPMENT.ACCEPTED && (
        <Flex direction={showMobileButtons ? "column-reverse" : "row"}>
          <CustomButton
            mr={showMobileButtons ? 0 : 2}
            large
            onClick={() => setShowDriverSearchModal(true)}
          >
            <Text fontSize={18} fontWeight={600}>
              Assign to Driver
            </Text>
          </CustomButton>
          <CustomButton
            mb={showMobileButtons ? 1 : 0}
            large
            onClick={() => setShowOrganizationSearchModal(true)}
          >
            <Text fontSize={18} fontWeight={600}>
              Assign to Organization
            </Text>
          </CustomButton>
        </Flex>
      )}

      {shipment.status === SHIPMENT.ASSIGNED && organization2 && (
        <Flex>
          <CustomButton large onClick={() => setShowDriverSearchModal(true)}>
            <Text fontSize={18} fontWeight={600}>
              Assign to Driver
            </Text>
          </CustomButton>
        </Flex>
      )}

      <TransportSearchModal
        open={showTransportSearchModal}
        setOpen={setShowTransportSearchModal}
        shipmentId={shipment._id}
      />

      <DriverSearchModal
        open={showDriverSearchModal}
        setOpen={setShowDriverSearchModal}
        organization2={self}
        shipmentId={shipment._id}
      />

      {shipment && (
        <OrganizationSearchModal
          open={showOrganizationSearchModal}
          setOpen={setShowOrganizationSearchModal}
          shipmentId={shipment._id}
          shipment={shipment}
        />
      )}
    </Flex>
  );
};

export default OrganizationShipment;
