import { LoadingButton } from "@mui/lab";
import React from "react";

const CustomButton = ({
  children,
  isSubmitting,
  disabled,
  onClick,
  type,
  fullWidth,
  large,
  width,
  height,
  variant,
  minWidth,
  component,
  mr,
  ml,
  mt,
  mb,
}) => {
  return (
    <LoadingButton
      sx={{
        width: width,
        height: height,
        minWidth: minWidth,
        mr: mr,
        ml: ml,
        mt: mt,
        mb: mb,
      }}
      fullWidth={fullWidth || false}
      size={large ? "large" : "small"}
      type={type}
      variant={variant ? variant : "contained"}
      color="theme"
      loading={isSubmitting}
      onClick={onClick}
      disabled={disabled}
      component={component}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomButton;
