import {
  SET_TRANSPORT,
  SET_TRANSPORT_USERS,
  SET_ALL_TRANSPORTS,
  SET_TRANSPORT_USER,
  SET_TRANSPORT_CHATS,
  SET_TRANSPORT_CHATS_MESSAGES,
} from "../actions/transportActions";

const initialState = {
  transport: null,
  transports: [],
  chats: {},
  chatsMessages: {},
};

export const transportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_TRANSPORTS:
      return { ...state, transports: action.payload };
    case SET_TRANSPORT_USERS:
      return { ...state, transportUsers: action.payload };

    case SET_TRANSPORT_USER:
      return { ...state, transportUser: action.payload };

    case SET_TRANSPORT:
      return { ...state, transport: action.payload };

    case SET_TRANSPORT_CHATS:
      return {
        ...state,
        chats: action.payload,
      };

    case SET_TRANSPORT_CHATS_MESSAGES:
      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [action.payload.chatroomId]: action.payload.messages,
        },
      };

    default:
      return state;
  }
};
