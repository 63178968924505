import React, { useState } from "react";
import { FormProvider } from "../../../../components/hook-form";
import { Link as RouterLink } from "react-router-dom";

import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../../../redux/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../../../components/hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Box, IconButton, InputAdornment, Link, styled } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { useAPIActions } from "../../../../hooks/useActions";
import { useHistory } from "react-router-dom";
import { getMimeType } from "../../../../utils";
import Text from "../../../../components/Text";
import { Colors } from "../../../../components/config";
import { appRoutes } from "../../../../assets/constants";
import { useDispatch } from "react-redux";
import { setSnackbarAction } from "../../../../redux/actionCreators/uiActionCreators";
import { getVerificationEmail } from "../../../../redux/actionCreators/organizationActionCreators";

const ImageOverlayStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "black",
  opacity: 0.2,
  position: "absolute",
  // width: "100%",
  // height: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  top: 0,
  borderRadius: 10,
  overflow: "hidden",
}));

const SignInForm = () => {
  const { loginOrganizationUser, checkVerification } = useAPIActions();

  const history = useHistory();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      ),
  });

  const defaultValues = {
    email: "",
    password: "",
    // email: "john@acme.com",
    // password: "acme123456",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    const { email, password } = methods.getValues();

    const user = { email: email.toLowerCase(), password };

    checkVerification(email.toLowerCase(), async (success) => {
      if (success) {
        loginOrganizationUser(user, () => {
          history.push("/organization");
        });
      } else {
        dispatch(
          setSnackbarAction({
            message: "Email not verified",
            severity: "error",
          })
        );

        const response = await getVerificationEmail(email.toLowerCase());

        if (!response.error) {
          setTimeout(() => {
            dispatch(
              setSnackbarAction({
                message: "Verification Email Sent",
                severity: "success",
              })
            );
          }, 2000);
        }
      }
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={10}>
        <RHFTextField name="email" label="Email" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Flex justifyContent="flex-end" mt={3} mb={5}>
        <Link
          variant="subtitle2"
          color="text.theme"
          component={RouterLink}
          to={appRoutes.organizationForgotPassword}
          sx={{
            textDecoration: "none",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Forgot Password?
        </Link>
      </Flex>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="theme"
        loading={isSubmitting}
      >
        Sign In
      </LoadingButton>
    </FormProvider>
  );
};

export default SignInForm;
