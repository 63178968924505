// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useState } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import tableDataComplex from "../../app/shipments/variables/tableDataComplex.json";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";
import {
  columnsDataComplex,
  organizationUserDataComplex,
} from "../../sharedComponents/home/variables/columnsData";
import {
  cleanOrganizationUsersData,
  cleanShipmentData,
} from "../../sharedComponents/home/util";
import { useSelector } from "react-redux";
import { SHIPMENT } from "../../../redux/constants";
import AddUsersModal from "./AddUsersModal";
import { useAPIActions } from "../../../hooks/useActions";
import PermissionsModal from "./PermissionsModal";

export default function Users() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const [currentOrganizationUser, setCurrentOrganizationUser] = useState(null);

  const { organizationUsers, organization } = useSelector(
    (state) => state.organization
  );

  const { currentUser } = useSelector((state) => state.ui);

  const {
    getLinkForAddingUserToOrganization,
    updateOrganizationUserPermissions,
    addUserPermissionsByUserId,
    setSnackbarAction,
  } = useAPIActions();

  console.log("organization => ", { organization, organizationUsers });

  const onAddUser = (email) => {
    getLinkForAddingUserToOrganization(
      email.toLowerCase(),
      organization._id,
      () => {
        setShowAddUserModal(false);
        setSnackbarAction({
          message: "Email has been sent to the user",
          severity: "success",
        });
      }
    );
  };

  const onAddPermission = (permissions) => {
    if (permissions?._id) {
      updateOrganizationUserPermissions(
        permissions,
        currentOrganizationUser.id,
        () => {
          setShowPermissionsModal(false);
          setSnackbarAction({
            message: "Permissions have been added to the user",
            severity: "success",
          });
        }
      );
    } else {
      addUserPermissionsByUserId(
        permissions,
        currentOrganizationUser.id,
        () => {
          setShowPermissionsModal(false);
          setSnackbarAction({
            message: "Permissions have been added to the user",
            severity: "success",
          });
        }
      );
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Users"
          value={organizationUsers.length}
        />
      </SimpleGrid>

      {(currentUser.role === "admin" || currentUser.permissions?.userEdit) && (
        <SimpleGrid
          columns={{ base: 2, md: 4, lg: 6, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Button
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            onClick={() => setShowAddUserModal(true)}
          >
            Add User
          </Button>
        </SimpleGrid>
      )}

      {organizationUsers.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="All Users"
            columnsData={organizationUserDataComplex}
            tableData={cleanOrganizationUsersData(organizationUsers)}
            onViewAndModifyPermissions={(user) => {
              console.log("user => ", user);
              setShowPermissionsModal(true);
              setCurrentOrganizationUser(user);
            }}
            userEdit={
              currentUser.role === "admin" || currentUser.permissions?.userEdit
            }
          />
        </SimpleGrid>
      )}

      <AddUsersModal
        open={showAddUserModal}
        setOpen={setShowAddUserModal}
        onClick={onAddUser}
      />

      <PermissionsModal
        open={showPermissionsModal}
        setOpen={setShowPermissionsModal}
        permissions={currentOrganizationUser?.permissions}
        onClick={onAddPermission}
      />
    </Box>
  );
}
