import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";

const AddUsersModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "10px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const AddUsersModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const AddUsersModal = ({ open, setOpen, onClick }) => {
  const { currentUser } = useSelector((state) => state.ui);

  const [email, setEmail] = useState("");
  const isMobile = useResponsive("down", "600");

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <AddUsersModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Add Organization User
          </Text>
        </HeaderContainer>

        <AddUsersModalContentContainer>
          <CustomInput
            value={email}
            setValue={setEmail}
            placeholder="Enter Email Address"
          />
        </AddUsersModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton
            onClick={() => onClick(email)}
            height={50}
            width="50%"
            disabled={!email}
          >
            <Text center fontSize={18} fontWeight={600}>
              Add User
            </Text>
          </CustomButton>
        </Flex>
      </AddUsersModalStyle>
    </CustomModal>
  );
};
export default AddUsersModal;
