import { Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { Colors } from "../../../components/config";
import GroupChatCreateModal from "./GroupChatCreateModal";

const GroupChat = () => {
  const [openGroupChatCreateModal, setOpenGroupChatCreateModal] =
    useState(false);

  const onCreateGroupChatClick = () => {
    setOpenGroupChatCreateModal(true);
  };
  return (
    <Flex mb={5}>
      <CustomButton width={200} large onClick={onCreateGroupChatClick}>
        <Text fontSize={16}>Create Group Chat</Text>
      </CustomButton>

      <GroupChatCreateModal
        open={openGroupChatCreateModal}
        setOpen={setOpenGroupChatCreateModal}
      />
    </Flex>
  );
};

export default GroupChat;
