import axios from "axios";
import {
  SET_ALL_DRIVERS,
  SET_DRIVER,
  SET_DRIVER_CHATS,
  SET_DRIVER_CHATS_MESSAGES,
} from "../actions/driverActions";
import { SET_CURRENT_USER_UI, SET_SOCKET } from "../actions/uiActions";
import { SERVER_URL } from "../constants";
import { APIRequest } from "../utils/axios";
import { setLoadingAction, setSnackbarAction } from "./uiActionCreators";

import socketIO from "socket.io-client";
import {
  SET_GROUP_CHATS,
  SET_GROUP_CHATS_MESSAGES,
} from "../actions/organizationActions";

let socket;

export const signUpDriver =
  (signUpInfo, file, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      let formData = new FormData();
      formData.append("file", file);

      let imageUrlResponse = await axios({
        method: "post",
        url: `${SERVER_URL}/driver/profile-pic-upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (imageUrlResponse.data) {
        signUpInfo.profilePicture = imageUrlResponse.data;
      }

      let response = await APIRequest("/driver", "POST", signUpInfo);
      console.log("signUpDriver => ", response);

      callback();

      dispatch({
        type: SET_DRIVER,
        payload: response,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const loginDriver = (loginInfo, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/auth/driver/login", "POST", loginInfo);
    console.log("loginDriver => ", response);

    await localStorage.setItem(
      "tisnix_user_access_token",
      response.access_token
    );
    await localStorage.setItem(
      "tisnix_user_signed_in",
      JSON.stringify("/driver")
    );

    let user = await APIRequest("/driver/getCurrentUser", "GET", null, true);

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    callback();
    dispatch({
      type: SET_DRIVER,
      payload: user,
    });

    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: user._id,
        name: user.name,
        type: "driver",
      },
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
    dispatch(
      setSnackbarAction({
        severity: "error",
        message: error.message || error,
      })
    );
  }
};

export const getAllDrivers = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/driver/all", "GET", null, true);
    console.log("Get All Drivers", response);
    dispatch({
      type: SET_ALL_DRIVERS,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const updateDriver =
  (updateInfo, driverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(`/driver/${driverId}`, "PUT", updateInfo);
      console.log("updateDriver => ", response);

      if (callback) callback();

      dispatch({
        type: SET_DRIVER,
        payload: response,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const getCurrentDriver = (fallback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let user = await APIRequest("/driver/getCurrentUser", "GET", null, true);

    if (!user) {
      dispatch(setLoadingAction(false));
      fallback();
      return;
    }

    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: user._id,
        name: user.name,
        type: "driver",
      },
    });
    socket = socketIO(SERVER_URL, {
      query: `userId=${user._id}`,
    });

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    dispatch({
      type: SET_DRIVER,
      payload: user,
    });
    dispatch({
      type: SET_SOCKET,
      payload: Boolean(socket),
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    fallback();
    dispatch(setLoadingAction(false));
  }
};

export const getPasswordRequestEmailForDriver = async (email) => {
  try {
    let response = await APIRequest(`/driver/getPasswordRequestEmail`, "POST", {
      email,
    });

    console.log("getPasswordRequestEmailForDriver response", response);

    if (response.success) {
      return response;
    }
  } catch (error) {
    console.log(error);

    return {
      error: true,
      message: error?.message || "Something went wrong!",
    };
  }
};

export const resetPasswordForDriver =
  (email, password, link, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(`/driver/resetPassword`, "POST", {
        email,
        password,
        link,
      });

      console.log("resetPasswordForDriver response", response);

      dispatch(setLoadingAction(false));
      if (response.success) {
        callback();
      }
    } catch (error) {
      console.log(error);

      dispatch(setLoadingAction(false));
      return {
        error: true,
        message: error?.message || "Something went wrong!",
      };
    }
  };

export const getDriverResetPasswordLinkData = async (linkId) => {
  try {
    let response = await APIRequest(
      `/driver/reset-password-link/${linkId}`,
      "GET"
    );

    console.log("getDriverResetPasswordLinkData response", response);

    if (response.user && response.link) {
      return response;
    }
  } catch (error) {
    console.log(error);
    // if (error.message) alert(error.message);
    return {
      message: "Link expired or broken",
      error: true,
    };
  }
};

export const getDriverChatRooms = (driverId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/chat/all/driver/${driverId}`,
      "GET",
      null,
      true
    );
    console.log("getDriverChatRooms => ", response);

    let chatrooms = response.chats;

    // not a good one but for now
    let receiverResponse = (
      await Promise.all(
        chatrooms.map((chatroom) => {
          if (chatroom.organization) {
            return APIRequest(
              `/organization/${chatroom.organization}`,
              "GET",
              null,
              true
            );
          }
        })
      )
    ).filter((item) => item);

    console.log("receiverResponse", receiverResponse);

    let finalChatrooms = {};

    chatrooms.map((chatroom) => {
      receiverResponse.map((receiver) => {
        if (chatroom.organization === receiver._id) {
          chatroom.receiver = receiver;
          finalChatrooms[chatroom._id] = chatroom;
        }
      });
    });

    console.log("final chatrooms", finalChatrooms);

    dispatch({
      type: SET_DRIVER_CHATS,
      payload: finalChatrooms,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
  }
};

export const createChatroomForDriver =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomForDriver", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/driverToTransport`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomForDriver ", response);

      dispatch(getDriverChatRooms(senderId));

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getDriverChatroomMessages =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);

      let response = await APIRequest(`/chat/${chatroomId}`, "GET", null, true);

      console.log("getChatroomMessages => ", response);

      let payload = {
        chatroomId,
        messages: response,
      };

      dispatch({
        type: SET_DRIVER_CHATS_MESSAGES,
        payload: payload,
      });

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const sendDriverChatMessage =
  (
    message,
    senderId,
    receiverId,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoadingAction(true));

      let { chats, chatsMessages } = getState().driver;

      socket.emit("send-message", {
        message,
        senderId,
        receiverId,
        chatroomId,
        type: "cto",

        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
        timeStamp: Date.now(),
      });

      let newChatsObject = { ...chats };
      let newChatsMessagesObject = { ...chatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiverId,
        chatroomId,
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        console.log("new message payload", payload);

        // dispatch({
        //   type: SET_DRIVER_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_DRIVER_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const listenToDriverSocketEvents = () => async (dispatch, getState) => {
  try {
    console.log("listenToSocketEvents ");

    socket.on("receive-message", (data) => {
      const { chats, chatsMessages } = getState().driver;
      let {
        message,
        messageType,
        senderId,
        receiverId,
        chatroomId,
        timeStamp,
        attachmentUrl,
        shipmentId,
      } = data.payload;
      console.log("receive-message", {
        message,
        senderId,
        receiverId,
        chatroomId,
        timeStamp,
        messageType,
        attachmentUrl,
        shipmentId,
        data,
      });

      let newChatsObject = { ...chats };
      let newChatsMessagesObject = { ...chatsMessages };

      // first check if chatroom exists

      console.log("newChatsObject", newChatsObject[chatroomId]);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp,
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        dispatch({
          type: SET_DRIVER_CHATS_MESSAGES,
          payload,
        });
      } else {
        // chatroom does not exist
        // so create a new chatroom

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp,
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };
        let chatroom = {
          chatroomId,
        };
        console.log("new chatroom", chatroom);

        newChatsObject[chatroomId] = chatroom;

        let payload = {
          chatroomId,
          messages: [messageObject],
        };

        dispatch({
          type: SET_DRIVER_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch({
        type: SET_DRIVER_CHATS,
        payload: newChatsObject,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

// Group Chat Actions

export const listenToGroupChatMessagesForDriver =
  (driver) => async (dispatch, getState) => {
    try {
      console.log("socket => ", socket);
      socket.on("receive-group-message", (data) => {
        const { groupChats, groupChatsMessages } = getState().organization;
        console.log("receive-group-message", data);
        if (data.payload.senderId === driver._id) return;
        let {
          message,
          messageType,
          senderId,
          receiversIds,
          groupChatRoomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        } = data.payload;

        let newChatsObject = { ...groupChats };
        let newChatsMessagesObject = { ...groupChatsMessages };

        // first check if chatroom exists

        console.log("newChatsObject", newChatsObject[groupChatRoomId]);

        if (newChatsObject[groupChatRoomId]) {
          let chatroom = newChatsObject[groupChatRoomId];
          let chatroomMessages = newChatsMessagesObject[groupChatRoomId];

          let messageObject = {
            senderId: senderId,
            receiversIds: receiversIds,
            message: message,
            timeStamp,
            chatroomId: groupChatRoomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatMessages = [...chatroomMessages, messageObject];

          newChatsObject[groupChatRoomId] = { ...chatroom };

          let payload = {
            chatroomId: groupChatRoomId,
            messages: chatMessages,
          };

          dispatch({
            type: SET_GROUP_CHATS_MESSAGES,
            payload,
          });
        } else {
          // chatroom does not exist
          // so create a new chatroom

          let messageObject = {
            senderId: senderId,
            receiversIds: receiversIds,
            message: message,
            timeStamp,
            chatroomId: groupChatRoomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };
          let chatroom = {
            chatroomId: groupChatRoomId,
          };
          console.log("new chatroom", chatroom);

          newChatsObject[groupChatRoomId] = chatroom;

          let payload = {
            chatroomId: groupChatRoomId,
            messages: [messageObject],
          };

          dispatch({
            type: SET_GROUP_CHATS_MESSAGES,
            payload,
          });
        }

        dispatch({
          type: SET_GROUP_CHATS,
          payload: newChatsObject,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

export const emitJoinRoomEventForDriver =
  (chatroomId, userId) => async (dispatch) => {
    try {
      console.log("emitJoinRoomEvent => ", { chatroomId, userId });
      socket.emit("joinRoom", {
        room: chatroomId,
        userId,
      });

      socket.on("users", (data) => {
        console.log("emitJoinRoomEvent users => ", data);
      });

      socket.on("message", (data) => {
        console.log("emitJoinRoomEvent message => ", data);
      });
    } catch (error) {
      console.log(error);
    }
  };

export const sendGroupChatMessageForDriver =
  (
    message,
    senderId,
    receiversIds,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      console.log("sendGroupChatMessage => ", {
        message,
        senderId,
        receiversIds,
        chatroomId,
        messageType,
        attachmentUrl,
      });
      dispatch(setLoadingAction(true));

      let { groupChats, groupChatsMessages } = getState().organization;

      socket.emit("send-message-group", {
        message,
        senderId,
        receiversIds,
        groupChatRoomId: chatroomId,

        messageType,
        attachmentUrl,
        timeStamp: Date.now(),
      });

      let newChatsObject = { ...groupChats };
      let newChatsMessagesObject = { ...groupChatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiversIds,
        chatroomId,
        messageType,
        attachmentUrl,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiversIds: receiversIds,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        // dispatch({
        //   type: SET_ORGANIZATION_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_GROUP_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const listenToUsersAdditionInGroupChatForDriver =
  (chatroomId) => async (dispatch) => {
    try {
      console.log("listenToUsersAdditionInGroupChat => ", chatroomId);
      socket.on("users", (data) => {
        console.log("listenToUsersAdditionInGroupChat => ", data);
      });
    } catch (error) {
      console.log(error);
    }
  };

export const stopListeningToDriverSocketEvents = async () => {
  if (socket) {
    socket.off("receive-message");
    socket.off("receive-group-message");
  }
};
