import React, { useState } from "react";
import { FormProvider } from "../../../../components/hook-form";
import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../../../redux/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../../../components/hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Box, IconButton, InputAdornment, styled } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { useAPIActions } from "../../../../hooks/useActions";
import { useHistory } from "react-router-dom";
import { getMimeType } from "../../../../utils";
import Text from "../../../../components/Text";
import { Colors } from "../../../../components/config";
import { appRoutes } from "../../../../assets/constants";

const ImageOverlayStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "black",
  opacity: 0.2,
  position: "absolute",
  // width: "100%",
  // height: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  top: 0,
  borderRadius: 10,
  overflow: "hidden",
}));

const SignUpForm = () => {
  const { signUpCustomer, setSnackbarAction } = useAPIActions();

  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const [picture, setPicture] = useState("");

  const SignUpSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      ),

    repeatPassword: Yup.string()
      .required("Repeat password required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const defaultValues = {
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    if (!picture.file) {
      setSnackbarAction({
        open: true,
        message: "Please select a profile picture",
        severity: "error",
      });
      return;
    }
    const { name, email, password } = methods.getValues();

    const user = { name, email, password, type: "customer" };

    signUpCustomer(user, picture.file, () => {
      history.push(appRoutes.customerSignIn);
    });
  };

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPicture(result);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={10}>
        <RHFTextField name="name" label="Your Name" />
        <RHFTextField name="email" label="Email" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="repeatPassword"
          label="Repeat Password"
          type={showPasswordRepeat ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                >
                  <Iconify
                    icon={
                      showPasswordRepeat ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {picture?.localUri ? (
          <Box
            sx={{
              position: "relative",
              mr: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text fontSize={20} fontWeight={500} mb={2}>
              Selected Picture
            </Text>

            <img
              style={{
                height: 200,
                borderRadius: 10,
                cursor: "pointer",
              }}
              src={picture.localUri}
            />
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Text>{picture.name}</Text>
              <IconButton onClick={() => setPicture("")}>
                <Iconify
                  icon="carbon:close"
                  sx={{
                    color: Colors.red,
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Flex>
            <ImageOverlayStyle />
          </Box>
        ) : (
          <Box mb={2} display="flex" alignItems="center">
            <IconButton
              sx={{ mr: 1 }}
              variant="contained"
              color="theme"
              component="label"
            >
              <Iconify icon="ant-design:plus-outlined" />
              <input type="file" hidden onChange={onChangeImage} />
            </IconButton>
            <Text
              fontSize={16}
              fontWeight={500}
              color={Colors.extraDarkGreyText}
            >
              Add Profile Picture
            </Text>
          </Box>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="theme"
          loading={isSubmitting}
        >
          Sign Up
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default SignUpForm;
