import {
  RESET_CUSTOMER_DATA,
  SET_ALL_CUSTOMERS,
  SET_CUSTOMER,
  SET_CUSTOMER_CHATS,
  SET_CUSTOMER_CHATS_MESSAGES,
} from "../actions/customerActions";

const initialState = {
  customer: null,
  customers: [],
  chats: {},
  chatsMessages: {},
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };

    case SET_CUSTOMER_CHATS:
      return {
        ...state,
        chats: action.payload,
      };

    case SET_CUSTOMER_CHATS_MESSAGES:
      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [action.payload.chatroomId]: action.payload.messages,
        },
      };

    case RESET_CUSTOMER_DATA:
      return initialState;

    default:
      return state;
  }
};
