import { Flex, useColorModeValue } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import React from "react";
import Text from "../../../components/Text";
import { Colors } from "../../../components/config";
import CustomButton from "../../../components/CustomButton";
import useResponsive from "../../../hooks/useResponsive";

const SignInOptions = ({ current }) => {
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const isScreen400 = useResponsive("down", "400");
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      mt="20px"
      maxW="100%"
    >
      <Text mb="10px" fontSize="14px" fontWeight="500">
        {" "}
        Sign in as
      </Text>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", "3sm": "row" }}
      >
        {["organization", "driver"]
          .filter((item) => item !== current)
          .map((item, index) => (
            <Text
              mb={1}
              color={Colors.themeColor}
              fontWeight="400"
              fontSize="14px"
              key={index}
              sx={
                index !== 2 && isScreen400
                  ? { marginBottom: "10px" }
                  : index !== 2 && !isScreen400
                  ? { marginRight: "10px" }
                  : null
              }
            >
              <NavLink to={`/auth/${item}/sign-in`}>
                <CustomButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="outlined"
                  color="theme"
                  minWidth="110px"
                >
                  {item}
                </CustomButton>
              </NavLink>
            </Text>
          ))}
      </Flex>
    </Flex>
  );
};

export default SignInOptions;
