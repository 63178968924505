import { SHIPMENT } from "../../../redux/constants";

export const cleanOrganizationUsersData = (data) => {
  const cleanData = data.map((item, index) => {
    console.log("CleanOrganizationUsersData", item);
    const { name, email, profilePicture } = item;
    return {
      id: item._id,
      count: `User # ${index + 1}`,
      name,
      email,
      profilePicture: profilePicture || "https://bit.ly/sage-adebayo",
      permissions: item.permissions,
      role: item?.role ? item.role.toUpperCase() : "USER",
    };
  });
  return cleanData;
};

export const cleanShipmentData = (data) => {
  console.log("cleanShipmentData", data);

  const formattedData = data;
  console.log("formattedData", formattedData);
  const cleanData = formattedData.map((item, index) => {
    const { timeStamp, status, pickupCompany, shipmentNumber, _id } = item;
    return {
      count: `Shipment # ${shipmentNumber}`,
      status,
      date: timeStamp / 1000,
      createdBy: pickupCompany,
      shipmentId: _id,
      actionId: {
        shipmentId: _id,
        status,
      },
    };
  });
  return cleanData;
};

export const cleanOrganizationData = (data) => {
  const cleanData = data.map((item, index) => {
    // console.log("CleanOrganizationData", item);
    const { companyName } = item;
    return {
      count: `Organization # ${index + 1}`,
      name: companyName,
      chatId: item._id,
      address: item.address,
    };
  });
  return cleanData;
};

export const cleanTransportData = (data) => {
  const cleanData = data.map((item, index) => {
    //console.log("CleanTransportData", item);
    const { companyName } = item;
    return {
      count: `Transport # ${index + 1}`,
      name: companyName,
      chatId: item._id,
      address: item.address,
    };
  });
  return cleanData;
};

export const cleanDriverData = (data) => {
  console.log("cleanDriverData", data);
  const cleanData = data.map((item, index) => {
    console.log("cleanDriverData", item);
    const { name } = item;
    return {
      count: `Driver # ${index + 1}`,
      name,
      chatId: item._id,
      email: item.email,
    };
  });
  return cleanData;
};

export const cleanInvoiceData = (data) => {
  console.log("cleanInvoiceData", data);
  const cleanData = data.map((item, index) => {
    const {
      invoiceTimestamp,
      invoiceStatus,
      invoiceNumber,
      invoiceTotalAmount,
      invoiceType,
      invoiceUrl,
      _id,
    } = item;
    return {
      count: `Invoice # ${invoiceNumber}`,
      status: invoiceStatus,
      date: invoiceTimestamp,
      due: invoiceTimestamp,
      amount: invoiceTotalAmount + " €",

      type: invoiceType,
      url: invoiceUrl,
      invoiceId: _id,
      view: invoiceUrl,
    };
  });
  return cleanData;
};

export const calculateInvoiceBalance = (data) => {
  let total = 0;
  data
    .filter((item) => item.invoiceStatus !== "paid")
    .forEach((item) => {
      console.log("item", item);
      total += Number(item.invoiceTotalAmount);
    });
  return total;
};

export const getShipmentNextStatus = (status) => {
  switch (status) {
    case SHIPMENT.DRIVER_ASSIGNED:
      return "Picked Up";
    case SHIPMENT.PICKED:
      return "In Transit";
    case SHIPMENT.TRANSIT:
      return "Delivered";

    case SHIPMENT.DELIVERED:
      return "POD Attached";
    default:
      return "Completed";
  }
};
