export const handleScrolling = (loading) => {
  if (loading) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }
};

export const getMimeType = (ext) => {
  switch (ext) {
    case "jpg":
      return "image/jpg";
    case "png":
      return "image/png";
    case "jpeg":
      return "image/jpeg";
    default:
      return "image/jpg";
  }
};

export const transformCustomerChats = (chats) => {
  console.log("transformCustomerChats", chats);
  return chats.map((chat) => {
    return {
      name: chat.receiver.companyName,
      email: chat.receiver.users[0].email,
      // message : chat.messages[chat.messages.length - 1].message,
      message: "coming soon...",
      profilePicture: chat.receiver.logo,
      chatroomId: chat._id,
      isActive: chat.isActive,
      receiverId: chat.receiver._id,
      type: "organization",
    };
  });
};

export const transformOrganizationChats = (chats, currentUser) => {
  console.log("transformOrganizationChats chats", chats, currentUser);
  if (!currentUser) return [];

  let filteredChats = [];

  if (currentUser.role === "admin") {
    filteredChats = chats;
  } else {
    filteredChats = chats.filter((chat) =>
      chat?.users?.includes(currentUser.userId)
    );
  }
  return filteredChats
    .filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i)
    .map((chat) => {
      if (chat.organizationUsers) {
        return {
          name: getGroupChatName(
            [...chat.organizationUsers, ...chat.drivers],
            currentUser
          ),
          email: "Organization Users",
          sender: [...chat.organizationUsers, ...chat.drivers].filter(
            (user) => user._id === currentUser.userId
          ),
          receivers: [...chat.organizationUsers, ...chat.drivers].filter(
            (user) => user._id !== currentUser.userId
          ),
          receiverAvatars: getReceiverAvatars(
            [...chat.organizationUsers, ...chat.drivers].filter(
              (user) => user._id !== currentUser.userId
            )
          ),
          names: getUserName([
            ...chat.organizationUsers,
            ...chat.drivers,
            currentUser,
          ]),
          address: "",
          hourlyRate: "hourlyRate",
          rateList: "rateList",
          message: "coming soon...",
          profilePicture:
            "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png",
          isActive: "isActive",
          receiverId: "receiverId",
          chatroomId: chat._id,
          type: "group",
        };
      } else {
        return {
          name: chat.receiver.name || chat.receiver.companyName,
          email: chat.receiver?.email || chat.receiver?.users[0]?.email,
          // message : chat.messages[chat.messages.length - 1].message,
          address: chat.receiver.address,
          hourlyRate: chat.receiver.hourlyRate,
          rateList: chat.receiver.rateList,
          message: "coming soon...",
          profilePicture: chat.receiver.profilePicture || chat.receiver.logo,
          isActive: chat.isActive,
          receiverId: chat.receiver._id,
          chatroomId: chat._id,
          timeStamp: chat.timeStamp,
          users: chat.users,
          message:
            chat.lastMessage.messageType === "shipment"
              ? "Shipment"
              : chat.lastMessage.messageType === "image"
              ? "Image"
              : chat.lastMessage.message,

          type: chat.transport
            ? "transport"
            : chat.driver
            ? "driver"
            : chat.organization2
            ? "organization2"
            : "customer",
        };
      }
      return {
        name: chat.receiver.name || chat.receiver.companyName,
        email: chat.receiver?.email || chat.receiver?.users[0]?.email,
        // message : chat.messages[chat.messages.length - 1].message,
        address: chat.receiver.address,
        hourlyRate: chat.receiver.hourlyRate,
        rateList: chat.receiver.rateList,
        message: "coming soon...",
        profilePicture: chat.receiver.profilePicture || chat.receiver.logo,
        isActive: chat.isActive,
        receiverId: chat.receiver._id,
        chatroomId: chat._id,
        type: chat.transport
          ? "transport"
          : chat.driver
          ? "driver"
          : chat.organization2
          ? "organization2"
          : "customer",
      };
    });
};

export const transformTransportChats = (chats) => {
  console.log("transforming chats", chats);
  return chats.map((chat) => {
    return {
      name: chat.receiver.name || chat.receiver.companyName,
      email: chat.receiver?.email || chat.receiver?.users[0]?.email,

      // message : chat.messages[chat.messages.length - 1].message,
      message: "coming soon...",
      profilePicture: chat.receiver.profilePicture || chat.receiver.logo,
      receiver: chat.organization ? chat.receiver.users[0] : chat.receiver,
      isActive: chat.isActive,
      receiverId: chat.receiver._id,

      chatroomId: chat._id,
      type: chat.driver ? "driver" : "organization",
    };
  });
};

export const transformDriverChats = (chats, driver) => {
  console.log("transformDriverChats chats", chats);
  return chats
    .filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i)
    .map((chat) => {
      if (chat.organizationUsers) {
        return {
          name: getGroupChatNameForDriver(
            [...chat.organizationUsers, ...chat.drivers],
            driver
          ),
          email: "Organization Users",
          sender: [...chat.organizationUsers, ...chat.drivers].filter(
            (user) => user._id === driver._id
          ),
          receivers: [...chat.organizationUsers, ...chat.drivers].filter(
            (user) => user._id !== driver._id
          ),
          receiverAvatars: getReceiverAvatars(
            [...chat.organizationUsers, ...chat.drivers].filter(
              (user) => user._id !== driver._id
            )
          ),
          names: getUserNameForDriver([
            ...chat.organizationUsers,
            ...chat.drivers,
            driver,
          ]),
          address: "",
          hourlyRate: "hourlyRate",
          rateList: "rateList",
          message: "coming soon...",
          profilePicture:
            "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png",
          isActive: "isActive",
          receiverId: "receiverId",
          chatroomId: chat._id,
          timeStamp: chat.timeStamp,
          type: "group",
        };
      } else
        return {
          name: chat?.receiver?.companyName,
          // message : chat.messages[chat.messages.length - 1].message,
          message: "coming soon...",
          profilePicture: chat?.receiver?.logo,
          chatroomId: chat._id,
          isActive: chat.isActive,
          receiverId: chat?.receiver?._id,
          timeStamp: chat.timeStamp,
          message:
            chat.lastMessage.messageType === "shipment"
              ? "Shipment"
              : chat.lastMessage.messageType === "image"
              ? "Image"
              : chat.lastMessage.message,
          type: "transport",
        };
    });
};

export const checkUserSignedIn = () => {
  try {
    const isLoggedInLS = localStorage.getItem("tisnix_user_signed_in");

    const isLoggedIn = JSON.parse(isLoggedInLS);

    return isLoggedIn;
  } catch (error) {
    return false;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isInt = (value) => {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  );
};

export const getRoutesBasedOnPermissions = (routes, layout, permissions) => {
  const filteredRoutesByLayout = routes.filter(
    (route) => route.layout === layout
  );

  let routesToBeRendered = filteredRoutesByLayout;

  if (!permissions.invoiceView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Invoices"
    );
  }

  if (!permissions.profileView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Profile"
    );
  }

  if (!permissions.shipmentView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Shipments"
    );
  }

  if (!permissions.chatView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Chat"
    );
  }

  if (!permissions.driverView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Drivers"
    );
  }

  if (!permissions.userView) {
    routesToBeRendered = routesToBeRendered.filter(
      (route) => route.name !== "Users"
    );
  }

  console.log("routesToBeRendered", routesToBeRendered);

  return routesToBeRendered;
};

export const getDefaultRoutesForOrganizationUser = (routes, layout) => {
  const filteredRoutesByLayout = routes.filter(
    (route) => route.layout === layout
  );

  let routesToBeRendered = filteredRoutesByLayout;

  routesToBeRendered = routesToBeRendered.filter(
    (route) => route.name !== "Invoices"
  );

  // routesToBeRendered = routesToBeRendered.filter(
  //   (route) => route.name !== "Profile"
  // );

  routesToBeRendered = routesToBeRendered.filter(
    (route) => route.name !== "Shipments"
  );

  // routesToBeRendered = routesToBeRendered.filter(
  //   (route) => route.name !== "Chat"
  // );

  routesToBeRendered = routesToBeRendered.filter(
    (route) => route.name !== "Drivers"
  );

  routesToBeRendered = routesToBeRendered.filter(
    (route) => route.name !== "Users"
  );

  console.log("routesToBeRendered", routesToBeRendered);

  return routesToBeRendered;
};

export const getReceiverAvatars = (receivers) => {
  let receiverAvatars = {};
  receivers.map((receiver) => {
    receiverAvatars[receiver._id] = receiver.profilePicture || receiver.logo;
  });

  return receiverAvatars;
};

export const getUserName = (users) => {
  let username = {};
  users.map((user) => {
    if (user.userId) username[user.userId] = user.name;
    else username[user._id] = user.name;
  });
  return username;
};

export const getUserNameForDriver = (users) => {
  let username = {};
  users.map((user) => {
    if (user.userId) username[user.userId] = user.name;
    else username[user._id] = user.name;
  });
  return username;
};

export const getGroupChatName = (users, currentUser) => {
  let names = [];
  users.map((user) => {
    if (user._id !== currentUser.userId && names.length < 3)
      names.push(user.name);
  });
  console.log("====================================");
  console.log(names);
  console.log("====================================");
  return names.join(",");
};

export const getGroupChatNameForDriver = (users, driver) => {
  let names = [];
  users.map((user) => {
    if (user._id !== driver._id && names.length < 3) names.push(user.name);
  });
  console.log("====================================");
  console.log(names);
  console.log("====================================");
  return names.join(",");
};
