export const cleanOrganizationDataForProfile = (organization) => {
  const { companyName, address, users, website, KVK, logo } = organization;
  return {
    companyName,
    address: address,
    email: users[0]?.email || "",
    name: users[0]?.name || "",
    website: website,
    KVK: KVK,
    logo,
  };
};

export const cleanTransportDataForProfile = (transport) => {
  const { companyName, address, email } = transport;
  return {
    name: companyName,
    address: address,
    email: email,
  };
};

export const cleanCustomerDataForProfile = (customer) => {
  const { name, email, address } = customer;
  return {
    name,
    address,
    email,
  };
};

export const cleanDriverDataForProfile = (driver) => {
  const {
    name,
    email,
    address,
    licensePlate,
    typeOfVehicle,
    maxCapacityForVehicle,
    passportNumber,
    profilePicture,
    driverLicenseNumber,
    dob,
  } = driver;
  return {
    name,
    address,
    email,
    licensePlate,
    typeOfVehicle,
    maxCapacityForVehicle,
    passportNumber,
    profilePicture,
    driverLicenseNumber,
    dob,
  };
};
