export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_SNACKBAR_UI = "SET_SNACKBAR_UI";
export const SET_CURRENT_SCREEN = "SET_CURRENT_SCREEN";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_UI = "SET_LOADING_UI";

export const SET_SOCKET = "SET_SOCKET";

export const SET_CURRENT_USER_UI = "SET_CURRENT_USER_UI";

export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

export const RESET_UI_DATA = "RESET_UI_DATA";
