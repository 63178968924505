import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import useResponsive from "../../../hooks/useResponsive";
import { Flex, Stack } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/Iconify";
import { Colors } from "../../../components/config";
import { getMimeType } from "../../../utils";
import { setSnackbarAction } from "../../../redux/actionCreators/uiActionCreators";
import axios from "axios";
import { SERVER_URL } from "../../../redux/constants";
import moment from "moment";
import ThemeProvider from "../../../muiTheme";
import { vehicleOptions } from "../../auth/signup/driver/vehicleOptions";

const ProfileEditModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 800,
  overflowY: "scroll",

  padding: "10px",

  minWidth: !isMobile && 800,

  width: isMobile && "95vw",

  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const ImageOverlayStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "black",
  opacity: 0.2,
  position: "absolute",
  // width: "100%",
  // height: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  top: 0,
  borderRadius: 10,
  overflow: "hidden",
}));

const ProfileEditModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: "100%",
  overflowY: "scroll",

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const ProfileEditModal = ({
  open,
  setOpen,
  onClick,
  driverData: {
    companyName,

    dob,
    driverLicenseNumber,
    email,
    licensePlate,
    maxCapacityForVehicle,
    typeOfVehicle,
    name,
    passportNumber,

    profilePicture,
  },
  driverId,
}) => {
  console.log("driverData", {
    companyName,

    dob,
    driverLicenseNumber,
    email,
    licensePlate,
    maxCapacityForVehicle,
    typeOfVehicle,
    name,
    passportNumber,

    profilePicture,
  });
  const { currentUser } = useSelector((state) => state.ui);

  const { updateDriver, setSnackbarAction } = useAPIActions();
  const [picture, setPicture] = useState("");

  const [_typeOfVehicle, _setTypeOfVehicle] = useState(
    (typeOfVehicle && vehicleOptions.find((v) => v.name === typeOfVehicle)) ||
      null
  );

  const isMobile = useResponsive("down", "600");

  const SignUpSchema = Yup.object().shape({
    licensePlate: Yup.string().required("License Plate is required"),
    name: Yup.string().required("Name is required"),
    dob: Yup.date()
      .max(moment().format("YYYY-MM-DD"), "Cannot add a future date")
      .required("Date of birth is required"),
    passportNumber: Yup.string().required("Passport Number is required"),

    driverLicenseNumber: Yup.string().required(
      "Driver License Number is required"
    ),
    maxCapacityForVehicle: Yup.string().required(
      "Max Capacity For Vehicle is required"
    ),

    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const defaultValues = {
    licensePlate: licensePlate || "",
    driverLicenseNumber: driverLicenseNumber || "",
    dob: dob || moment().subtract(1, "day").format("YYYY-MM-DD"),

    passportNumber: passportNumber || "",
    maxCapacityForVehicle: maxCapacityForVehicle || "",
    typeOfVehicle: typeOfVehicle || "",
    name: name || "",
    email: email || "",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    if (!picture) {
      setSnackbarAction({
        open: true,
        message: "Please select a profile picture",
        severity: "error",
      });
      return;
    }

    if (!typeOfVehicle) {
      setSnackbarAction({
        open: true,
        message: "Please select a type of vehicle",
        severity: "error",
      });
      return;
    }

    try {
      const {
        name,
        email,
        licensePlate,
        driverLicenseNumber,
        dob,
        passportNumber,
        maxCapacityForVehicle,
      } = methods.getValues();

      const driver = {
        name,
        email,
        licensePlate,
        driverLicenseNumber,
        dob,
        passportNumber,
        maxCapacityForVehicle,

        typeOfVehicle: _typeOfVehicle.name,
      };

      if (picture.file) {
        let formData = new FormData();
        formData.append("file", picture.file);

        let imageUrlResponse = await axios({
          method: "post",
          url: `${SERVER_URL}/driver/profile-pic-upload`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (imageUrlResponse.data) {
          driver.profilePicture = imageUrlResponse.data;
        }
      }

      updateDriver(
        {
          ...driver,
        },
        driverId,

        () => {
          setOpen(false);

          setSnackbarAction({
            severity: "success",
            message: "Profile updated successfully",
          });
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (profilePicture) {
      setPicture({
        localUri: profilePicture,
        name: "Profile Picture",
      });
    }
  }, []);

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPicture(result);
  };

  const RenderTypeOfVehicleArea = () => {
    return (
      <Box mb={2}>
        {_typeOfVehicle ? (
          <Flex alignItems="center">
            <Flex flex={1}>
              <img
                src={_typeOfVehicle.image}
                style={{
                  height: 30,
                }}
              />
              <Flex direction="column">
                <Text fontSize={16}>{_typeOfVehicle.name}</Text>
                {/* <Text fontSize={14}>
                 
                  address
                </Text> */}
              </Flex>
            </Flex>
            <IconButton onClick={() => _setTypeOfVehicle(null)}>
              <Iconify icon="charm:cross" />
            </IconButton>
          </Flex>
        ) : (
          <ThemeProvider>
            <Autocomplete
              sx={{ flex: 1 }}
              options={vehicleOptions}
              id="controlled-demo"
              getOptionLabel={(option) => option.name}
              renderOption={renderTypeOfVehicleOption}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              value={_typeOfVehicle}
              onChange={(event, newValue) => {
                _setTypeOfVehicle(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of Vehicle"
                  variant="outlined"
                  color="theme"
                />
              )}
            />
          </ThemeProvider>
        )}
      </Box>
    );
  };

  const renderTypeOfVehicleOption = (props, option) => (
    <Box {...props} mb={1}>
      <img
        src={option.image}
        style={{
          height: 30,

          marginRight: 10,
          borderRadius: 100,
        }}
      />

      <Text fontSize={16}>{option.name}</Text>
    </Box>
  );

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <ProfileEditModalStyle isMobile={isMobile}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <HeaderContainer>
            <Text center fontSize={22} fontWeight={600} mb={2}>
              Edit Profile
            </Text>
          </HeaderContainer>

          <ProfileEditModalContentContainer>
            <Stack spacing={10} width={"100%"}>
              <RHFTextField name="name" label="Your Name" />
              <RHFTextField name="email" label="Email" disabled={email} />
              <RHFTextField name="licensePlate" label="License Plate" />

              <RHFTextField name="dob" label="Date of birth" type="date" />
              <RHFTextField name="passportNumber" label="Passport Number" />
              <RHFTextField
                name="driverLicenseNumber"
                label="Driver License Number"
              />

              <RHFTextField
                name="maxCapacityForVehicle"
                label="Max Capacity For Vehicle"
              />

              <RenderTypeOfVehicleArea />

              {picture?.localUri ? (
                <Box
                  sx={{
                    position: "relative",
                    mr: 1,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Text fontSize={20} fontWeight={500} mb={2}>
                    Selected Profile Picture
                  </Text>

                  <img
                    style={{
                      height: 200,
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                    src={picture.localUri}
                  />
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Text>{picture.name}</Text>
                    <IconButton onClick={() => setPicture("")}>
                      <Iconify
                        icon="carbon:close"
                        sx={{
                          color: Colors.red,
                          height: 24,
                          width: 24,
                        }}
                      />
                    </IconButton>
                  </Flex>
                  <ImageOverlayStyle />
                </Box>
              ) : (
                <Box mb={2} display="flex" alignItems="center">
                  <IconButton
                    sx={{ mr: 1 }}
                    variant="contained"
                    color="theme"
                    component="label"
                  >
                    <Iconify icon="ant-design:plus-outlined" />
                    <input type="file" hidden onChange={onChangeImage} />
                  </IconButton>
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={Colors.extraDarkGreyText}
                  >
                    Add Profile Picture
                  </Text>
                </Box>
              )}
            </Stack>
          </ProfileEditModalContentContainer>

          <Flex justifyContent="center">
            <LoadingButton
              sx={{
                width: 400,
              }}
              size="large"
              type="submit"
              variant="contained"
              color="theme"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Flex>
        </FormProvider>
      </ProfileEditModalStyle>
    </CustomModal>
  );
};
export default ProfileEditModal;
