import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import { BsFillChatFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { HiTruck } from "react-icons/hi";

// Customer Imports
import CustomerHome from "./views/customer/home";
import CustomerShipments from "./views/customer/shipments";
import CustomerChat from "./views/customer/chat";
import CustomerProfile from "./views/customer/profile";
import CustomerInvoices from "./views/customer/invoices";
import CustomerSignUp from "./views/auth/signup/customer";

import DriverHome from "./views/driver/home";
import DriverShipments from "./views/driver/shipments";
import DriverChat from "./views/driver/chat";
import DriverProfile from "./views/driver/profile";
import DriverInvoices from "./views/driver/invoices";

import OrganizationHome from "./views/organization/home";
import OrganizationShipments from "./views/organization/shipments";
import OrganizationChat from "./views/organization/chat";
import OrganizationProfile from "./views/organization/profile";
import OrganizationInvoices from "./views/organization/invoices";

import TransportHome from "./views/transport/home";
import TransportShipments from "./views/transport/shipments";
import TransportChat from "./views/transport/chat";
import TransportProfile from "./views/transport/profile";
import TransportInvoices from "./views/transport/invoices";

// Auth Imports
import SignInCentered from "./views/auth/signIn";

// transport components
import TransportSignUp from "./views/auth/signup/transport";

// Organization components
import OrganizationSignUp from "./views/auth/signup/organization";
import DriverSignUp from "./views/auth/signup/driver";
import OrganizationSignIn from "./views/auth/signIn/organization";
import CustomerSignIn from "./views/auth/signIn/customer";
import DriverSignIn from "./views/auth/signIn/driver";
import TransportSignIn from "./views/auth/signIn/transport";
import SingleShipment from "./views/shipment/SingleShipment";
import Employees from "./views/organization/users";
import Drivers from "./views/organization/drivers";
import Organizations from "./views/organization/organizations";
import Users from "./views/organization/users";
import OrganizationUserSignUp from "./views/auth/signup/organizationUser";
import ForgotPassword from "./views/auth/signIn/organization/ForgotPassword";
import ResetPassword from "./views/auth/signIn/organization/ResetPassword";
import DriverForgotPassword from "./views/auth/signIn/driver/DriverForgotPassword";
import DriverResetPassword from "./views/auth/signIn/driver/DriverResetPassword";
import VerifyEmail from "./views/auth/signIn/organization/VerifyEmail";
import PrivacyPolicy from "./views/privacy/PrivacyPolicy";
import SupportPage from "./views/support/Support";

//

const routes = [
  // Customer Views
  {
    name: "Home",
    layout: "/customer",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: CustomerHome,
  },
  {
    name: "Chat",
    layout: "/customer",
    path: "/chat",
    icon: (
      <Icon as={BsFillChatFill} width="18px" height="18px" color="inherit" />
    ),
    component: CustomerChat,
  },
  {
    name: "Shipment",
    layout: "/customer",
    path: "/shipments/:id",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: SingleShipment,
  },
  {
    name: "Shipments",
    layout: "/customer",
    icon: <Icon as={HiTruck} width="20px" height="20px" color="inherit" />,
    path: "/shipments",
    component: CustomerShipments,
  },
  {
    name: "Invoices",
    layout: "/customer",
    path: "/invoices",
    icon: (
      <Icon
        as={FaFileInvoiceDollar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CustomerInvoices,
  },
  {
    name: "Profile",
    layout: "/customer",
    path: "/profile",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: CustomerProfile,
  },

  // Driver Views
  {
    name: "Home",
    layout: "/driver",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DriverHome,
  },
  {
    name: "Chat",
    layout: "/driver",
    path: "/chat",
    icon: (
      <Icon as={BsFillChatFill} width="18px" height="18px" color="inherit" />
    ),
    component: DriverChat,
  },
  {
    name: "Shipment",
    layout: "/driver",
    path: "/shipments/:id",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: SingleShipment,
  },
  {
    name: "Shipments",
    layout: "/driver",
    icon: <Icon as={HiTruck} width="20px" height="20px" color="inherit" />,
    path: "/shipments",
    component: DriverShipments,
  },
  // {
  //   name: "Invoices",
  //   layout: "/driver",
  //   path: "/invoices",
  //   icon: (
  //     <Icon
  //       as={FaFileInvoiceDollar}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: DriverInvoices,
  // },
  {
    name: "Profile",
    layout: "/driver",
    path: "/profile",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: DriverProfile,
  },
  // Organization Views
  {
    name: "Home",
    layout: "/organization",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: OrganizationHome,
  },
  {
    name: "Chat",
    layout: "/organization",
    path: "/chat",
    icon: (
      <Icon as={BsFillChatFill} width="18px" height="18px" color="inherit" />
    ),
    component: OrganizationChat,
  },
  {
    name: "Users",
    layout: "/organization",
    path: "/users",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,

    component: Users,
  },
  {
    name: "Drivers",
    layout: "/organization",
    path: "/drivers",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,

    component: Drivers,
  },
  {
    name: "Organizations",
    layout: "/organization",
    path: "/organizations",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,

    component: Organizations,
  },
  {
    name: "Shipment",
    layout: "/organization",
    path: "/shipments/:id",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: SingleShipment,
  },
  {
    name: "Shipments",
    layout: "/organization",
    icon: <Icon as={HiTruck} width="20px" height="20px" color="inherit" />,
    path: "/shipments",
    component: OrganizationShipments,
  },
  {
    name: "Invoices",
    layout: "/organization",
    path: "/invoices",
    icon: (
      <Icon
        as={FaFileInvoiceDollar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: OrganizationInvoices,
  },
  {
    name: "Profile",
    layout: "/organization",
    path: "/profile",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: OrganizationProfile,
  },
  // Transport Views
  {
    name: "Home",
    layout: "/transport",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TransportHome,
  },
  {
    name: "Chat",
    layout: "/transport",
    path: "/chat",
    icon: (
      <Icon as={BsFillChatFill} width="18px" height="18px" color="inherit" />
    ),
    component: TransportChat,
  },
  {
    name: "Shipment",
    layout: "/transport",
    path: "/shipments/:id",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: SingleShipment,
  },
  {
    name: "Shipments",
    layout: "/transport",
    icon: <Icon as={HiTruck} width="20px" height="20px" color="inherit" />,
    path: "/shipments",
    component: TransportShipments,
  },

  {
    name: "Invoices",
    layout: "/transport",
    path: "/invoices",
    icon: (
      <Icon
        as={FaFileInvoiceDollar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: TransportInvoices,
  },
  {
    name: "Profile",
    layout: "/transport",
    path: "/profile",
    icon: <Icon as={BiUserCircle} width="20px" height="20px" color="inherit" />,
    component: TransportProfile,
  },
  //
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  // {
  //   name: "Profile",
  //   layout: "/app",
  //   path: "/profile",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Transport Sign Up",
    layout: "/auth",
    path: "/transport/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: TransportSignUp,
  },
  {
    name: "Transport Sign In",
    layout: "/auth",
    path: "/transport/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: TransportSignIn,
  },
  {
    name: "Organization Sign Up",
    layout: "/auth",
    path: "/organization/sign-up/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: OrganizationSignUp,
  },
  {
    name: "Organization Sign Up",
    layout: "/auth",
    path: "/organization/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: OrganizationSignUp,
  },

  {
    name: "User Sign Up",
    layout: "/auth",
    path: "/organization/user/sign-up/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: OrganizationUserSignUp,
  },
  {
    name: "Driver Sign Up",
    layout: "/auth",
    path: "/organization/driver/sign-up/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DriverSignUp,
  },

  {
    name: "Organization Sign In",
    layout: "/auth",
    path: "/organization/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: OrganizationSignIn,
  },
  {
    name: "Organization User Forgot password",
    layout: "/auth",
    path: "/organization/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },

  {
    name: "Organization User Reset password",
    layout: "/auth",
    path: "/organization/reset-password/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
  {
    name: "Organization User Reset password",
    layout: "/auth",
    path: "/organization/verify-email/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: VerifyEmail,
  },
  // {
  //   name: "Customer Sign Up",
  //   layout: "/auth",
  //   path: "/customer/sign-up",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: CustomerSignUp,
  // },
  // {
  //   name: "Customer Sign In",
  //   layout: "/auth",
  //   path: "/customer/sign-in",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: CustomerSignIn,
  // },

  {
    name: "Driver Sign Up",
    layout: "/auth",
    path: "/driver/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DriverSignUp,
  },
  {
    name: "Driver Sign In",
    layout: "/auth",
    path: "/driver/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DriverSignIn,
  },
  {
    name: "Driver Forgot Password",
    layout: "/auth",
    path: "/driver/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DriverForgotPassword,
  },

  {
    name: "Driver Reset Password",
    layout: "/auth",
    path: "/driver/reset-password/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DriverResetPassword,
  },


  //Privacy policy and support page link
  {
    name: "Privacy Policy",
    layout: "/privacy",
    path: "/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PrivacyPolicy,
  },

  {
    name: "Support",
    layout: "/support",
    path: "/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SupportPage,
  },

  // Shipment Route
  // {
  //   name: "Single Shipment",
  //   layout: "/",
  //   path: "/shipment/:id",
  //   component: SingleShipment,
  // },
];

export default routes;
