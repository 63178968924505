// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import React, { useEffect, useState } from "react";

import ChatList from "./ChatList";
import useResponsive from "../../../hooks/useResponsive";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAPIActions } from "../../../hooks/useActions";
import { transformDriverChats } from "../../../utils";
import { styled, Box as MuiBox } from "@mui/material";
import ChatView from "../../sharedComponents/chat/ChatView";
import { stopListeningToOrganizationSocketEvents } from "../../../redux/actionCreators/organizationActionCreators";
import GroupChat from "../../organization/chat/GroupChat";
import { transformOrganizationChats } from "../../../utils";
import { stopListeningToDriverSocketEvents } from "../../../redux/actionCreators/driverActionCreators";

const MobileChatStyle = styled(MuiBox)(({ theme, currentChat }) => ({
  position: "absolute",
  top: !currentChat ? 130 : 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: currentChat && 1000,
  backgroundColor: "#fff",
}));

export default function Chat() {
  const onChatHistoryOpen = () => {};
  const onChatFilesOpen = () => {};

  const [currentChat, setCurrentChat] = useState(null);
  const isDesktop = useResponsive("up", "900");

  const socket = useSelector((state) => state.ui.socket);
  const [firstTimeChatPageLoaded, setFirstTimeChatPageLoaded] = useState(false);

  const location = useLocation();

  const { chatsMessages, driver, chats } = useSelector((state) => state.driver);

  const { groupChatsMessages, organization, groupChats } = useSelector(
    (state) => state.organization
  );

  const { shipmentIdToBeAssigned } = useSelector((state) => state.shipment);

  //console.log("driver chats", { chats, chatsMessages });

  const {
    listenToDriverSocketEvents,
    getDriverChatroomMessages,
    sendDriverChatMessage,
    setShipmentIdToBeAssigned,
    sendGroupChatMessageForDriver,
    listenToGroupChatMessagesForDriver,
    emitJoinRoomEventForDriver,
    getGroupChatroomMessages,
  } = useAPIActions();

  useEffect(() => {
    console.log("chat socket", socket);
    if (socket) {
      listenToDriverSocketEvents();
      listenToGroupChatMessagesForDriver(driver);
    }

    return () => {
      if (socket) {
        stopListeningToDriverSocketEvents();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (firstTimeChatPageLoaded) return;

    if (
      Object.keys(chats).length > 0 &&
      location.state &&
      location.state.chatId
    ) {
      setFirstTimeChatPageLoaded(true);
      const selectedChat = transformDriverChats([
        chats[location.state.chatId],
      ])[0];

      //console.log("location.state", location.state);

      getDriverChatroomMessages(selectedChat.chatroomId, () => {
        //console.log("messages fetched");
        setCurrentChat(selectedChat);

        if (location.state && shipmentIdToBeAssigned) {
          sendDriverChatMessage(
            "",
            driver._id,
            selectedChat.receiverId,
            selectedChat.chatroomId,
            "shipment",
            null,
            shipmentIdToBeAssigned
          );

          setShipmentIdToBeAssigned(null);
        }
      });
    } else if (Object.keys(chats).length > 0) {
      setFirstTimeChatPageLoaded(true);
      //  console.log("init chats", chats);

      const firstChat = transformDriverChats([chats[Object.keys(chats)[0]]])[0];
      //console.log("first chat", firstChat);
      getDriverChatroomMessages(firstChat.chatroomId, () => {
        //console.log("messages fetched");
        setCurrentChat(firstChat);
      });
    }
  }, [chats, location]);

  const onSingleChatClick = (chat) => {
    console.log("chat", { chat, currentChat });

    if (!currentChat || currentChat?.chatroomId !== chat.chatroomId) {
      if (chat.type === "group") {
        emitJoinRoomEventForDriver(chat.chatroomId, driver._id, "group");
        getGroupChatroomMessages(chat.chatroomId, () => {
          console.log("messages fetched");
          setCurrentChat(chat);
        });
      } else {
        getDriverChatroomMessages(chat.chatroomId, () => {
          console.log("messages fetched");
          setCurrentChat(chat);
        });
      }
    }
  };

  const onSendChatMessage = (message) => {
    if (currentChat.type === "group") {
      console.log("onSendChatMessage", { message, currentChat });
      sendGroupChatMessageForDriver(
        message.chatInput,
        currentChat.sender[0]._id,
        currentChat.receivers.map((receiver) => receiver._id),
        currentChat.chatroomId,
        message.type,
        message.type === "image" ? message.url : null
      );
    } else if (message.type === "image") {
      sendDriverChatMessage(
        message.chatInput,
        driver._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "image",
        message.url
      );
    } else if (message.type === "shipment") {
      sendDriverChatMessage(
        message.chatInput,
        driver._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "shipment",
        null,
        message.shipmentId
      );
    } else {
      sendDriverChatMessage(
        message.chatInput,
        driver._id,
        currentChat.receiverId,
        currentChat.chatroomId
      );
    }
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="100%">
        {isDesktop ? (
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={4}
            height="100%"
            width="100%"
          >
            <GridItem colSpan={currentChat ? 3 : 12}>
              <ChatList
                isDesktop={isDesktop}
                chatsList={
                  [...Object.keys(chats), ...Object.keys(groupChats)].length > 0
                    ? transformDriverChats(
                        [...Object.values(chats), ...Object.values(groupChats)],
                        driver
                      )
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            </GridItem>

            {currentChat && (
              <GridItem colSpan={9}>
                <ChatView
                  other={currentChat}
                  self={driver}
                  onSendChatMessage={onSendChatMessage}
                  onChatHistoryOpen={onChatHistoryOpen}
                  onChatFilesOpen={onChatFilesOpen}
                  currentChat={currentChat}
                  messages={
                    currentChat.type === "group"
                      ? Object.keys(groupChatsMessages).length > 0
                        ? groupChatsMessages[currentChat.chatroomId]
                        : []
                      : Object.keys(chatsMessages).length > 0
                      ? chatsMessages[currentChat.chatroomId]
                      : []
                  }
                />
              </GridItem>
            )}
          </Grid>
        ) : (
          <MobileChatStyle currentChat={currentChat}>
            {currentChat ? (
              <ChatView
                other={currentChat}
                self={driver}
                onSendChatMessage={onSendChatMessage}
                mobile={true}
                onChatHistoryOpen={onChatHistoryOpen}
                onChatFilesOpen={onChatFilesOpen}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                messages={
                  currentChat.type === "group"
                    ? Object.keys(groupChatsMessages).length > 0
                      ? groupChatsMessages[currentChat.chatroomId]
                      : []
                    : Object.keys(chatsMessages).length > 0
                    ? chatsMessages[currentChat.chatroomId]
                    : []
                }
              />
            ) : (
              <ChatList
                chatsList={
                  [...Object.keys(chats), ...Object.keys(groupChats)].length > 0
                    ? transformDriverChats(
                        [...Object.values(chats), ...Object.values(groupChats)],
                        driver
                      )
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            )}
          </MobileChatStyle>
        )}
      </Box>
    </>
  );
}
