import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultPrivacy from "../../layouts/privacy/Default";
import Text from "../../components/Text";
import illustration from "../../assets/img/logo.png";
import { Colors } from "../../components/config";

const PrivacyPolicy = () => {
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <DefaultPrivacy>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginTop={"40vh"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Privacy Policy
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={18} fontWeight={"bold"}>
            Last Updated: June 1, 2023
          </Text>
          <Text color={"#000"} fontSize={16}>
            This Privacy Policy describes how TisNix ("we," "us," or "our") collects, uses, and discloses information about you when you access and use our mobile application (the "App").
            <br></br><br></br><b>1. Information We Collect</b><br></br>
            We may collect the following types of information:<br></br>
            Personal Information: We may require users to provide us with personal information to use certain features of the App, such as your name, email address, and phone number.<br></br>
            Usage Information: We automatically collect information about your interactions with the App, such as the date and time of access, features used, and other usage data.<br></br>
            <br></br><b>2. How We Use Your Information</b><br></br>
            We use the information we collect to:<br></br>
            Provide, maintain, and improve the App.<br></br>
            Process transactions and send related information, including confirmations and invoices.<br></br>
            Respond to your comments, questions, and requests, and provide customer service.<br></br>
            Monitor and analyze trends, usage, and activities in connection with our App.<br></br>
            <br></br><b>3. How We Share Your Information</b><br></br>
            We do not share your personal information with third parties without your consent, except in the following circumstances:<br></br>
            To enforce our terms and conditions or to protect our rights, privacy, safety, or property.<br></br>
            To comply with any applicable law, regulation, legal process, or governmental request.<br></br>
            <br></br><b>4. Security</b><br></br>
            We take reasonable measures to help protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.<br></br>
            <br></br><b>5. Changes to this Policy</b><br></br>
            We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.<br></br>
            <br></br><b>6. Contact Us</b><br></br>
            If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].<br></br>
            <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
          </Text>
        </Box>

      </Flex>
    </DefaultPrivacy>
  );
};

export default PrivacyPolicy;
