import axios from "axios";
import {
  SET_TRANSPORT,
  SET_TRANSPORT_USERS,
  SET_ALL_TRANSPORTS,
  SET_TRANSPORT_USER,
  SET_TRANSPORT_CHATS,
  SET_TRANSPORT_CHATS_MESSAGES,
} from "../actions/transportActions";
import { SERVER_URL } from "../constants";
import { APIRequest } from "../utils/axios";
import { setLoadingAction, setSnackbarAction } from "./uiActionCreators";
import socketIO from "socket.io-client";
import {
  SET_CURRENT_USER_UI,
  SET_SEARCH_RESULTS,
  SET_SOCKET,
} from "../actions/uiActions";

let socket;

export const addTransport =
  (transport, file, user, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      let formData = new FormData();
      formData.append("file", file);

      let imageUrlResponse = await axios({
        method: "post",
        url: `${SERVER_URL}/transport/logo-upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (imageUrlResponse.data) {
        transport.logo = imageUrlResponse.data;
      }

      let transportResponse = await APIRequest("/transport", "POST", transport);
      let userResponse = await APIRequest(
        `/transport/addUser/${transportResponse._id}`,
        "POST",
        user
      );
      callback();
      console.log(
        "transportResponse",
        transportResponse,
        "userResponse",
        userResponse
      );
      dispatch({
        type: SET_TRANSPORT,
        payload: transportResponse,
      });
      dispatch({
        type: SET_TRANSPORT_USERS,
        payload: userResponse,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message || error,
        })
      );
    }
  };

export const getAllTransports = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/transport/all", "GET", null, true);
    console.log("Get All Transport", response);
    dispatch({
      type: SET_ALL_TRANSPORTS,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const getUsersByTransport = (transportId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/transport/${transportId}/users`, "GET");
    console.log("Get Users By Transport", response);
    dispatch({
      type: SET_TRANSPORT_USERS,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const getTransport = (transportId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/transport/${transportId}`, "GET");
    console.log("Get Transport", response);
    dispatch({
      type: SET_TRANSPORT,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const addTransportUser = (transportId, user) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/transport/addUser/${transportId}`,
      "POST",
      user
    );
    console.log("addTransportUser response", response);
    dispatch({
      type: SET_TRANSPORT,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const getAllTransportsUsers = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/transport/users`, "GET");
    console.log("getAllTransportsUsers response", response);
    dispatch({
      type: SET_TRANSPORT,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const deleteTransport = (transportId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(`/transport/${transportId}`, "DELETE");
    console.log("deleteTransport response", response);
    dispatch({
      type: SET_TRANSPORT,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const loginTransportUser = (loginInfo, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/auth/transport/login", "POST", loginInfo);
    console.log("loginTransportUser => ", response);
    await localStorage.setItem(
      "tisnix_user_access_token",
      response.access_token
    );
    await localStorage.setItem(
      "tisnix_user_signed_in",
      JSON.stringify("/transport")
    );

    let user = await APIRequest("/transport/getCurrentUser", "GET", null, true);

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    callback();

    dispatch({
      type: SET_TRANSPORT_USER,
      payload: user,
    });
    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: user._id,
        name: user.name,
        type: "transport",
      },
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
    dispatch(
      setSnackbarAction({
        severity: "error",
        message: error.message || error,
      })
    );
  }
};

export const getCurrentTransportUser = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let user = await APIRequest("/transport/getCurrentUser", "GET", null, true);

    let response = await APIRequest(`/transport/${user.transportId}`, "GET");

    console.log("getTransport", response);

    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: response._id,
        name: user.name,
        type: "transport",
      },
    });

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    socket = socketIO(SERVER_URL, {
      query: `userId=${response._id}`,
    });

    console.log("socket", socket);

    socket.on("online", (data) => {
      console.log("online", data);
    });

    dispatch({
      type: SET_TRANSPORT_USER,
      payload: user,
    });
    dispatch({
      type: SET_TRANSPORT,
      payload: response,
    });

    dispatch({
      type: SET_SOCKET,
      payload: Boolean(socket),
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getDriverByName = (driverName) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/driver/getDriverByName/${driverName}`,
      "GET"
    );

    console.log("getDriverByName", response);

    if (response) {
      dispatch({
        type: SET_SEARCH_RESULTS,
        payload: [response],
      });
    } else
      dispatch({
        type: SET_SEARCH_RESULTS,
        payload: [],
      });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const createChatroomForTransport =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomForTransport", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/transportToDriver`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomForTransport ", response);

      dispatch(
        getTransportChatRooms(senderId, () => {
          callback(response?.res?._id);
        })
      );

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getTransportChatRooms =
  (transportId, callback) => async (dispatch) => {
    try {
      console.log("getTransportChatRooms", transportId);
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/chat/all/transport/${transportId}`,
        "GET",
        null,
        true
      );
      console.log("getTransportChatRooms => ", response);

      let chatrooms = response.chats;

      // not a good one but for now
      let receiverResponse = (
        await Promise.all(
          chatrooms.map((chatroom) => {
            if (chatroom.driver) {
              return APIRequest(
                `/driver/${chatroom.driver}`,
                "GET",
                null,
                true
              );
            } else if (chatroom.organization) {
              return APIRequest(
                `/organization/${chatroom.organization}`,
                "GET",
                null,
                true
              );
            }
          })
        )
      ).filter((item) => item);

      console.log("receiverResponse", receiverResponse);

      let finalChatrooms = {};

      chatrooms.map((chatroom) => {
        receiverResponse.map((receiver) => {
          if (chatroom.organization === receiver._id) {
            chatroom.receiver = receiver;
            chatroom.type = receiver.type;
            finalChatrooms[chatroom._id] = chatroom;
          } else if (chatroom.driver === receiver._id) {
            chatroom.receiver = receiver;
            chatroom.type = receiver.type;
            finalChatrooms[chatroom._id] = chatroom;
          }
        });
      });

      console.log("final chatrooms", finalChatrooms);

      if (callback) callback();

      dispatch({
        type: SET_TRANSPORT_CHATS,
        payload: finalChatrooms,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getTransportChatroomMessages =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);

      let response = await APIRequest(`/chat/${chatroomId}`, "GET", null, true);

      console.log("getChatroomMessages => ", response);

      let payload = {
        chatroomId,
        messages: response,
      };

      dispatch({
        type: SET_TRANSPORT_CHATS_MESSAGES,
        payload: payload,
      });

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const sendTransportChatMessage =
  (
    message,
    senderId,
    receiverId,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoadingAction(true));

      let { chats, chatsMessages } = getState().transport;

      socket.emit("send-message", {
        message,
        senderId,
        receiverId,
        chatroomId,
        type: "cto",
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      let newChatsObject = { ...chats };
      let newChatsMessagesObject = { ...chatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiverId,
        chatroomId,
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        // dispatch({
        //   type: SET_TRANSPORT_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_TRANSPORT_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const listenToTransportSocketEvents =
  () => async (dispatch, getState) => {
    try {
      console.log("listenToSocketEvents ");

      socket.on("receive-message", (data) => {
        const { chats, chatsMessages } = getState().transport;
        let {
          message,
          messageType,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        } = data.payload;
        console.log("receive-message", {
          message,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          messageType,
          attachmentUrl,
          shipmentId,
          data,
        });

        let newChatsObject = { ...chats };
        let newChatsMessagesObject = { ...chatsMessages };

        // first check if chatroom exists

        console.log("newChatsObject", newChatsObject[chatroomId]);

        if (newChatsObject[chatroomId]) {
          let chatroom = newChatsObject[chatroomId];
          let chatroomMessages = newChatsMessagesObject[chatroomId];

          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatMessages = [...chatroomMessages, messageObject];

          newChatsObject[chatroomId] = { ...chatroom };

          let payload = {
            chatroomId,
            messages: chatMessages,
          };

          dispatch({
            type: SET_TRANSPORT_CHATS_MESSAGES,
            payload,
          });
        } else {
          // chatroom does not exist
          // so create a new chatroom

          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };
          let chatroom = {
            chatroomId,
          };
          console.log("new chatroom", chatroom);

          newChatsObject[chatroomId] = chatroom;

          let payload = {
            chatroomId,
            messages: [messageObject],
          };

          dispatch({
            type: SET_TRANSPORT_CHATS_MESSAGES,
            payload,
          });
        }

        dispatch({
          type: SET_TRANSPORT_CHATS,
          payload: newChatsObject,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
