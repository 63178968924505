// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "../../../components/calendar/MiniCalendar";
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useEffect } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";

import { useAPIActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import {
  columnsDataComplex,
  driverDataComplex,
  transportDataComplex,
} from "../../sharedComponents/home/variables/columnsData";
import {
  cleanShipmentData,
  cleanDriverData,
  calculateInvoiceBalance,
} from "../../sharedComponents/home/util";
import { useHistory } from "react-router-dom";
import { SHIPMENT } from "../../../redux/constants";

export default function Home() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { drivers } = useSelector((state) => state.driver);

  const { shipments } = useSelector((state) => state.shipment);
  const { transport, chats } = useSelector((state) => state.transport);

  const { currentUser } = useSelector((state) => state.ui);
  const { invoices } = useSelector((state) => state.invoice);

  const { createChatroomForTransport } = useAPIActions();
  console.log("drivers => ", {
    drivers,
    shipments,
  });
  const history = useHistory();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Shipments"
          value={shipments.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Active Shipments"
          value={
            shipments.filter(
              (item) =>
                item.status !== SHIPMENT.DELIVERED &&
                item.status !== SHIPMENT.POD_ATTACHED &&
                item.status !== SHIPMENT.CANCELLED
            ).length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Outstanding Invoice"
          value={`€${calculateInvoiceBalance(invoices)}`}
        />
      </SimpleGrid>

      {shipments.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            columnsData={columnsDataComplex}
            tableData={cleanShipmentData(shipments).reverse().splice(0, 7)}
          />
        </SimpleGrid>
      )}

      {drivers.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="Drivers"
            currentUser={currentUser}
            transport={true}
            columnsData={driverDataComplex}
            tableData={cleanDriverData(drivers)}
            onChatClick={(chatId) => {
              console.log("chatId", { chatId, chats, transport });
              if (Object.values(chats).find((item) => item.driver === chatId)) {
                history.push(`/transport/chat`);
              } else {
                createChatroomForTransport(transport._id, chatId, () => {
                  history.push(`/transport/chat`);
                });
              }
            }}
          />
        </SimpleGrid>
      )}
    </Box>
  );
}
