import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Avatar, Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";

const UsersModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "10px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const UsersModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const UsersModal = ({ open, setOpen, onClick, currentChat }) => {
  const isMobile = useResponsive("down", "600");

  console.log("UsersModal currentChat", currentChat);

  const { organizationUsers } = useSelector((state) => state.organization);

  console.log("organizationUsers", organizationUsers);

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <UsersModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            All Organization Users
          </Text>
        </HeaderContainer>

        <UsersModalContentContainer>
          {organizationUsers
            .filter((user) => user.role !== "admin")
            .map((user) => (
              <Flex
                key={user._id}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={3}
              >
                <Flex alignItems={"center"}>
                  <Avatar src={user.profilePicture} size="sm" mr={2} />
                  <Text fontSize={14} fontWeight={500}>
                    {user.name}
                  </Text>
                </Flex>

                <CustomButton
                  onClick={() => onClick(user._id)}
                  disabled={currentChat?.users?.includes(user._id)}
                >
                  <Text center fontSize={14} fontWeight={600}>
                    {currentChat?.users?.includes(user._id) ? "Added" : "Add"}
                  </Text>
                </CustomButton>
              </Flex>
            ))}
        </UsersModalContentContainer>
      </UsersModalStyle>
    </CustomModal>
  );
};
export default UsersModal;
