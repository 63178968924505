import { Input } from "@chakra-ui/react";
import React from "react";
import { Colors } from "./config";

const CustomInput = ({ value, setValue, placeholder, type = "text", sx }) => {
  return (
    <Input
      // variant="unstyled"
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={type}
      sx={{
        fontSize: 16,

        height: "50px",
        width: "100%",
        padding: "0px 10px",
        borderRadius: "10px",

        "&:focus": {
          borderColor: Colors.themeColor,
          boxShadow: `0 0 0 1px ${Colors.themeColor}`,
        },

        ...sx,
      }}
    />
  );
};

export default CustomInput;
