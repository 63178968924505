import { Route, Redirect } from "react-router-dom";
import { APIRequest } from "./redux/utils/axios";

const PrivateRoute = ({ children, path, ...rest }) => {
  const getAuthValue = async () => {
    try {
      const isLoggedInLS = localStorage.getItem("tisnix_user_signed_in");
      const isLoggedIn = JSON.parse(isLoggedInLS);

      return isLoggedIn;
    } catch (error) {
      console.log("PrivateRoute error", error);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={() => {
        return getAuthValue() ? (
          children
        ) : (
          <Redirect to={`/auth${path}/sign-in`} />
        );
      }}
    />
  );
};

export default PrivateRoute;
