import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";

import { Slide, Typography } from "@mui/material";
import { useAPIActions } from "../hooks/useActions";

const SnackbarAlert = () => {
  const {
    snackbar: { message, severity, open },
  } = useSelector((state) => state.ui);

  const { setSnackbarAction } = useAPIActions();

  console.log("snackbar", message, severity, open);

  const onClose = (e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarAction({
      open: false,
    });
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      TransitionComponent={TransitionUp}
      style={{
        zIndex: open ? 1400 : 0,
      }}
    >
      <MuiAlert
        onClose={onClose}
        variant="filled"
        severity={severity}
        sx={{ width: "100%" }}
      >
        <Typography fontWeight="600" textAlign="center">
          {message}
        </Typography>
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarAlert;
