import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // boxShadow: 24,
  overflow: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
  border: "none",
  outline: "none",
  // width: "90vw",

  //backgroundColor: "#fff",
};
const CustomModal = ({ open, setOpen, children, callback }) => {
  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        setOpen(false);
        if (callback) callback();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={Boolean(open)}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
};
export default CustomModal;
