import {
  MdOutlinePendingActions,
  MdCheckCircleOutline,
  MdMoreHoriz,
  MdCheckCircle,
  MdOutlineCancel,
} from "react-icons/md";
import { HiTruck } from "react-icons/hi";
import { SHIPMENT } from "../../redux/constants";

export const getIcon = (value) => {
  switch (value) {
    case SHIPMENT.PENDING:
      return MdOutlinePendingActions;
    case SHIPMENT.ACCEPTED:
      return MdCheckCircleOutline;
    case SHIPMENT.PICKED:
      return HiTruck;

    case SHIPMENT.DRIVER_ASSIGNED:
      return HiTruck;
    case SHIPMENT.DELIVERED:
      return HiTruck;

    case SHIPMENT.POD_ATTACHED:
      return HiTruck;

    case SHIPMENT.TRANSIT:
      return HiTruck;
    case SHIPMENT.COMPLETED:
      return MdCheckCircle;

    case SHIPMENT.CANCELLED:
      return MdOutlineCancel;

    case SHIPMENT.ASSIGNED:
      return MdCheckCircleOutline;
    default:
      return MdMoreHoriz;
  }
};

export const getColor = (value) => {
  switch (value) {
    case SHIPMENT.PENDING:
      return "black.500";
    case SHIPMENT.ACCEPTED:
      return "blue.500";
    case SHIPMENT.PICKED:
      return "orange.500";

    case SHIPMENT.DRIVER_ASSIGNED:
      return "orange.500";
    case SHIPMENT.DELIVERED:
      return "green.500";

    case SHIPMENT.POD_ATTACHED:
      return "green.500";

    case SHIPMENT.COMPLETED:
      return "green.500";

    case SHIPMENT.TRANSIT:
      return "orange.500";
    case SHIPMENT.CANCELLED:
      return "red.500";

    case SHIPMENT.ASSIGNED:
      return "blue.500";
    default:
      return "black.500";
  }
};

export const getShipmentsByStatus = (shipments, status) => {
  return shipments.filter((shipment) => shipment.status === status);
};
