// Chakra imports
import { Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "../../../assets/img/profile/Project1.png";
import Project2 from "../../../assets/img/profile/Project2.png";
import Project3 from "../../../assets/img/profile/Project3.png";
// Custom components

import React from "react";
import Project from "./Project";
import Card from "../../../components/card/Card";
import { MdEdit } from "react-icons/md";

const Projects = ({
  data: {
    name,
    email,
    address,
    phone,
    companyName,
    website,
    KVK,
    driverLicenseNumber,
    passportNumber,
    typeOfVehicle,
    maxCapacityForVehicle,
    licensePlate,
    dob,
  },
  onProfileEdit,
  isAdmin,
}) => {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex alignItems={"center"} mb={2}>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          About
        </Text>
        {(isAdmin || driverLicenseNumber) && (
          <Link
            href="#"
            variant="no-hover"
            me="16px"
            ms="auto"
            p="0px !important"
            onClick={onProfileEdit}
          >
            <Icon as={MdEdit} color="secondaryGray.500" h="18px" w="18px" />
          </Link>
        )}
      </Flex>
      {companyName && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project1}
          ranking={companyName}
          link="#"
          title="Company Name"
        />
      )}
      {phone && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project2}
          ranking="2"
          link="0333-3205462"
          title="Phone number"
        />
      )}
      {name && !driverLicenseNumber && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project1}
          ranking={name}
          link="#"
          title="Owned by"
        />
      )}
      {name && driverLicenseNumber && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project1}
          ranking={name}
          link="#"
          title="Name"
        />
      )}
      {email && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={email}
          link="#"
          title="Email address"
        />
      )}

      {dob && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={dob}
          link="#"
          title="Date of Birth"
        />
      )}

      {driverLicenseNumber && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={driverLicenseNumber}
          link="#"
          title="Driver License Number"
        />
      )}

      {licensePlate && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={licensePlate}
          link="#"
          title="License Plate"
        />
      )}

      {passportNumber && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={passportNumber}
          link="#"
          title="Passport Number"
        />
      )}

      {typeOfVehicle && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={typeOfVehicle}
          link="#"
          title="Type of Vehicle"
        />
      )}

      {maxCapacityForVehicle && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={maxCapacityForVehicle}
          link="#"
          title="Max Capacity For Vehicle"
        />
      )}
      {address && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={address}
          link="#"
          title="Address"
        />
      )}
      {website && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={website}
          link="#"
          title="Website"
        />
      )}
      {KVK && (
        <Project
          boxShadow={cardShadow}
          mb="20px"
          image={Project3}
          ranking={KVK}
          link="#"
          title="KVK"
        />
      )}
    </Card>
  );
};

export default Projects;
