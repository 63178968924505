import { Flex } from "@chakra-ui/react";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { Colors } from "../../../components/config";
import Iconify from "../../../components/Iconify";
import Text from "../../../components/Text";

const PictureDisplay = ({ picture, setPicture }) => {
  return (
    <Box
      sx={{
        position: "relative",
        mr: 1,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        style={{
          height: 100,
          borderRadius: 10,
          cursor: "pointer",
        }}
        src={picture.localUri}
      />
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Text>{picture.name}</Text>
        <IconButton onClick={() => setPicture("")}>
          <Iconify
            icon="carbon:close"
            sx={{
              color: Colors.red,
              height: 24,
              width: 24,
            }}
          />
        </IconButton>
      </Flex>
    </Box>
  );
};

export default PictureDisplay;
