import {
  beVanCanOpenSide,
  beVan,
  largeVan,
  lutonVan,
  smallTruck,
  smallTruckCanOpenSide,
  smallVan,
  mediumVan,
  trailer,
  trailerCanOpenSide,
  trailerOpen,
} from "../../../../assets";

export const vehicleOptions = [
  {
    name: "Small Van",
    value: "smallVan",
    image: smallVan,
  },
  {
    name: "Medium Van",
    value: "mediumVan",
    image: mediumVan,
  },
  {
    name: "Large Van",
    value: "largeVan",
    image: largeVan,
  },
  {
    name: "Luton Van",
    value: "lutonVan",
    image: lutonVan,
  },
  {
    name: "BeVan Open Side",
    value: "beVanCanOpenSide",
    image: beVanCanOpenSide,
  },
  {
    name: "BeVan",
    value: "beVan",
    image: beVan,
  },
  {
    name: "Small Truck",
    value: "smallTruck",
    image: smallTruck,
  },

  {
    name: "Small Truck Open Side",
    value: "smallTruckCanOpenSide",
    image: smallTruckCanOpenSide,
  },

  {
    name: "Trailer",
    value: "trailer",
    image: trailer,
  },
  {
    name: "Trailer Open Side",
    value: "trailerCanOpenSide",
    image: trailerCanOpenSide,
  },
  {
    name: "Trailer Open",
    value: "trailerOpen",
    image: trailerOpen,
  },
];
