import axios from "axios";
import moment from "moment";
import {
  CREATE_SHIPMENT,
  SET_CURRENT_SHIPMENT,
  SET_ALL_SHIPMENTS,
  SET_SHIPMENT_ID_TO_BE_ASSIGNED,
  SET_SHIPMENTS_ORGANIZATIONS,
} from "../actions/shipmentActions";
import { SERVER_URL, SHIPMENT } from "../constants";
import { APIRequest } from "../utils/axios";
import { setLoadingAction, setSnackbarAction } from "./uiActionCreators";

export const createShipment =
  (shipmentInfo, organizationEmail, currentUser, callback) =>
  async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest("/shipment", "POST", shipmentInfo);
      console.log("createShipment => ", response);

      setTimeout(() => {
        dispatch(
          setSnackbarAction({
            open: true,
            message: "Shipment Created",
            severity: "success",
          })
        );
      }, 500);
      if (response._id) {
        dispatch(
          sendShipmentStatusEmail({
            shipmentNumber: response.shipmentNumber,
            to: organizationEmail,
            status: "Created",
            notificationData: {
              organization: response.organization,
              heading: "Shipment Created",
              message: `Shipment#${response.shipmentNumber} has been created`,
            },
          })
        );
      }

      dispatch(getShipmentsByUserId(currentUser.type, currentUser.id));

      dispatch({
        type: CREATE_SHIPMENT,
        payload: shipmentInfo,
      });

      callback(response._id);
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const sendShipmentStatusEmail = (emailData, all) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    console.log("sendShipmentStatusEmail => ", emailData);

    if (all) {
      let emailIds = [];

      let organization = await APIRequest(
        `/organization/${emailData.organization}`
      );

      emailIds.push(organization.users[0].email);

      let customer = await APIRequest(`/organization/${emailData.customer}`);

      emailIds.push(customer.users[0].email);

      if (emailData.organization2) {
        let organization2 = await APIRequest(
          `/organization/${emailData.organization2}`
        );

        emailIds.push(organization2.users[0].email);
      }

      emailIds.push(emailData.to);

      console.log("sendShipmentStatusEmail emailIds => ", emailIds);
      let emailResponse = await APIRequest(
        "/shipment/send-shipment-email",
        "POST",
        {
          shipmentNumber: emailData.shipmentNumber,
          to: emailIds,
          status: emailData.status,
          notificationData: emailData.notificationData,
        }
      );
    } else {
      let emailResponse = await APIRequest(
        "/shipment/send-shipment-email",
        "POST",
        {
          shipmentNumber: emailData.shipmentNumber,
          to: [emailData.to],
          status: emailData.status,
          notificationData: emailData.notificationData,
        }
      );
    }

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const updateShipment =
  (shipmentId, shipmentInfo, currentUser, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/shipment/${shipmentId}`,
        "PUT",
        shipmentInfo
      );

      console.log("updateShipment => ", response);

      dispatch(getShipmentById(shipmentId));

      dispatch(getShipmentsByUserId(currentUser.type, currentUser.id));

      if (callback) callback(response);
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const uploadShipmentPODs =
  (pods, shipment, currentUser, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("pods => ", { pods, shipment });

      const podUrls = await Promise.all(
        pods.map(async (pod) => {
          let formData = new FormData();
          formData.append("file", pod.file);

          let imageUrlResponse = await axios({
            method: "post",
            url: `${SERVER_URL}/shipment/pod-upload`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          return imageUrlResponse.data;
        })
      );

      console.log("podUrls => ", podUrls);

      dispatch(
        updateShipment(
          shipment._id,
          {
            podUrls: [...shipment.podUrls, ...podUrls],
            status: SHIPMENT.POD_ATTACHED,
          },
          currentUser,
          callback
        )
      );

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const getAllShipments = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/shipment/all", "GET");
    console.log("getAllShipments => ", response);

    dispatch({
      type: SET_ALL_SHIPMENTS,
      payload: response,
    });

    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const getShipmentsByUserId =
  (type = "customer", userId) =>
  async (dispatch) => {
    console.log("getShipmentsByUserId => ", type, userId);
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(`/shipment/all/${type}/${userId}`, "GET");
      console.log("getShipmentsByUserId => ", response);

      dispatch({
        type: SET_ALL_SHIPMENTS,
        payload: response,
      });

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };
export const getShipmentById = (shipmentId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(`/shipment/${shipmentId}`, "GET");
    console.log("getShipmentById response =>", response);

    if (response?.organization) {
      const organization = await APIRequest(
        `/organization/${response.organization}`,
        "GET"
      );

      console.log("getShipmentById organization => ", organization);

      response.organization = organization;
    }

    if (response?.organization2) {
      const organization2 = await APIRequest(
        `/organization/${response.organization2}`,
        "GET"
      );

      console.log("getShipmentById organization2 => ", organization2);

      response.organization2 = organization2;
    }

    if (response?.customer) {
      const organization = await APIRequest(
        `/organization/${response.customer}`,
        "GET"
      );

      console.log("getShipmentById organization => ", organization);

      response.customer = organization;
    }

    if (response?.transport) {
      const transport = await APIRequest(
        `/transport/${response.transport}`,
        "GET"
      );

      console.log("getShipmentById transport => ", transport);

      response.transport = transport;
    }

    if (response?.driver) {
      const driver = await APIRequest(`/driver/${response.driver}`, "GET");

      console.log("getShipmentById driver => ", driver);

      response.driver = driver;
    }

    dispatch({
      type: SET_CURRENT_SHIPMENT,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};
export const getShipmentForMessageById = async (shipmentId) => {
  try {
    let response = await APIRequest(`/shipment/${shipmentId}`, "GET");
    console.log("getShipmentById response =>", response);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const setShipmentIdToBeAssigned = (shipmentId) => async (dispatch) => {
  dispatch({
    type: SET_SHIPMENT_ID_TO_BE_ASSIGNED,
    payload: shipmentId,
  });
};

export const getShipmentsOrganization =
  (organizationId) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      console.log("getShipmentsOrganization organizationId =>", organizationId);
      let response = await APIRequest(
        `/shipment/getShipmentOrganizations`,
        "GET",
        {
          orgId: organizationId,
        }
      );
      console.log("getShipmentsOrganization response =>", response);

      dispatch({
        type: SET_SHIPMENTS_ORGANIZATIONS,
        payload: response,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const clearShipment = () => async (dispatch) => {
  dispatch({
    type: SET_CURRENT_SHIPMENT,
    payload: null,
  });
};
