import React, { useEffect, useState } from "react";
import { Box, Checkbox, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { RHFCheckbox } from "../../../components/hook-form/RHFCheckbox";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { Colors } from "../../../components/config";

const PermissionsModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "10px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const PermissionsModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 500,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
  padding: 16,
}));

const PermissionsModal = ({ open, setOpen, onClick, permissions }) => {
  const { currentUser } = useSelector((state) => state.ui);

  const [email, setEmail] = useState("");
  const isMobile = useResponsive("down", "600");
  const [_permissions, _setPermissions] = useState({
    chatView: false,
    chatEdit: false,
    invoicesView: false,
    invoicesEdit: false,
    shipmentView: false,
    shipmentEdit: false,
    userView: false,
    userEdit: false,
    driverView: false,
    driverEdit: false,
    organizationView: false,
    organizationEdit: false,
    profileEdit: false,
    profileView: false,
  });

  useEffect(() => {
    if (permissions && Object.keys(permissions).length > 0) {
      _setPermissions(permissions);
    }
  }, [permissions]);

  const PermissionItem = ({
    label,
    description,
    view,
    edit,

    onViewChange,
    onChange,
  }) => {
    if (label === "Chat")
      console.log("PermissionItem", {
        view,
        edit,
      });

    return (
      <Flex justifyContent="space-between" width="100%" mb={2}>
        <Flex flexDirection="column">
          <Text fontSize={18} fontWeight={600}>
            {label}
          </Text>
          <Text fontSize={12} fontWeight={400} color="rgba(0, 0, 0, 0.54)">
            Manage User Permission
          </Text>
        </Flex>

        <Flex>
          <CustomCheckbox
            name="view"
            value={view}
            setValue={(value) => {
              if (!edit) onViewChange(value);
            }}
          />
          <CustomCheckbox
            name="edit"
            value={edit}
            setValue={(value) => {
              onChange(!edit);
            }}
            hidden={label === "Invoices"}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <PermissionsModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Manage User Permissions
          </Text>
        </HeaderContainer>

        <PermissionsModalContentContainer>
          <Flex
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            mb={2}
          >
            <Box></Box>
            <Flex>
              <Flex width={39} justifyContent="center">
                <Text fontSize={14} fontWeight={500} color={Colors.greyText}>
                  View
                </Text>
              </Flex>
              <Flex width={39} justifyContent="center">
                <Text fontSize={14} fontWeight={500} color={Colors.greyText}>
                  Edit
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <PermissionItem
            label="Chat"
            view={_permissions?.chatView}
            edit={_permissions?.chatEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                chatView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                chatEdit: value,
                chatView: true,
              })
            }
          />
          <PermissionItem
            label="Users"
            view={_permissions?.userView}
            edit={_permissions?.userEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                userView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                userEdit: value,
                userView: true,
              })
            }
          />
          <PermissionItem
            label="Drivers"
            view={_permissions?.driverView}
            edit={_permissions?.driverEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                driverView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                driverEdit: value,
                driverView: true,
              })
            }
          />
          <PermissionItem
            label="Organization"
            view={_permissions?.organizationView}
            edit={_permissions?.organizationEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                organizationView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                organizationEdit: value,
                organizationView: true,
              })
            }
          />

          <PermissionItem
            label="Shipment"
            view={_permissions?.shipmentView}
            edit={_permissions?.shipmentEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                shipmentView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                shipmentEdit: value,
                shipmentView: true,
              })
            }
          />
          <PermissionItem
            label="Invoices"
            view={_permissions?.invoicesView}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                invoicesView: value,
              });
            }}
          />
          <PermissionItem
            label="Profile"
            view={_permissions?.profileView}
            edit={_permissions?.profileEdit}
            onViewChange={(value) => {
              _setPermissions({
                ..._permissions,
                profileView: value,
              });
            }}
            onChange={(value) =>
              _setPermissions({
                ..._permissions,
                profileEdit: value,
                profileView: true,
              })
            }
          />
        </PermissionsModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton
            onClick={() => {
              //  onClick()
              console.log(_permissions);
              onClick(_permissions);
            }}
            height={40}
            width="50%"
          >
            <Text center fontSize={18} fontWeight={600}>
              Apply
            </Text>
          </CustomButton>
        </Flex>
      </PermissionsModalStyle>
    </CustomModal>
  );
};
export default PermissionsModal;
