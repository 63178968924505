// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import tableDataComplex from "../../app/shipments/variables/tableDataComplex.json";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";
import {
  columnsDataComplex,
  organizationConnectionDataComplex,
  organizationRequestDataComplex,
  organizationUserDataComplex,
} from "../../sharedComponents/home/variables/columnsData";
import { cleanShipmentData } from "../../sharedComponents/home/util";
import { useSelector } from "react-redux";
import { SHIPMENT } from "../../../redux/constants";
import AddOrganizationModal from "./AddOrganizationModal";
import { useAPIActions } from "../../../hooks/useActions";
import {
  cleanOrganizationConnectionsData,
  cleanOrganizationRequestsData,
  cleanOrganizationUsersData,
} from "../profile/columnsData";
import OrganizationDataTable from "../components/OrganizationDataTable";
import { useHistory } from "react-router-dom";
import OrganizationProfileModal from "../profile/OrganizationProfileModal";

export default function Users() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { drivers } = useSelector((state) => state.driver);
  const { organization, organizationConnections, organizationRequests, chats } =
    useSelector((state) => state.organization);

  const { createChatroomOrganizationToOrganization } = useAPIActions();

  const [showAddOrganizationModal, setShowAddOrganizationModal] =
    useState(false);

  const [showOrganizationProfileModal, setShowOrganizationProfileModal] =
    useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const { currentUser } = useSelector((state) => state.ui);
  const history = useHistory();

  const {
    makeOrganizationRequest,
    getPendingOrganizationRequests,
    getOrganizationConnections,
    setSnackbarAction,
    updateOrganizationRequest,
    deleteOrganizationConnection,
  } = useAPIActions();

  console.log("organizationConnections", organizationConnections);

  const onAddOrganization = (email) => {
    makeOrganizationRequest(email.toLowerCase(), organization._id, () => {
      setShowAddOrganizationModal(false);
      setSnackbarAction({
        message: "Request has been sent to the organization",
        severity: "success",
      });
    });
  };

  const onRequestActionClick = (requestId, status, orgId) => {
    console.log(
      "onRequestActionClick",
      requestId,
      status,
      orgId,
      organization._id
    );
    updateOrganizationRequest(requestId, status, organization._id, () => {
      if (Object.values(chats).find((item) => item.organization2 === orgId)) {
        const chatItem = Object.values(chats).find(
          (item) => item.organization2 === orgId
        );

        console.log("onChatClick chatItem", chatItem);
      } else {
        createChatroomOrganizationToOrganization(
          organization._id,
          orgId,
          (chatId) => {
            setSnackbarAction({
              message: "Chat has been created",
              severity: "success",
            });
          }
        );
      }
    });
  };

  const onDeleteConnectionClick = (organizationId) => {
    deleteOrganizationConnection(organization._id, organizationId);
  };

  const onDeleteRequestClick = (requestId) => {
    console.log("onDeleteRequestClick", requestId);
  };

  const onChatClick = (organizationId) => {
    console.log("onChatClick", organizationId);

    if (
      Object.values(chats).find((item) => item.organization2 === organizationId)
    ) {
      const chatItem = Object.values(chats).find(
        (item) => item.organization2 === organizationId
      );

      console.log("onChatClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id },
      });
    } else {
      createChatroomOrganizationToOrganization(
        organization._id,
        organizationId,
        (chatId) => {
          setTimeout(() => {
            history.push({
              pathname: "/organization/chat",
              state: { chatId },
            });
          }, 1000);
        }
      );
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Organization Connections"
          value={organizationConnections.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Organization Requests"
          value={organizationRequests.length}
        />
      </SimpleGrid>

      {(currentUser.role === "admin" ||
        currentUser.permissions?.organizationEdit) && (
        <SimpleGrid
          columns={{ base: 2, sm: 1, md: 2, lg: 4, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Button
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            onClick={() => setShowAddOrganizationModal(true)}
          >
            Connect with Organization
          </Button>
        </SimpleGrid>
      )}

      {organizationRequests.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <OrganizationDataTable
            dataType="Pending Requests"
            columnsData={organizationRequestDataComplex}
            tableData={cleanOrganizationRequestsData(organizationRequests)}
            organizationId={organization._id}
            onRequestActionClick={onRequestActionClick}
            onDelete={onDeleteRequestClick}
          />
        </SimpleGrid>
      )}

      {organizationConnections.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <OrganizationDataTable
            dataType="Connections"
            columnsData={organizationConnectionDataComplex}
            tableData={cleanOrganizationConnectionsData(
              organizationConnections
            )}
            organizationId={organization._id}
            onDelete={onDeleteConnectionClick}
            onChat={onChatClick}
            onProfileClick={(org) => {
              setSelectedOrganization(org);
              setShowOrganizationProfileModal(true);
            }}
            organizationEdit={
              currentUser.role === "admin" ||
              currentUser.permissions?.organizationEdit
            }
          />
        </SimpleGrid>
      )}

      <AddOrganizationModal
        open={showAddOrganizationModal}
        setOpen={setShowAddOrganizationModal}
        onClick={onAddOrganization}
      />

      <OrganizationProfileModal
        open={showOrganizationProfileModal}
        setOpen={setShowOrganizationProfileModal}
        organization={selectedOrganization}
        onClose={() => setShowOrganizationProfileModal(false)}
      />
    </Box>
  );
}
