import {
  SET_ORGANIZATION,
  SET_ORGANIZATION_USERS,
  SET_ALL_ORGANIZATIONS,
  SET_ORGANIZATION_USER,
  SET_ORGANIZATION_CHATS,
  SET_ORGANIZATION_CHATS_MESSAGES,
  SET_ORGANIZATION_DRIVERS,
  SET_ORGANIZATION_REQUESTS,
  SET_ORGANIZATION_CONNECTIONS,
  RESET_ORGANIZATION_DATA,
  SET_GROUP_CHATS,
  SET_GROUP_CHATS_MESSAGES,
} from "../actions/organizationActions";

const initialState = {
  organization: null,
  organizations: [],
  organizationUsers: [],
  organizationDrivers: [],
  organizationConnections: [],
  organizationRequests: [],
  organizationUser: null,
  chats: {},
  chatsMessages: {},
  groupChatsMessages: {},
  groupChats: {},
};

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_ORGANIZATIONS:
      return { ...state, organizations: action.payload };
    case SET_ORGANIZATION_USERS:
      return { ...state, organizationUsers: action.payload };

    case SET_ORGANIZATION_DRIVERS:
      return { ...state, organizationDrivers: action.payload };

    case SET_ORGANIZATION_USER:
      return { ...state, organizationUser: action.payload };
    case SET_ORGANIZATION:
      return { ...state, organization: action.payload };

    case SET_ORGANIZATION_CHATS:
      return { ...state, chats: action.payload };

    case SET_ORGANIZATION_CHATS_MESSAGES:
      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [action.payload.chatroomId]: action.payload.messages,
        },
      };

    case SET_GROUP_CHATS_MESSAGES:
      return {
        ...state,
        groupChatsMessages: {
          ...state.groupChatsMessages,
          [action.payload.chatroomId]: action.payload.messages,
        },
      };

    case SET_ORGANIZATION_CONNECTIONS:
      return { ...state, organizationConnections: action.payload };

    case SET_ORGANIZATION_REQUESTS:
      return { ...state, organizationRequests: action.payload };

    case SET_GROUP_CHATS:
      return { ...state, groupChats: action.payload };

    case RESET_ORGANIZATION_DATA:
      return initialState;

    default:
      return state;
  }
};
