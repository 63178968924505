import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import AuthLayout from "./layouts/auth";
import PrivacyLayout from "./layouts/privacy";
import SupportLayout from "./layouts/support";
import AppLayout from "./layouts/app";
import CustomerLayout from "./layouts/customer";
import DriverLayout from "./layouts/driver";
import OrganizationLayout from "./layouts/organization";
import TransportLayout from "./layouts/transport";

import { useSelector } from "react-redux";
import SnackbarAlert from "./components/SnackbarAlert";
import { useAPIActions } from "./hooks/useActions";
import ThemeProvider from "./muiTheme";
import { handleScrolling } from "./utils";
import PrivateRoute from "./PrivateRoute";
import SingleShipment from "./views/shipment/SingleShipment";
import CustomRedirect from "./CustomRedirect";

const hist = createBrowserHistory();

const App = () => {
  const { loading } = useSelector((state) => state.ui);

  const { setSnackbarAction } = useAPIActions();

  useEffect(() => {
    handleScrolling(loading);
  }, [loading]);

  // useEffect(() => {
  //   setSnackbarAction({
  //     message:
  //       "We weren't able to validate your details, please sign-up with correct information.",
  //     type: "error",
  //   });
  // }, []);

  return (
    <>
      {/* <ThemeProvider>
        <SnackbarAlert />
      </ThemeProvider> */}
      <LoadingOverlay active={loading} spinner={true}>
        <BrowserRouter history={hist}>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/privacy`} component={PrivacyLayout} />
            <Route path={`/support`} component={SupportLayout} />
            <Route path={`/app`} component={AppLayout} />
            {/* <Route path="/shipment/:id" component={SingleShipment} /> */}

            <PrivateRoute path={`/customer`}>
              <CustomerLayout />
            </PrivateRoute>
            <PrivateRoute path={`/driver`}>
              <DriverLayout />
            </PrivateRoute>
            <PrivateRoute path={`/organization`}>
              <OrganizationLayout />
            </PrivateRoute>
            <PrivateRoute path={`/transport`}>
              <TransportLayout />
            </PrivateRoute>

            <CustomRedirect from="/" to="/customer" />
          </Switch>
        </BrowserRouter>
      </LoadingOverlay>
    </>
  );
};

export default App;
