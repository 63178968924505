import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { Project } from "../../sharedComponents/profile";

const OrganizationProfileModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 900,
  padding: "20px",

  minWidth: !isMobile && 500,

  width: isMobile ? "95vw" : "50vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const OrganizationProfileModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 900,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const OrganizationProfileModal = ({ open, setOpen, onClose, organization }) => {
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const { currentUser } = useSelector((state) => state.ui);

  const isMobile = useResponsive("down", "600");

  // console.log("organization in modal", organization);

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <OrganizationProfileModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Organization Information
          </Text>
        </HeaderContainer>

        <OrganizationProfileModalContentContainer>
          <Flex direction="column" width="100%">
            <Project
              boxShadow={cardShadow}
              mb="20px"
              ranking={organization?.name || organization?.companyName}
              rankingFs="18px"
              title="Name"
              avatar={organization?.logo || organization?.profilePicture}
            />
            {organization?.address && (
              <Project
                boxShadow={cardShadow}
                mb="20px"
                ranking={organization?.address}
                rankingFs="18px"
                title="Address"
              />
            )}

            {organization?.rateList && (
              <Project
                boxShadow={cardShadow}
                mb="20px"
                ranking={organization?.rateList}
                rankingFs="18px"
                title="Rate List"
              />
            )}

            {organization?.hourlyRate && (
              <Project
                boxShadow={cardShadow}
                mb="20px"
                ranking={`${organization?.hourlyRate} €/hr`}
                rankingFs="18px"
                title="Hourly Waiting Rate"
              />
            )}
          </Flex>
        </OrganizationProfileModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton onClick={() => onClose()} height={40} width={200}>
            <Text center fontSize={18} fontWeight={600}>
              Close
            </Text>
          </CustomButton>
        </Flex>
      </OrganizationProfileModalStyle>
    </CustomModal>
  );
};
export default OrganizationProfileModal;
