// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useState } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import tableDataComplex from "../../app/shipments/variables/tableDataComplex.json";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";
import {
  columnsDataComplex,
  organizationDriverDataComplex,
  organizationUserDataComplex,
} from "../../sharedComponents/home/variables/columnsData";
import { cleanShipmentData } from "../../sharedComponents/home/util";
import { useSelector } from "react-redux";
import { SHIPMENT } from "../../../redux/constants";
import AddDriverModal from "./AddDriverModal";
import { useAPIActions } from "../../../hooks/useActions";
import { cleanOrganizationUsersData } from "../profile/columnsData";
import PermissionsModal from "../users/PermissionsModal";

export default function Users() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { drivers } = useSelector((state) => state.driver);
  const { organization, organizationDrivers } = useSelector(
    (state) => state.organization
  );

  const [showAddDriverModal, setShowAddDriverModal] = useState(false);

  const { getLinkForAddingDriverToOrganization, setSnackbarAction } =
    useAPIActions();

  const { currentUser } = useSelector((state) => state.ui);

  const onAddDriver = (email) => {
    getLinkForAddingDriverToOrganization(email, organization._id, () => {
      setShowAddDriverModal(false);
      setSnackbarAction({
        message: "Email has been sent to the user",
        severity: "success",
      });
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Drivers"
          value={organizationDrivers.length}
        />
      </SimpleGrid>

      {(currentUser.role === "admin" ||
        currentUser.permissions?.driverEdit) && (
        <SimpleGrid
          columns={{ base: 2, md: 4, lg: 6, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Button
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            onClick={() => setShowAddDriverModal(true)}
          >
            Add Driver
          </Button>
        </SimpleGrid>
      )}

      {organizationDrivers.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="All Drivers"
            columnsData={organizationDriverDataComplex}
            tableData={cleanOrganizationUsersData(organizationDrivers)}
            driverEdit={
              currentUser.role === "admin" ||
              currentUser.permissions?.driverEdit
            }
          />
        </SimpleGrid>
      )}

      <AddDriverModal
        open={showAddDriverModal}
        setOpen={setShowAddDriverModal}
        onClick={onAddDriver}
      />
    </Box>
  );
}
