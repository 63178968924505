// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import React, { useEffect, useState } from "react";

import ChatList from "./ChatList";
import { useAPIActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { transformOrganizationChats } from "../../../utils";
import { styled, Box as MuiBox } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import ChatView from "../../sharedComponents/chat/ChatView";
import { useLocation } from "react-router-dom";
import GroupChat from "./GroupChat";
import { stopListeningToOrganizationSocketEvents } from "../../../redux/actionCreators/organizationActionCreators";
import UsersModal from "./UsersModal";

const MobileChatStyle = styled(MuiBox)(({ theme, currentChat }) => ({
  position: "absolute",
  top: !currentChat ? 130 : 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: currentChat && 1000,
  backgroundColor: "#fff",
}));

export default function Chat() {
  const onChatHistoryOpen = () => {};
  const onChatFilesOpen = () => {};

  const [currentChat, setCurrentChat] = useState(null);
  const isDesktop = useResponsive("up", "900");

  const socket = useSelector((state) => state.ui.socket);

  const location = useLocation();

  const { chatsMessages, groupChatsMessages, organization, chats, groupChats } =
    useSelector((state) => state.organization);

  const { currentUser } = useSelector((state) => state.ui);

  const { shipmentIdToBeAssigned } = useSelector((state) => state.shipment);

  const [firstTimeChatPageLoaded, setFirstTimeChatPageLoaded] = useState(false);

  const [usersModal, setUsersModal] = useState(false);
  console.log("chats => ", chats);

  // console.log("location", { location, chatsMessages });

  // console.log("currentChat => ", { currentChat, chats });
  const {
    listenToOrganizationSocketEvents,
    getOrganizationChatroomMessages,
    getGroupChatroomMessages,
    sendOrganizationChatMessage,
    setShipmentIdToBeAssigned,
    emitJoinRoomEvent,
    sendGroupChatMessage,
    listenToGroupChatMessages,
    addUserToOrganizationChat,
    setSnackbarAction,
  } = useAPIActions();

  useEffect(() => {
    console.log("chat socket", socket);
    if (socket) {
      listenToOrganizationSocketEvents();
      listenToGroupChatMessages(currentUser);
    }

    return () => {
      if (socket) {
        stopListeningToOrganizationSocketEvents();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (currentChat) {
      let _chat = transformOrganizationChats(
        [chats[currentChat.chatroomId]],
        currentUser
      )[0];

      console.log("currentChat update", _chat);

      setCurrentChat(_chat);
    }
    if (firstTimeChatPageLoaded) return;
    if (
      Object.keys(chats).length > 0 &&
      location.state &&
      location.state.chatId
    ) {
      setFirstTimeChatPageLoaded(true);
      const selectedChat = transformOrganizationChats([
        chats[location.state.chatId],
      ])[0];

      // console.log("location.state", location.state);

      getOrganizationChatroomMessages(selectedChat.chatroomId, () => {
        //    console.log("messages fetched");
        setCurrentChat(selectedChat);

        if (location.state && shipmentIdToBeAssigned) {
          sendOrganizationChatMessage(
            "",
            organization._id,
            selectedChat.receiverId,
            selectedChat.chatroomId,
            "shipment",
            null,
            shipmentIdToBeAssigned
          );

          setShipmentIdToBeAssigned(null);
        }
      });
    } else if (Object.keys(chats).length > 0) {
      setFirstTimeChatPageLoaded(true);
      console.log("init chats", chats);

      const firstChat = transformOrganizationChats([
        chats[Object.keys(chats)[0]],
      ])[0];
      console.log("first chat", firstChat);
      if (!firstChat) return;
      getOrganizationChatroomMessages(firstChat.chatroomId, () => {
        //console.log("messages fetched");
        setCurrentChat(firstChat);
      });
    }
  }, [chats, location]);

  const onSingleChatClick = (chat) => {
    console.log("onSingleChatClick chat", { chat, currentChat });

    if (!currentChat || currentChat?.chatroomId !== chat.chatroomId) {
      if (chat.type === "group") {
        emitJoinRoomEvent(chat.chatroomId, currentUser.userId, "group");
        getGroupChatroomMessages(chat.chatroomId, () => {
          console.log("messages fetched");
          setCurrentChat(chat);
        });
      } else {
        getOrganizationChatroomMessages(chat.chatroomId, () => {
          console.log("messages fetched");
          setCurrentChat(chat);
        });
      }
    }
  };

  console.log("currentUser > ", currentUser);
  const onSendChatMessage = (message) => {
    if (currentChat.type === "group") {
      console.log("onSendChatMessage", { message, currentChat });
      sendGroupChatMessage(
        message.chatInput,
        currentChat.sender[0]._id,
        currentChat.receivers.map((receiver) => receiver._id),
        currentChat.chatroomId,
        message.type,
        message.type === "image" ? message.url : null
      );
    } else if (message.type === "image") {
      sendOrganizationChatMessage(
        message.chatInput,
        organization._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "image",
        message.url
      );
    } else if (message.type === "shipment") {
      sendOrganizationChatMessage(
        message.chatInput,
        organization._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "shipment",
        null,
        message.shipmentId
      );
    } else {
      sendOrganizationChatMessage(
        message.chatInput,
        currentUser.userId,
        currentChat.receiverId,
        currentChat.chatroomId
      );
    }
  };

  const onAddUserToChat = (userId) => {
    addUserToOrganizationChat(
      currentChat.chatroomId,
      userId,
      currentUser.id,
      (err) => {
        if (err) {
          setUsersModal(false);
          return;
        }
        setSnackbarAction({
          open: true,
          message: "User Added to Chat",
          severity: "success",
        });
        setUsersModal(false);
      }
    );
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="100%">
        {isDesktop ? (
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={4}
            height="100%"
            width="100%"
          >
            <GridItem colSpan={currentChat ? 3 : 12}>
              <GroupChat />
              <ChatList
                isDesktop={isDesktop}
                chatsList={
                  [...Object.keys(chats), ...Object.keys(groupChats)].length > 0
                    ? transformOrganizationChats(
                        [...Object.values(chats), ...Object.values(groupChats)],
                        currentUser
                      )
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            </GridItem>

            {currentChat && (
              <GridItem colSpan={9}>
                <ChatView
                  other={currentChat}
                  self={currentUser}
                  onSendChatMessage={onSendChatMessage}
                  onChatHistoryOpen={onChatHistoryOpen}
                  onChatFilesOpen={onChatFilesOpen}
                  currentChat={currentChat}
                  messages={
                    currentChat.type === "group"
                      ? Object.keys(groupChatsMessages).length > 0
                        ? groupChatsMessages[currentChat.chatroomId]
                        : []
                      : Object.keys(chatsMessages).length > 0
                      ? chatsMessages[currentChat.chatroomId]
                      : []
                  }
                  isAdmin={currentUser?.role === "admin"}
                  onAddUserToChatClick={() => {
                    setUsersModal(true);
                  }}
                />
              </GridItem>
            )}
          </Grid>
        ) : (
          <MobileChatStyle currentChat={currentChat}>
            {currentChat ? (
              <ChatView
                other={currentChat}
                self={organization}
                onSendChatMessage={onSendChatMessage}
                mobile={true}
                onChatHistoryOpen={onChatHistoryOpen}
                onChatFilesOpen={onChatFilesOpen}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                messages={
                  currentChat.type === "group"
                    ? Object.keys(groupChatsMessages).length > 0
                      ? groupChatsMessages[currentChat.chatroomId]
                      : []
                    : Object.keys(chatsMessages).length > 0
                    ? chatsMessages[currentChat.chatroomId]
                    : []
                }
                isAdmin={currentUser?.role === "admin"}
                onAddUserToChatClick={() => {
                  setUsersModal(true);
                }}
              />
            ) : (
              <ChatList
                chatsList={
                  [...Object.keys(chats), ...Object.keys(groupChats)].length > 0
                    ? transformOrganizationChats(
                        [...Object.values(chats), ...Object.values(groupChats)],
                        currentUser
                      )
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            )}
          </MobileChatStyle>
        )}
      </Box>

      <UsersModal
        open={usersModal}
        setOpen={setUsersModal}
        onClick={onAddUserToChat}
        currentChat={currentChat}
      />
    </>
  );
}
