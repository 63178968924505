import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import { useAPIActions } from "../../hooks/useActions";
import { SHIPMENT } from "../../redux/constants";
import DriverPodModal from "./DriverPodUploadModal";
import WaitingHourModal from "./WaitingHourModal";
const DriverShipment = ({ shipment, self, other, isPod }) => {
  console.log("DriverShipment", { shipment, self, other, isPod });

  const {
    updateShipment,
    createInvoiceForOrganization2,
    createInvoiceForOrg1FromOrg2,
    sendShipmentStatusEmail,
    createInvoiceForCustomer,
    createInvoiceForOrganization,
    setSnackbarAction,
  } = useAPIActions();

  const [showDriverPodModal, setShowDriverPodModal] = useState(isPod);
  const [showWaitingHourModal, setShowWaitingHourModal] = useState(false);

  const { currentUser } = useSelector((state) => state.ui);
  //console.log("currentUser", currentUser);

  const onDeliverShipment = (waitingHours) => {
    console.log("assign shipment");
    setShowWaitingHourModal(false);

    updateShipment(
      shipment._id,
      {
        waitingHours: waitingHours ? waitingHours : 0,
      },
      currentUser,
      async (shipmentResponse) => {
        console.log("shipmentResponse", shipmentResponse);
        updateShipment(
          shipment._id,
          {
            status: SHIPMENT.DELIVERED,
          },
          currentUser,
          async (shipmentResponse) => {
            sendShipmentStatusEmail(
              {
                shipmentNumber: shipmentResponse.shipmentNumber,
                status: "Delivered",
                to: self.email,
                customer: shipmentResponse.customer,
                organization2: shipmentResponse.organization2,
                organization: shipmentResponse.organization,
                notificationData: {
                  customer: shipmentResponse.customer,
                  organization2: shipmentResponse?.organization2,
                  organization: shipmentResponse.organization,
                  heading: "Shipment Delivered",
                  message: `Shipment#${shipment.shipmentNumber} has been delivered`,
                },
              },
              true
            );

            setSnackbarAction({
              open: true,
              message: "Shipment Delivered",
              severity: "success",
            });

            console.log("sending invoices ", { shipmentResponse });

            // invoice part commented out for now

            // if (shipmentResponse.organization2) {
            //   Promise.all([
            //     await createInvoiceForCustomer(shipmentResponse),
            //     await createInvoiceForOrg1FromOrg2(shipmentResponse),
            //     await createInvoiceForOrganization2(self, shipmentResponse),
            //   ]);
            // } else {
            //   Promise.all([
            //     await createInvoiceForCustomer(shipmentResponse),
            //     await createInvoiceForOrganization(shipmentResponse),
            //   ]);
            // }
          }
        );
      }
    );
  };

  const onPickupShipment = () => {
    console.log("onPickupShipment");

    try {
      updateShipment(
        shipment._id,
        {
          status: SHIPMENT.PICKED,
        },
        currentUser,
        () => {
          sendShipmentStatusEmail(
            {
              shipmentNumber: shipment.shipmentNumber,
              status: "Picked",
              to: self.email,
              customer: shipment.customer._id,
              organization2: shipment.organization2?._id,
              organization: shipment.organization._id,
              notificationData: {
                customer: shipment.customer._id,
                organization2: shipment.organization2?._id,
                organization: shipment.organization._id,
                heading: "Shipment Picked",
                message: `Shipment#${shipment.shipmentNumber} has been picked by driver`,
              },
            },
            true
          );

          setSnackbarAction({
            open: true,
            message: "Shipment Picked Up",
            severity: "success",
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onInTransitShipment = () => {
    console.log("onInTransitShipment");

    updateShipment(
      shipment._id,
      {
        status: SHIPMENT.TRANSIT,
      },
      currentUser,
      () => {
        sendShipmentStatusEmail(
          {
            shipmentNumber: shipment.shipmentNumber,
            status: "InTransit",
            to: self.email,
            customer: shipment.customer._id,
            organization2: shipment.organization2?._id,
            organization: shipment.organization._id,
            notificationData: {
              customer: shipment.customer._id,
              organization2: shipment.organization2?._id,
              organization: shipment.organization._id,
              heading: "Shipment In Transit",
              message: `Shipment#${shipment.shipmentNumber} is in transit`,
            },
          },
          true
        );

        setSnackbarAction({
          open: true,
          message: "Shipment In Transit",
          severity: "success",
        });
      }
    );
  };

  const onUploadPOD = () => {
    setShowDriverPodModal(true);
  };

  return (
    <Flex justifyContent="center" flex={1}>
      {shipment.status === SHIPMENT.DRIVER_ASSIGNED && (
        <Flex>
          <CustomButton large onClick={onPickupShipment}>
            <Text fontSize={18} fontWeight={600}>
              Change Status to picked up
            </Text>
          </CustomButton>
        </Flex>
      )}
      {shipment.status === SHIPMENT.PICKED && (
        <Flex>
          <CustomButton large onClick={onInTransitShipment}>
            <Text fontSize={18} fontWeight={600}>
              Change Status to in transit
            </Text>
          </CustomButton>
        </Flex>
      )}

      {shipment.status === SHIPMENT.TRANSIT && (
        <Flex>
          <CustomButton
            large
            onClick={() => {
              setShowWaitingHourModal(true);
            }}
          >
            <Text fontSize={18} fontWeight={600}>
              Change Status to delivered
            </Text>
          </CustomButton>
        </Flex>
      )}

      {shipment.status === SHIPMENT.DELIVERED && (
        <Flex>
          <CustomButton large onClick={onUploadPOD}>
            <Text fontSize={18} fontWeight={600}>
              Upload POD
            </Text>
          </CustomButton>
        </Flex>
      )}

      <DriverPodModal
        shipment={shipment}
        open={showDriverPodModal}
        setOpen={setShowDriverPodModal}
        self={self}
      />
      <WaitingHourModal
        open={showWaitingHourModal}
        setOpen={setShowWaitingHourModal}
        onClick={onDeliverShipment}
      />
    </Flex>
  );
};

export default DriverShipment;
