import moment from "moment";
import {
  SET_ALL_INVOICES,
  SET_CURRENT_INVOICE,
} from "../actions/invoiceActions";
import { APIRequest } from "../utils/axios";
import { setLoadingAction } from "./uiActionCreators";

export const getInvoicesByUserId =
  (invoiceType, userId) => async (dispatch) => {
    console.log("getInvoicesByUserId => ", userId);
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/invoice/${invoiceType}/${userId}`,
        "GET"
      );
      console.log("getInvoicesByUserId => ", response);

      dispatch({
        type: SET_ALL_INVOICES,
        payload: response,
      });

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };
export const getInvoiceById = (invoiceId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let response = await APIRequest(`/invoice/${invoiceId}`, "GET");
    console.log("getInvoiceById response =>", response);

    if (response?.organization) {
      const organization = await APIRequest(
        `/organization/${response.organization}`,
        "GET"
      );

      console.log("getShipmentById organization => ", organization);

      response.organization = organization;
    }

    if (response?.customer) {
      const customer = await APIRequest(
        `/customer/${response.customer}`,
        "GET"
      );

      console.log("getShipmentById customer => ", customer);

      response.customer = customer;
    }

    if (response?.transport) {
      const transport = await APIRequest(
        `/transport/${response.transport}`,
        "GET"
      );

      console.log("getShipmentById transport => ", transport);

      response.transport = transport;
    }

    if (response?.driver) {
      const driver = await APIRequest(`/driver/${response.driver}`, "GET");

      console.log("getShipmentById driver => ", driver);

      response.driver = driver;
    }

    dispatch({
      type: SET_CURRENT_INVOICE,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
  }
};

export const createInvoiceForCustomer =
  (shipments, secondToFirstArray, entities, discount, callback) =>
  async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log(
        "createInvoiceForCustomer shipment => ",
        shipments,
        secondToFirstArray,
        entities
      );

      let self = await APIRequest(
        `/organization/${entities.currentUser.id}`,
        "GET"
      );
      let other = await APIRequest(
        `/organization/${entities.organization._id}`,
        "GET"
      );

      console.log("createInvoiceForCustomer self => ", self);
      console.log("createInvoiceForCustomer other => ", other);

      let invoiceData = {
        logo: self.logo,
        sender: self.companyName,
        senderAddress: self.address,
        receiver: other.companyName,
        receiverAddress: other.address,
        invoiceNumber: shipments[0].shipmentNumber,
        invoiceDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),
        invoiceDueDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),

        shipmentDescriptions: shipments.map(
          (shipment) => `${shipment.goods.join(",")}`
        ),

        shipmentPrices: shipments.map((shipment) => shipment.customerCost),

        senderId: self._id,
        receiverId: other._id,
        shipmentIds: shipments.map((shipment) => shipment._id),
        deliveredOn: shipments.map((shipment) =>
          moment(Number(shipment.deliveryTimeStamp)).format("DD-MM-YYYY")
        ),
        deliveryAddresses: shipments.map(
          (shipment) => shipment.deliveryAddress
        ),
        shipmentNumbers: shipments.map((shipment) => shipment.shipmentNumber),
        noOfWaitingHours: shipments.map((shipment) => shipment.waitingHours),
        organizationHourlyRate: shipments[0].organization.hourlyRate,
        invoiceType: "organization",
        invoiceTimestamp: moment().unix(),

        discount: discount ? discount : 0,

        email: other.users[0].email,
        fromEmail: self.users[0].email,
        secondToFirstArray: secondToFirstArray ? secondToFirstArray : [],
      };
      console.log("createInvoiceForCustomer => ", invoiceData);

      let response = await APIRequest("/invoice/", "POST", invoiceData);

      console.log("createInvoiceForCustomer => ", response);

      callback();

      //    callback(response._id);
      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const createInvoiceForOrganization = (shipment) => async () => {
  try {
    if (!shipment.organization?.id) {
      const organization = await APIRequest(
        `/organization/${shipment.organization}`,
        "GET"
      );
      shipment.organization = organization;
    }

    if (!shipment.driver?._id) {
      const driver = await APIRequest(`/driver/${shipment.driver}`, "GET");
      shipment.driver = driver;
    }

    console.log("createInvoiceForOrganization shipmentOrg => ", {
      shipment,
    });

    let invoiceData = {
      logo: shipment.driver.profilePicture,
      sender: shipment.driver.name,
      senderAddress: shipment.driver.address,
      receiver: shipment.organization.companyName,
      receiverAddress: shipment.organization.address,
      invoiceNumber: shipment.shipmentNumber,
      invoiceDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),
      invoiceDueDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),

      shipmentDescription: `Delivery Cost for shipment - ${shipment.goods.join(
        ","
      )}`,
      shipmentPrice: shipment.organizationCost,
      driverWaitingDescription: "Waiting time (hours)",
      driverWaitingHours: shipment.waitingHours,
      organizationHourlyRate: shipment.organization.hourlyRate,

      senderId: shipment.driver._id,
      receiverId: shipment.organization._id,
      shipmentId: shipment._id,
      invoiceType: "organization",
      invoiceTimestamp: moment().unix(),

      email: shipment.organization.users[0].email,
      fromEmail: shipment.driver.email,
    };

    let response = await APIRequest("/invoice/", "POST", invoiceData);
    console.log("createInvoiceForOrganization => ", response);
    //    callback(response._id);
  } catch (error) {
    console.log(error);
  }
};

export const createInvoiceForOrganization2 = (self, shipment) => async () => {
  try {
    if (!shipment.organization2?._id) {
      const organization2 = await APIRequest(
        `/organization/${shipment.organization2}`,
        "GET"
      );
      shipment.organization2 = organization2;
    }

    console.log("createInvoiceForOrganization2 shipmentOrg => ", shipment);

    let invoiceData = {
      logo: self.profilePicture,
      sender: self.name,
      senderAddress: "N/A",
      receiver: shipment.organization2.companyName,
      receiverAddress: shipment.organization2.address,
      invoiceNumber: shipment.shipmentNumber,
      invoiceDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),
      invoiceDueDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),

      shipmentDescription: `Delivery Cost for shipment - ${shipment.goods.join(
        ","
      )}`,
      shipmentPrice: shipment.transportCost,
      driverWaitingDescription: "Waiting time (hours)",
      driverWaitingHours: shipment.waitingHours,
      organizationHourlyRate: shipment.organization2.hourlyRate,

      senderId: self._id,
      receiverId: shipment.organization2._id,
      shipmentId: shipment._id,
      invoiceType: "organization",
      invoiceTimestamp: moment().unix(),

      //email: self.users[0].email,
      email: shipment.organization2.users[0].email,
      fromEmail: self.email,
    };

    let response = await APIRequest("/invoice/", "POST", invoiceData);
    console.log("createInvoiceForOrganization2 => ", response);
    //    callback(response._id);
  } catch (error) {
    console.log(error);
  }
};

export const createInvoiceForOrg1FromOrg2 = (shipment) => async () => {
  try {
    if (!shipment.organization?._id) {
      const organization = await APIRequest(
        `/organization/${shipment.organization}`,
        "GET"
      );
      shipment.organization = organization;
    }

    if (!shipment.organization2?._id) {
      const organization2 = await APIRequest(
        `/organization/${shipment.organization2}`,
        "GET"
      );
      shipment.organization2 = organization2;
    }

    console.log("createInvoiceForOrg1FromOrg2 shipmentOrg => ", shipment);

    let invoiceData = {
      logo: shipment.organization2.logo,
      sender: shipment.organization2.companyName,
      senderAddress: shipment.organization2.address,
      receiver: shipment.organization.companyName,
      receiverAddress: shipment.organization.address,
      invoiceNumber: shipment.shipmentNumber,
      invoiceDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),
      invoiceDueDate: moment.unix(moment().unix(0)).format("DD-MM-YYYY"),

      shipmentDescription: `Organization Cost for shipment - ${shipment.goods.join(
        ","
      )}`,
      shipmentPrice: shipment.organizationCost,

      senderId: shipment.organization2._id,
      receiverId: shipment.organization._id,
      shipmentId: shipment._id,
      invoiceType: "organization",
      invoiceTimestamp: moment().unix(),

      //email: self.users[0].email,
      email: shipment.organization.users[0].email,
      fromEmail: shipment.organization2.users[0].email,
    };

    let response = await APIRequest("/invoice/", "POST", invoiceData);
    console.log("createInvoiceForOrganization2 => ", response);
    //    callback(response._id);
  } catch (error) {
    console.log(error);
  }
};
