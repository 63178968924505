import React, { useEffect, useState } from "react";
import { NavLink, useHistory, Link as RouterLink } from "react-router-dom";
// Chakra imports
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "../../../../layouts/auth/Default";
// Assets
import illustration from "../../../../assets/img/logo.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import { useAPIActions } from "../../../../hooks/useActions";
import SignUpForm from "./SignUpForm";

import { Box, Link } from "@mui/material";
import Text from "../../../../components/Text";
import { Colors } from "../../../../components/config";
import { appRoutes } from "../../../../assets/constants";
function TransportSignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Transport Sign Up
          </Heading>
          <Text fontSize={16} color={Colors.lightgrey2}>
            Enter the details to sign up
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          // justifyContent="center"
          // alignItems="center"
        >
          <Box mb={2}>
            <SignUpForm />
          </Box>
          <Flex
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text mr={1}>
              Already registered?{"  "}
              <Link
                variant="subtitle2"
                color="text.theme"
                component={RouterLink}
                to={appRoutes.transportSignIn}
              >
                {"   "}
                Login here
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default TransportSignUp;
