import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { vehicleOptions } from "../../auth/signup/driver/vehicleOptions";
import Iconify from "../../../components/Iconify";
import ThemeProvider from "../../../muiTheme";
import CustomCheckbox from "../../../components/CustomCheckbox";
import moment from "moment";
import { themeColor } from "../../../components/config/colors";
import { Colors } from "../../../components/config";

const CreateNewInvoiceModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 600,
  padding: "10px",

  minWidth: !isMobile && 600,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const CreateNewInvoiceModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  //   alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const CreateNewInvoiceModal = ({ open, setOpen, onClick }) => {
  const { currentUser } = useSelector((state) => state.ui);

  const isMobile = useResponsive("down", "600");

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [discount, setDiscount] = useState(0);

  const { shipmentsOrganizations } = useSelector((state) => state.shipment);

  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);

  const {
    createInvoiceForCustomer,
    setSnackbarAction,
    getShipmentsOrganization,
  } = useAPIActions();

  useEffect(() => {
    if (selectedOrganization) {
      setCheckboxes(Array(selectedOrganization.shipments.length).fill(false));
    }
  }, [selectedOrganization]);

  const RenderOrganizations = () => {
    return (
      <Box mb={4}>
        {selectedOrganization ? (
          <Flex flexDirection={"column"}>
            <Text mb={1} fontSize={16} fontWeight={500}>
              Organization:
            </Text>
            <Flex alignItems={"center"}>
              <Flex flex={1}>
                <img
                  src={selectedOrganization.logo}
                  style={{
                    height: 30,
                    marginRight: 10,
                  }}
                />
                <Flex direction="column">
                  <Text fontSize={16}>{selectedOrganization.companyName}</Text>
                  {/* <Text fontSize={14}>
                 
                  address
                </Text> */}
                </Flex>
              </Flex>
              <IconButton onClick={() => setSelectedOrganization(null)}>
                <Iconify icon="charm:cross" />
              </IconButton>
            </Flex>
          </Flex>
        ) : (
          <ThemeProvider>
            <Autocomplete
              sx={{ flex: 1 }}
              options={shipmentsOrganizations}
              id="controlled-demo"
              getOptionLabel={(option) => option?.companyName}
              renderOption={renderSelectedOrganization}
              isOptionEqualToValue={(option, value) =>
                option.companyName === value.companyName
              }
              noOptionsText={"No Organizations"}
              value={selectedOrganization}
              onChange={(event, newValue) => {
                setSelectedOrganization(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Organization"
                  variant="outlined"
                  color="theme"
                />
              )}
            />
          </ThemeProvider>
        )}
      </Box>
    );
  };

  const renderSelectedOrganization = (props, option) => (
    <Box {...props} mb={1}>
      <img
        src={option.logo}
        style={{
          height: 30,

          marginRight: 10,
          borderRadius: 100,
        }}
      />

      <Text fontSize={16}>{option.companyName}</Text>
    </Box>
  );

  const RenderShipments = () => {
    console.log({ checkboxes, selectedShipments });

    return (
      <Flex flexDirection={"column"} mb={2}>
        <Text mb={1} fontSize={16} fontWeight={500}>
          Shipments: {selectedOrganization.shipments.length}
        </Text>

        <Flex direction="column">
          {selectedOrganization.shipments.map((shipment, index) => (
            <Flex
              mb={2}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 10,
              }}
            >
              <Flex flexDirection={"column"} width={"100%"}>
                <Flex flexDirection={"column"} mb={2}>
                  <Text fontSize={16}>Shipment #{shipment.shipmentNumber}</Text>
                  <Box
                    sx={{
                      backgroundColor: Colors.lightgrey2,
                      width: "15%",
                      height: "3px",
                    }}
                  ></Box>
                </Flex>
                <Text
                  fontSize={14}
                  fontWeight={500}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 350,
                  }}
                >
                  Pickup from : {shipment.pickupAddress}
                </Text>
                <Text
                  fontSize={14}
                  fontWeight={500}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 350,
                  }}
                >
                  Deliver to : {shipment.deliveryAddress}
                </Text>
                <Text fontSize={14} fontWeight={500}>
                  Delivered :{" "}
                  {moment(shipment.deliveryTimeStamp / 1000).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </Text>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  fontWeight={500}
                >
                  <Text fontSize={14} fontWeight={500}>
                    Waiting Hours : {shipment.waitingHours}
                  </Text>
                  <Text fontSize={16} color={themeColor} fontWeight={500}>
                    {" "}
                    {currentUser.id === shipment.organization._id
                      ? shipment.customerCost
                      : currentUser.id === shipment?.organization2?._id
                      ? shipment?.organizationCost
                      : "0"}{" "}
                    {" €"}
                  </Text>
                </Flex>
              </Flex>
              <CustomCheckbox
                name="edit"
                value={checkboxes[index]}
                setValue={(value) => {
                  //   onChange(!edit);
                  const newCheckboxes = [...checkboxes];
                  newCheckboxes[index] = value;
                  setCheckboxes(newCheckboxes);

                  // insert  shipment into array
                  if (value) {
                    setSelectedShipments([...selectedShipments, shipment]);
                  } else {
                    // remove shipment
                    const newSelectedShipments = selectedShipments.filter(
                      (selectedShipment) =>
                        selectedShipment.shipmentNumber !==
                        shipment.shipmentNumber
                    );
                    setSelectedShipments(newSelectedShipments);
                  }
                }}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  };

  const onCreate = () => {
    // apply discount on shipments

    if (discount) {
      const newShipments = selectedShipments.map((shipment, index) => {
        if (currentUser.id === shipment.organization._id) {
          //apply discount on customerCost
          return {
            ...shipment,
          };
        } else if (currentUser.id === shipment?.organization2?._id) {
          //apply discount on organizationCost
          return {
            ...shipment,
          };
        }
      });

      console.log({ newShipments, selectedOrganization });

      let secondToFirstArray = [];

      newShipments.map((shipment) => {
        if (
          currentUser.id === shipment.organization._id &&
          selectedOrganization._id === shipment.customer._id
        ) {
          secondToFirstArray.push(true);
        } else {
          secondToFirstArray.push(false);
        }
      });

      createInvoiceForCustomer(
        newShipments,

        secondToFirstArray,

        {
          currentUser,
          organization: selectedOrganization,
        },
        discount,
        () => {
          setSnackbarAction({
            message: `Invoice Created`,
            severity: "success",
          });

          setOpen(false);
          getShipmentsOrganization(currentUser.id);
        }
      );
    }
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <CreateNewInvoiceModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Create New Invoice
          </Text>
        </HeaderContainer>

        <CreateNewInvoiceModalContentContainer>
          <RenderOrganizations />
          {selectedOrganization && <RenderShipments />}
          {selectedOrganization &&
            // and any checkbox is checked true
            checkboxes.some((checkbox) => checkbox) && (
              <Flex mb={2} direction={"column"}>
                <Text
                  fontSize={16}
                  mb={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Discount{" "}
                  <Text fontSize={14} fontWeight={500} ml={1} color={"#ccc"}>
                    (Optional)
                  </Text>
                </Text>
                <CustomInput
                  value={discount}
                  placeholder={"Discount in Euros"}
                  setValue={(value) => setDiscount(value)}
                  width={100}
                  type="number"
                />
              </Flex>
            )}
        </CreateNewInvoiceModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton
            onClick={onCreate}
            height={40}
            width="50%"
            disabled={!checkboxes.some((checkbox) => checkbox)}
          >
            <Text center fontSize={16} fontWeight={600}>
              Create
            </Text>
          </CustomButton>
        </Flex>
      </CreateNewInvoiceModalStyle>
    </CustomModal>
  );
};
export default CreateNewInvoiceModal;
