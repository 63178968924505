// Chakra imports
import { Portal, Box, useDisclosure, Button } from "@chakra-ui/react";
import Footer from "../../components/footer/FooterAdmin.js";
// Layout components
import Navbar from "../../components/navbar/NavbarAdmin.js";
import Sidebar from "../../components/sidebar/Sidebar.js";
import { SidebarContext } from "../../contexts/SidebarContext";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useAPIActions } from "../../hooks/useActions.js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // by just using below line like this, it fixes the route name not changing issue in the header
  const location = useLocation();

  const {
    getDriverChatRooms,
    getCurrentDriver,
    getShipmentsByUserId,
    getInvoicesByUserId,
    getAllGroupChats,
  } = useAPIActions();

  const { driver } = useSelector((state) => state.driver);
  const history = useHistory();

  console.log("driver", driver);

  useEffect(() => {
    getCurrentDriver(() => {
      localStorage.removeItem("tisnix_user_signed_in");
      localStorage.removeItem("tisnix_user_access_token");
      localStorage.removeItem("tisnix_user");
      history.push("/auth/driver/sign-in");
    });
  }, []);

  useEffect(() => {
    if (driver) {
      getDriverChatRooms(driver._id);
      getShipmentsByUserId("driver", driver._id);
      getInvoicesByUserId("driver", driver._id);
      getAllGroupChats(driver?._id, undefined, undefined);
    }
  }, [driver]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Home";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/driver") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box height={"100%"}>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          height="100%"
          overflow="auto"
          position="relative"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                state={{ user: driver }}
                logoText={"TixNix"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            //height="100%"
            pt="50px"
            width="100%"
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/driver" to="/driver/home" />
            </Switch>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
