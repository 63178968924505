import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
// Chakra imports
import { Flex, Heading, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
import DefaultAuth from "../../../../layouts/auth/Default";
// Assets
import illustration from "../../../../assets/img/logo.png";

import SignInOptions from "../SignInOptions";
import { Box, Link } from "@mui/material";
import Text from "../../../../components/Text";
import { Colors } from "../../../../components/config";
import SignInForm from "./SignInForm";
import { appRoutes } from "../../../../assets/constants";

function CustomerSignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Customer Sign In
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={16}>
            Enter your email and password to sign in
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box mb={2}>
            <SignInForm />
          </Box>

          <Flex
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text>
              Not registered yet?
              <Link
                variant="subtitle2"
                color="text.theme"
                component={RouterLink}
                to={appRoutes.customerSignUp}
              >
                {"   "}
                Create an Account
              </Link>
            </Text>
          </Flex>

          <SignInOptions current="customer" />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default CustomerSignIn;
