import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import { useAPIActions } from "../../hooks/useActions";
import useResponsive from "../../hooks/useResponsive";
import { SHIPMENT } from "../../redux/constants";
import DriverSearchModal from "./DriverSearchModal";
import TransportSearchModal from "./TransportSearchModal";

const TransportShipment = ({ shipment }) => {
  console.log("TransportShipment", shipment);

  const [showDriverSearchModal, setShowDriverSearchModal] = useState(false);

  const { updateShipment } = useAPIActions();

  const { currentUser } = useSelector((state) => state.ui);
  //console.log("currentUser", currentUser);

  const onAssignShipment = () => {
    console.log("assign shipment");

    setShowDriverSearchModal(true);
  };

  return (
    <Flex justifyContent="center" flex={1}>
      {shipment.status === SHIPMENT.ASSIGNED && (
        <Flex>
          <CustomButton large onClick={onAssignShipment}>
            <Text fontSize={18} fontWeight={600}>
              Send to Driver
            </Text>
          </CustomButton>
        </Flex>
      )}

      <DriverSearchModal
        open={showDriverSearchModal}
        setOpen={setShowDriverSearchModal}
        shipmentId={shipment._id}
      />
    </Flex>
  );
};

export default TransportShipment;
