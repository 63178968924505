export const SERVER_URL = "https://tisnix-backend-5l2ankptqq-ew.a.run.app";
//export const SERVER_URL = "http://localhost:3001";

export const MIN_PASSWORD_LENGTH = 4;
export const FIRSTNAME_MIN_LEN = 1;
export const FIRSTNAME_MAX_LEN = 30;
export const LASTNAME_MIN_LEN = 1;
export const LASTNAME_MAX_LEN = 30;

export const USERNAME_MIN_LEN = 8;
export const USERNAME_MAX_LEN = 20;

export const SHIPMENT = {
  PENDING: "pending", // when shipment is created
  ACCEPTED: "accepted", // when shipment is accepted by organization
  ASSIGNED: "assigned", // when shipment is assigned to transport
  DRIVER_ASSIGNED: "driverAssigned", // when shipment is assigned to driver
  PICKED: "picked", // when shipment is picked up by driver
  TRANSIT: "transit", // when shipment is in transit by driver
  DELIVERED: "delivered", // when shipment is delivered by driver
  COMPLETED: "completed", // when shipment is completed by driver
  CANCELLED: "cancelled", // when shipment is cancelled by anyone
  POD_ATTACHED: "podAttached", // when shipment is completed & POD attached by driver
};

export const DEV_TESTING = false;
