import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

import DefaultAuth from "../../../../layouts/auth/Default";
import Text from "../../../../components/Text";
import illustration from "../../../../assets/img/logo.png";
import { Colors } from "../../../../components/config";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useAPIActions } from "../../../../hooks/useActions";
import { setSnackbarAction } from "../../../../redux/actionCreators/uiActionCreators";

const VerifyEmail = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const dispatch = useDispatch();

  const params = useParams();

  const [showContent, setShowContent] = useState(false);

  const [linkData, setLinkData] = useState(null);
  const history = useHistory();

  const { verifyEmail } = useAPIActions();

  useEffect(() => {
    (async () => {
      if (params?.id) {
        verifyEmail(params?.id, (success) => {
          if (!success) {
            setTimeout(() => {
              history.push("/auth/organization/sign-in");
            }, 2000);
            return;
          }
          dispatch(
            setSnackbarAction({
              message: "Email Verified successfully",
              severity: "success",
            })
          );
          setTimeout(() => {
            history.push("/auth/organization/sign-in");
          }, 2000);
        });
      }
    })();
  }, []);

  if (!showContent) return <></>;

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="70vh"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="24px" mb="10px">
            Verifying your email address
          </Heading>
          <CircularProgress color="theme" size={30} />
          {/* <Text color={Colors.lightgrey2} fontSize={16}>
            Enter your new password
          </Text> */}
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        ></Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default VerifyEmail;
