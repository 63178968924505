import React, { useEffect, useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import useResponsive from "../../../hooks/useResponsive";
import { Flex, Stack } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/Iconify";
import { Colors } from "../../../components/config";
import { getMimeType } from "../../../utils";
import { setSnackbarAction } from "../../../redux/actionCreators/uiActionCreators";
import axios from "axios";
import { SERVER_URL } from "../../../redux/constants";

const ProfileEditModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 800,
  overflowY: "scroll",

  padding: "10px",

  minWidth: !isMobile && 800,

  width: isMobile && "95vw",

  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const ImageOverlayStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "black",
  opacity: 0.2,
  position: "absolute",
  // width: "100%",
  // height: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  top: 0,
  borderRadius: 10,
  overflow: "hidden",
}));

const ProfileEditModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: "100%",
  overflowY: "scroll",

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
}));
const ProfileEditModal = ({
  open,
  setOpen,
  onClick,
  organizationData: { companyName, website, address, KVK, name, email, logo },
  organizationId,
}) => {
  const { currentUser } = useSelector((state) => state.ui);

  const { updateOrganization, setSnackbarAction } = useAPIActions();
  const [picture, setPicture] = useState("");

  const isMobile = useResponsive("down", "600");

  const SignUpSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
    name: Yup.string().required("Name is required"),
    website: Yup.string().required("Website is required"),
    address: Yup.string().required("Address is required"),

    KVK: Yup.string().required("KVK is required"),

    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const defaultValues = {
    companyName: companyName || "",
    website: website || "",
    address: address || "",

    KVK: KVK || "",

    name: name || "",
    email: email || "",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    if (!picture) {
      setSnackbarAction({
        open: true,
        message: "Please select a logo",
        severity: "error",
      });
      return;
    }

    try {
      const { name, email, companyName, website, address, KVK } =
        methods.getValues();

      const company = {
        companyName,
        website,
        address,
        KVK,
        email,
      };

      if (picture.file) {
        let formData = new FormData();
        formData.append("file", picture.file);

        let imageUrlResponse = await axios({
          method: "post",
          url: `${SERVER_URL}/organization/logo-upload`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log("imageUrlResponse", imageUrlResponse);
        if (imageUrlResponse.data) {
          company.logo = imageUrlResponse.data;
        }
      }

      updateOrganization(
        organizationId,
        {
          ...company,
        },
        () => {
          setOpen(false);

          setSnackbarAction({
            severity: "success",
            message: "Profile updated successfully",
          });
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (logo) {
      setPicture({
        localUri: logo,
        name: "logo",
      });
    }
  }, []);

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPicture(result);
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <ProfileEditModalStyle isMobile={isMobile}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <HeaderContainer>
            <Text center fontSize={22} fontWeight={600} mb={2}>
              Edit Profile
            </Text>
          </HeaderContainer>

          <ProfileEditModalContentContainer>
            <Stack spacing={10} width={"100%"}>
              <RHFTextField name="companyName" label="Company Name" />

              <RHFTextField name="name" disabled={name} label="Your Name" />
              <RHFTextField name="email" label="Email" disabled={email} />
              <RHFTextField name="address" label="Address" />
              <RHFTextField name="KVK" label="KVK" />
              <RHFTextField name="website" label="Website" />

              {picture?.localUri ? (
                <Box
                  sx={{
                    position: "relative",
                    mr: 1,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Text fontSize={20} fontWeight={500} mb={2}>
                    Selected Logo
                  </Text>

                  <img
                    style={{
                      height: 200,
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                    src={picture.localUri}
                  />
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Text>{picture.name}</Text>
                    <IconButton onClick={() => setPicture("")}>
                      <Iconify
                        icon="carbon:close"
                        sx={{
                          color: Colors.red,
                          height: 24,
                          width: 24,
                        }}
                      />
                    </IconButton>
                  </Flex>
                  <ImageOverlayStyle />
                </Box>
              ) : (
                <Box mb={2} display="flex" alignItems="center">
                  <IconButton
                    sx={{ mr: 1 }}
                    variant="contained"
                    color="theme"
                    component="label"
                  >
                    <Iconify icon="ant-design:plus-outlined" />
                    <input type="file" hidden onChange={onChangeImage} />
                  </IconButton>
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={Colors.extraDarkGreyText}
                  >
                    Add Logo
                  </Text>
                </Box>
              )}
            </Stack>
          </ProfileEditModalContentContainer>

          <Flex justifyContent="center">
            <LoadingButton
              sx={{
                width: 400,
              }}
              size="large"
              type="submit"
              variant="contained"
              color="theme"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Flex>
        </FormProvider>
      </ProfileEditModalStyle>
    </CustomModal>
  );
};
export default ProfileEditModal;
