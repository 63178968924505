import { Flex, VStack } from "@chakra-ui/react";
import { LoadingButton } from "@mui/lab";
import { Box, styled } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../components/config";
import Text from "../../../components/Text";
import { useAPIActions } from "../../../hooks/useActions";
import {
  getShipmentById,
  getShipmentForMessageById,
} from "../../../redux/actionCreators/shipmentActionCreators";
import ShipmentChatBox from "./ShipmentChatBox";

const ShipmentMessageStyle = styled(Box)(({ theme }) => ({}));

const ShipmentMessage = ({ shipmentId, from, dateSent, other, self }) => {
  const [currentShipment, setCurrentShipment] = useState(null);

  const isMe = from === "me";
  const alignment = isMe ? "flex-end" : "flex-start";

  useEffect(() => {
    console.log("ShipmentMessage shipmentId", { shipmentId });

    if (shipmentId) {
      (async () => {
        let shipment = await getShipmentForMessageById(shipmentId);
        setCurrentShipment(shipment);
      })();
    }
  }, [shipmentId]);

  // console.log("ShipmentMessage currentShipment", currentShipment);

  return (
    <ShipmentMessageStyle>
      {currentShipment && (
        <VStack mt={6} alignItems={alignment} alignSelf={alignment}>
          {
            <ShipmentChatBox
              shipment={currentShipment}
              setCurrentShipment={setCurrentShipment}
              other={other}
              self={self}
            />
          }
          <Text fontSize={12} color={Colors.darkgreyText}>
            {moment(Math.round(dateSent)).format("DD MMM, YYYY  hh:mm a")}
          </Text>
        </VStack>
      )}
    </ShipmentMessageStyle>
  );
};

export default ShipmentMessage;
