import React, { useState } from "react";
import { FormProvider } from "../../../../components/hook-form";
import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../../../redux/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../../../components/hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { useAPIActions } from "../../../../hooks/useActions";
import { useHistory } from "react-router-dom";
import { getMimeType } from "../../../../utils";
import Text from "../../../../components/Text";
import { Colors } from "../../../../components/config";
import { vehicleOptions } from "./vehicleOptions";
import ThemeProvider from "../../../../muiTheme";
import { appRoutes } from "../../../../assets/constants";
import moment from "moment";

const ImageOverlayStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "black",
  opacity: 0.2,
  position: "absolute",
  // width: "100%",
  // height: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  top: 0,
  borderRadius: 10,
  overflow: "hidden",
}));

const SignUpForm = ({
  organizationDriverLinkData: {
    email,
    organization: { companyName },
    organizationId,
  },
}) => {
  const { signUpDriver, setSnackbarAction } = useAPIActions();

  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const [picture, setPicture] = useState("");

  const [typeOfVehicle, setTypeOfVehicle] = useState(null);

  const SignUpSchema = Yup.object().shape({
    licensePlate: Yup.string().required("License Plate is required"),
    name: Yup.string().required("Name is required"),
    dob: Yup.date()
      .max(moment().format("YYYY-MM-DD"), "Cannot add a future date")
      .required("Date of birth is required"),
    passportNumber: Yup.string().required("Passport Number is required"),

    driverLicenseNumber: Yup.string().required(
      "Driver License Number is required"
    ),
    maxCapacityForVehicle: Yup.string().required(
      "Max Capacity For Vehicle is required"
    ),

    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    companyName: Yup.string().required("Company name is required"),

    password: Yup.string()
      .required("Password is required")
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      ),

    repeatPassword: Yup.string()
      .required("Repeat password required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const defaultValues = {
    // licensePlate: "H67ZS",
    // driverLicenseNumber: "123456789",
    // dob: moment().add(1, "day").format("YYYY-MM-DD"),

    // passportNumber: "123456789",
    // maxCapacityForVehicle: "4",
    // typeOfVehicle: "Sedan",
    // name: "James Smith",
    // email: email || "",
    // companyName: companyName || "",
    // password: "acme123456",
    // repeatPassword: "acme123456",

    licensePlate: "",
    driverLicenseNumber: "",
    dob: moment().subtract(1, "day").format("YYYY-MM-DD"),

    passportNumber: "",
    maxCapacityForVehicle: "",
    typeOfVehicle: "",
    name: "",
    email: email || "",
    companyName: companyName || "",
    password: "",
    repeatPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    console.log("onSubmit");
    if (!picture.file) {
      setSnackbarAction({
        open: true,
        message: "Please select a profile picture",
        severity: "error",
      });
      return;
    }

    if (!typeOfVehicle) {
      setSnackbarAction({
        open: true,
        message: "Please select a type of vehicle",
        severity: "error",
      });
      return;
    }

    console.log("typeOfVehicle", typeOfVehicle);

    const {
      name,
      licensePlate,
      dob,
      passportNumber,
      driverLicenseNumber,
      maxCapacityForVehicle,

      email,
      password,
    } = methods.getValues();
    const driver = {
      name,
      licensePlate,
      dob,
      passportNumber,
      driverLicenseNumber,
      maxCapacityForVehicle,
      typeOfVehicle: typeOfVehicle.name,
      email,
      password,
      type: "driver",
      organizationId,
    };
    signUpDriver(driver, picture.file, () => {
      history.push(appRoutes.driverSignIn);
    });

    console.log(driver);
  };

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPicture(result);
  };

  const RenderTypeOfVehicleArea = () => {
    return (
      <Box mb={2}>
        {typeOfVehicle ? (
          <Flex alignItems="center">
            <Flex flex={1}>
              <img
                src={typeOfVehicle.image}
                style={{
                  height: 30,
                }}
              />
              <Flex direction="column">
                <Text fontSize={16}>{typeOfVehicle.name}</Text>
                {/* <Text fontSize={14}>
                 
                  address
                </Text> */}
              </Flex>
            </Flex>
            <IconButton onClick={() => setTypeOfVehicle(null)}>
              <Iconify icon="charm:cross" />
            </IconButton>
          </Flex>
        ) : (
          <ThemeProvider>
            <Autocomplete
              sx={{ flex: 1 }}
              options={vehicleOptions}
              id="controlled-demo"
              getOptionLabel={(option) => option.name}
              renderOption={renderTypeOfVehicleOption}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              value={typeOfVehicle}
              onChange={(event, newValue) => {
                setTypeOfVehicle(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of Vehicle"
                  variant="outlined"
                  color="theme"
                />
              )}
            />
          </ThemeProvider>
        )}
      </Box>
    );
  };

  const renderTypeOfVehicleOption = (props, option) => (
    <Box {...props} mb={1}>
      <img
        src={option.image}
        style={{
          height: 30,

          marginRight: 10,
          borderRadius: 100,
        }}
      />

      <Text fontSize={16}>{option.name}</Text>
    </Box>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={10}>
        <RHFTextField name="name" label="Your Name" />
        <RHFTextField name="email" label="Email" disabled={email} />
        <RHFTextField
          name="companyName"
          label="Company Name"
          disabled={companyName}
        />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="repeatPassword"
          label="Repeat Password"
          type={showPasswordRepeat ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                >
                  <Iconify
                    icon={
                      showPasswordRepeat ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="licensePlate" label="License Plate" />

        <RHFTextField name="dob" label="Date of birth" type="date" />
        <RHFTextField name="passportNumber" label="Passport Number" />
        <RHFTextField
          name="driverLicenseNumber"
          label="Driver License Number"
        />

        <RHFTextField
          name="maxCapacityForVehicle"
          label="Max Capacity For Vehicle"
        />

        <RenderTypeOfVehicleArea />

        {picture?.localUri ? (
          <Box
            sx={{
              position: "relative",
              mr: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text fontSize={20} fontWeight={500} mb={2}>
              Selected Logo
            </Text>

            <img
              style={{
                height: 200,
                borderRadius: 10,
                cursor: "pointer",
              }}
              src={picture.localUri}
            />
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Text>{picture.name}</Text>
              <IconButton onClick={() => setPicture("")}>
                <Iconify
                  icon="carbon:close"
                  sx={{
                    color: Colors.red,
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Flex>
            <ImageOverlayStyle />
          </Box>
        ) : (
          <Box mb={2} display="flex" alignItems="center">
            <IconButton
              sx={{ mr: 1 }}
              variant="contained"
              color="theme"
              component="label"
            >
              <Iconify icon="ant-design:plus-outlined" />
              <input type="file" hidden onChange={onChangeImage} />
            </IconButton>
            <Text
              fontSize={16}
              fontWeight={500}
              color={Colors.extraDarkGreyText}
            >
              Add Logo
            </Text>
          </Box>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="theme"
          loading={isSubmitting}
        >
          Sign Up
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default SignUpForm;
