import axios from "axios";
import { SERVER_URL } from "../constants";
import store from "../store";

export const APIRequest = async (
  route,
  method = "GET",
  params = false,
  accessToken = false,
  auth = false,
  throwError = true
) => {
  const reqOptions = {
    method: method,
    url: SERVER_URL + route,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (accessToken) {
    const accessToken = await localStorage.getItem("tisnix_user_access_token");
    if (!accessToken)
      throw new Error(
        "Failed to retrieve access token for this request. Please try again."
      );
    reqOptions.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (auth) {
    const authState = store.getState().auth;
    reqOptions.headers.Perspective = authState.perspective;
  }
  if (params) {
    if (method === "POST" || method === "PUT" || method === "DELETE")
      reqOptions.data = params;
    else reqOptions.params = params;
  }

  console.log("reqOptions", { reqOptions });

  return axios(reqOptions)
    .then((res) => {
      // console.table("Request response: ", res);
      return res.data;
    })
    .catch(function (error) {
      console.log("main err", { error });

      console.log("ERROR in request: ", error.response?.data);
      let message = error.response?.data?.error ||
        (error.response?.data?.message && {
          message: error.response?.data?.message,
        }) || {
          message: "Something went wrong!",
        };

      if (throwError) throw message;
    });
};
