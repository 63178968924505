// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import tableDataComplex from "../../../views/app/shipments/variables/tableDataComplex.json";
import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";
import { columnsDataComplex } from "../../sharedComponents/home/variables/columnsData";
import { cleanShipmentData } from "../../sharedComponents/home/util";
import { useSelector } from "react-redux";
import { SHIPMENT } from "../../../redux/constants";

export default function Shipments() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { shipments } = useSelector((state) => state.shipment);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Shipments"
          value={shipments.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Active Shipments"
          value={
            shipments.filter(
              (item) =>
                item.status !== SHIPMENT.DELIVERED &&
                item.status !== SHIPMENT.POD_ATTACHED &&
                item.status !== SHIPMENT.CANCELLED
            ).length
          }
        />
      </SimpleGrid>

      {shipments.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="All Shipments"
            columnsData={columnsDataComplex}
            tableData={cleanShipmentData(shipments)}
          />
        </SimpleGrid>
      )}
    </Box>
  );
}
