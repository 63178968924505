import React, { useState } from "react";
import { FormProvider } from "../../../components/hook-form";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../../components/hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Box, IconButton, InputAdornment, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useHistory } from "react-router-dom";
import { useAPIActions } from "../../../hooks/useActions";
import { DEV_TESTING } from "../../../redux/constants";

const ImageOverlayStyle = styled(Box)(({ theme }) => ({}));

const ShipmentForm = ({
  onSuccess,
  userId,
  organizationId,
  organizationEmail,
  currentUser,
}) => {
  const { createShipment } = useAPIActions();

  console.log("ShipmentForm currentUser", currentUser);

  const history = useHistory();

  const [createBtnDisabled, setCreateBtnDisabled] = useState(false);

  const ShipmentSchema = Yup.object().shape({
    goods: Yup.string().required("Goods is required"),

    weight: Yup.string().required("Weight is required"),
    amount: Yup.string().required("Amount is required"),
    sizes: Yup.string().required("Sizes is required"),
    pickupCompany: Yup.string().required("Sender is required"),
    pickupAddress: Yup.string().required("Pickup Address is required"),
    recipient: Yup.string().required("Recipient is required"),
    deliveryAddress: Yup.string().required("Delivery Address is required"),
    loadRef: Yup.string().required("Load Reference is required"),
    extraNote: Yup.string().required("Extra Note is required"),
  });

  const defaultValues = DEV_TESTING
    ? {
        goods: "Bag,Packet",
        weight: "20",
        amount: "2500",
        sizes: "10x10x10",
        pickupCompany: currentUser.name,
        pickupAddress: "No 1, 2nd Street, Lagos",
        recipient: "RecipientName",
        deliveryAddress: "No 2, 3rd Street, Lagos",
        loadRef: "Any loading details",
        extraNote: "Keep with care",
      }
    : {
        goods: "",
        weight: "",
        amount: "",
        sizes: "",
        pickupAddress: "",
        recipient: "",
        deliveryAddress: "",
        loadRef: "",
        pickupCompany: currentUser.name,
        extraNote: "",
      };

  const methods = useForm({
    resolver: yupResolver(ShipmentSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    console.log("onSubmit", {
      userId,
      organizationId,
      organizationEmail,
    });

    //setCreateBtnDisabled(true);
    const {
      goods,
      weight,
      amount,
      sizes,
      pickupCompany,
      pickupAddress,
      recipient,
      deliveryAddress,
      extraNote,
      loadRef,
    } = methods.getValues();

    try {
      createShipment(
        {
          customer: userId,
          organization: organizationId,

          goods,
          weight,
          amount,
          sizes,
          pickupCompany,
          pickupAddress,
          recipient,
          deliveryAddress,
          extraNote,
          loadRef,
          status: "pending",
        },
        organizationEmail,
        currentUser,
        (shipmentId) => onSuccess(shipmentId)
      );
    } catch (error) {
      console.log("error", error);
      setCreateBtnDisabled(false);
    }
  };

  console.log("ShipmentForm");

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <RHFTextField name="goods" label="Goods" placeholder="Wallet Bag etc" />
        <RHFTextField name="weight" label="Weight (kg)" placeholder="25kg" />
        <RHFTextField
          name="amount"
          label="Value of the parcel (€)"
          placeholder="2500"
        />
        <RHFTextField
          name="sizes"
          label="Sizes (HxWxB)"
          placeholder="10x20x10"
        />
        <RHFTextField
          name="pickupCompany"
          label="Sender Name"
          placeholder="John Doe"
        />
        <RHFTextField
          name="pickupAddress"
          label="Pickup Address"
          placeholder="No 1, 2nd Street, Lagos"
        />

        <RHFTextField
          name="loadRef"
          label="Loading Reference"
          placeholder="Any loading details"
        />

        <RHFTextField
          name="recipient"
          label="Recipient Name"
          placeholder="John Doe"
        />
        <RHFTextField
          name="deliveryAddress"
          label="Delivery Address"
          placeholder="No 1, 2nd Street, Lagos"
        />

        <RHFTextField
          name="extraNote"
          label="Extra Note"
          placeholder="Keep good care"
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="theme"
          loading={isSubmitting}
          disabled={createBtnDisabled}
        >
          Create Shipment
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default ShipmentForm;
