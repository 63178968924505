import axios from "axios";
import {
  SET_CUSTOMER,
  SET_ALL_CUSTOMERS,
  SET_CUSTOMER_CHATS,
  SET_CUSTOMER_CHATS_MESSAGES,
} from "../actions/customerActions";
import { SERVER_URL } from "../constants";
import { APIRequest } from "../utils/axios";
import {
  setLoadingAction,
  setSnackbarAction,
  setSocket,
} from "./uiActionCreators";
import socketIO from "socket.io-client";
import {
  SET_CURRENT_USER_UI,
  SET_SEARCH_RESULTS,
  SET_SOCKET,
} from "../actions/uiActions";

let socket;

export const signUpCustomer =
  (signUpInfo, file, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      let formData = new FormData();
      formData.append("file", file);

      let imageUrlResponse = await axios({
        method: "post",
        url: `${SERVER_URL}/customer/profile-pic-upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("imageUrlResponse", imageUrlResponse.data);

      if (imageUrlResponse.data) {
        signUpInfo.profilePicture = imageUrlResponse.data;
      }

      let response = await APIRequest("/customer", "POST", signUpInfo);
      console.log("signUpCustomer => ", response);

      callback();

      dispatch({
        type: SET_CUSTOMER,
        payload: response,
      });
      dispatch(setLoadingAction(false));
    } catch (error) {
      dispatch(setLoadingAction(false));
      console.log(error);
      dispatch(
        setSnackbarAction({
          severity: "error",
          message: error.message,
        })
      );
    }
  };

export const loginCustomer = (loginInfo, callback) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest("/auth/customer/login", "POST", loginInfo);
    console.log("loginCustomer => ", response);

    await localStorage.setItem(
      "tisnix_user_access_token",
      response.access_token
    );
    await localStorage.setItem(
      "tisnix_user_signed_in",
      JSON.stringify("/customer")
    );

    let user = await APIRequest("/customer/getCurrentUser", "GET", null, true);

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    callback();

    dispatch({
      type: SET_CUSTOMER,
      payload: user,
    });
    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: user._id,
        name: user.name,
        type: "customer",
      },
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingAction(false));
    dispatch(
      setSnackbarAction({
        severity: "error",
        message: error.message || error,
      })
    );
  }
};

export const getCurrentCustomer = () => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));

    let user = await APIRequest("/customer/getCurrentUser", "GET", null, true);

    console.log("getCurrentCustomer => ", user);

    dispatch({
      type: SET_CURRENT_USER_UI,
      payload: {
        id: user._id,
        name: user.name,
        type: "customer",
      },
    });

    socket = socketIO(SERVER_URL, {
      query: `userId=${user._id}`,
    });

    socket.on("online", (data) => {
      console.log("online", data);
    });

    // socket.emit("send-message", {
    //   message: "hello world",
    //   senderId: "636ea797eb6adc773ad51413",
    //   receiverId: "636ea797eb6adc773ad51413",
    //   chatRoomId: "63711eb9f66f6925b4b3dde3",
    //   from: user.name,
    //   message: "Hello mate",
    //   type: "cto",
    // });

    // socket.on("receive-message", (data) => {
    //   console.log("receive-message", data);
    // });

    await localStorage.setItem("tisnix_user", JSON.stringify(user));

    dispatch({
      type: SET_CUSTOMER,
      payload: user,
    });
    dispatch({
      type: SET_SOCKET,
      payload: Boolean(socket),
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    localStorage.removeItem("tisnix_user_signed_in");
    console.log("getCurrentCustomer => ", { error });
    dispatch(setLoadingAction(false));
  }
};

export const getOrganizationByName =
  (organizationName, orgId) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));
      let response = await APIRequest(
        `/organization/getOrganizationByName/${organizationName}`,
        "GET"
      );

      console.log("getOrganizationByName", response);

      if (response) {
        dispatch({
          type: SET_SEARCH_RESULTS,
          payload: response.filter((org) => org._id !== orgId),
        });
      } else
        dispatch({
          type: SET_SEARCH_RESULTS,
          payload: [],
        });

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const updateCustomer = (updateInfo, customerId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/customer/${customerId}`,
      "PUT",
      updateInfo
    );
    console.log("updateCustomer => ", response);

    dispatch({
      type: SET_CUSTOMER,
      payload: response,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerChatRooms = (customerId) => async (dispatch) => {
  try {
    dispatch(setLoadingAction(true));
    let response = await APIRequest(
      `/chat/all/${customerId}`,
      "GET",
      null,
      true
    );
    console.log("getCustomerChatRooms => ", response);

    let chatrooms = response.chats;

    // not a good one but for now
    let receiverResponse = (
      await Promise.all(
        chatrooms.map((chatroom) => {
          if (chatroom.organization) {
            return APIRequest(
              `/organization/${chatroom.organization}`,
              "GET",
              null,
              true
            );
          }
        })
      )
    ).filter((item) => item);

    console.log("receiverResponse", receiverResponse);

    let finalChatrooms = {};

    chatrooms.map((chatroom) => {
      receiverResponse.map((receiver) => {
        if (chatroom.organization === receiver._id) {
          chatroom.receiver = receiver;
          finalChatrooms[chatroom._id] = chatroom;
        }
      });
    });

    console.log("final chatrooms", finalChatrooms);

    dispatch({
      type: SET_CUSTOMER_CHATS,
      payload: finalChatrooms,
    });
    dispatch(setLoadingAction(false));
  } catch (error) {
    console.log(error);
  }
};

export const createChatroomForCustomer =
  (senderId, receiverId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("createChatroomForCustomer", {
        senderId,
        receiverId,
      });

      let response = await APIRequest(
        `/chat/userToOrganization`,
        "POST",
        {
          senderId,
          receiverId,
        },
        true
      );

      console.log("createChatroomForCustomer ", response);

      dispatch(getCustomerChatRooms(senderId));

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getChatroomMessages =
  (chatroomId, callback) => async (dispatch) => {
    try {
      dispatch(setLoadingAction(true));

      console.log("chatroomId", chatroomId);

      let response = await APIRequest(`/chat/${chatroomId}`, "GET", null, true);

      console.log("getChatroomMessages => ", response);

      let payload = {
        chatroomId,
        messages: response,
      };

      dispatch({
        type: SET_CUSTOMER_CHATS_MESSAGES,
        payload: payload,
      });

      callback();

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
    }
  };

export const sendCustomerChatMessage =
  (
    message,
    senderId,
    receiverId,
    chatroomId,
    messageType,
    attachmentUrl,
    shipmentId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoadingAction(true));

      let { chats, chatsMessages } = getState().customer;

      socket.emit("send-message", {
        message,
        senderId,
        receiverId,
        chatroomId,
        type: "cto",
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      let newChatsObject = { ...chats };
      let newChatsMessagesObject = { ...chatsMessages };

      console.log("newChatsObject", {
        message,
        senderId,
        receiverId,
        chatroomId,
        messageType,
        attachmentUrl,
        shipmentId: shipmentId,
      });

      console.log("newChatsObject", newChatsObject);
      console.log("newChatsMessagesObject", newChatsMessagesObject);

      if (newChatsObject[chatroomId]) {
        let chatroom = newChatsObject[chatroomId];
        let chatroomMessages = newChatsMessagesObject[chatroomId];

        console.log("chatroom", chatroom);
        console.log("chatroomMessages", chatroomMessages);

        let messageObject = {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          timeStamp: Date.now(),
          chatroomId,
          messageType,
          attachmentUrl,
          shipmentId,
        };

        let chatMessages = [...chatroomMessages, messageObject];

        newChatsObject[chatroomId] = { ...chatroom };

        let payload = {
          chatroomId,
          messages: chatMessages,
        };

        // dispatch({
        //   type: SET_CUSTOMER_CHATS,
        //   payload: newChatsObject,
        // });
        dispatch({
          type: SET_CUSTOMER_CHATS_MESSAGES,
          payload,
        });
      }

      dispatch(setLoadingAction(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingAction(false));
    }
  };

export const listenToCustomerSocketEvents =
  () => async (dispatch, getState) => {
    try {
      console.log("listenToSocketEvents ");

      socket.on("receive-message", (data) => {
        let { chats, chatsMessages } = getState().customer;

        console.log("receive-message", data);
        let {
          message,
          messageType,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        } = data.payload;
        console.log("receive-message", {
          message,
          messageType,
          senderId,
          receiverId,
          chatroomId,
          timeStamp,
          attachmentUrl,
          shipmentId,
        });

        let newChatsObject = { ...chats };
        let newChatsMessagesObject = { ...chatsMessages };

        // first check if chatroom exists

        console.log("newChatsObject", newChatsObject[chatroomId]);

        if (newChatsObject[chatroomId]) {
          let chatroom = newChatsObject[chatroomId];
          let chatroomMessages = newChatsMessagesObject[chatroomId];

          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatMessages = [...chatroomMessages, messageObject];

          newChatsObject[chatroomId] = { ...chatroom };

          let payload = {
            chatroomId,
            messages: chatMessages,
          };

          dispatch({
            type: SET_CUSTOMER_CHATS_MESSAGES,
            payload,
          });
        } else {
          // chatroom does not exist
          // so create a new chatroom
          let messageObject = {
            senderId: senderId,
            receiverId: receiverId,
            message: message,
            timeStamp,
            chatroomId,
            messageType,
            attachmentUrl,
            shipmentId,
          };

          let chatroom = {
            chatroomId,
          };

          console.log("new chatroom", chatroom);

          newChatsObject[chatroomId] = chatroom;

          let payload = {
            chatroomId,
            messages: [messageObject],
          };

          dispatch({
            type: SET_CUSTOMER_CHATS_MESSAGES,
            payload,
          });
        }

        dispatch({
          type: SET_CUSTOMER_CHATS,
          payload: newChatsObject,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

export const uploadChatImage = async (file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);

    let imageUrlResponse = await axios({
      method: "post",
      url: `${SERVER_URL}/chat/pic-upload`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    console.log("imageUrlResponse", imageUrlResponse.data);

    return imageUrlResponse.data;
  } catch (error) {
    console.log(error);
  }
};
