export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "Shipment Number",
    accessor: "count",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Created On",
    accessor: "date",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },

  {
    Header: "Shipment",
    accessor: "shipmentId",
  },
];

export const columnsDataComplexForDriver = [
  {
    Header: "Shipment Number",
    accessor: "count",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Created On",
    accessor: "date",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },

  {
    Header: "Change Status to",
    accessor: "actionId",
  },

  {
    Header: "Shipment",
    accessor: "shipmentId",
  },
];

export const organizationDataComplex = [
  // {
  //   Header: "Organization Number",
  //   accessor: "count",
  // },
  {
    Header: "Name",
    accessor: "name",
  },

  {
    Header: "Address",
    accessor: "address",
  },

  {
    Header: "Chat",
    accessor: "chatId",
  },
];

export const organizationUserDataComplex = [
  // {
  //   Header: "Organization Number",
  //   accessor: "count",
  // },
  {
    Header: "User Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },

  {
    Header: "Permissions",
    accessor: "permissions",
  },

  {
    Header: "Action",
    accessor: "joinedOn",
  },
];

export const organizationRequestDataComplex = [
  // {
  //   Header: "Organization Number",
  //   accessor: "count",
  // },
  {
    Header: "From",
    accessor: "from",
  },
  {
    Header: "To",
    accessor: "to",
  },

  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const organizationConnectionDataComplex = [
  // {
  //   Header: "Organization Number",
  //   accessor: "count",
  // },
  {
    Header: "Organization",
    accessor: "organization",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const organizationDriverDataComplex = [
  // {
  //   Header: "Organization Number",
  //   accessor: "count",
  // },
  {
    Header: "User Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Action",
    accessor: "joinedOn",
  },
];

export const transportDataComplex = [
  // {
  //   Header: "Transport Number",
  //   accessor: "count",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Address",
    accessor: "address",
  },

  {
    Header: "Chat",
    accessor: "chatId",
  },
];

export const driverDataComplex = [
  // {
  //   Header: "Driver Number",
  //   accessor: "count",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Chat",
    accessor: "chatId",
  },
];

export const columnsDataInvoice = [
  {
    Header: "Invoice Number",
    accessor: "count",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Created on",
    accessor: "date",
  },
  {
    Header: "Due By",
    accessor: "due",
  },
  {
    Header: "Details",
    accessor: "details",
  },
];
