import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../../components/Text";
import CustomModal from "../../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";
import { Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../../hooks/useActions";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { Colors } from "../../../components/config";
import { setSnackbarAction } from "../../../redux/actionCreators/uiActionCreators";

const GroupChatCreateModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "10px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: 10,
}));

const GroupChatCreateModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",
  padding: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: 10,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  borderRadius: "10px",
}));

const GroupChatCreateModal = ({ open, setOpen, onClick }) => {
  const isMobile = useResponsive("down", "600");

  const { organizationUsers, organizationDrivers, organization } = useSelector(
    (state) => state.organization
  );

  const { currentUser } = useSelector((state) => state.ui);

  const { createGroupChat } = useAPIActions();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);

  const dispatch = useDispatch();

  // console.log("organizationUsers", {
  //   organizationUsers,
  //   organizationDrivers,
  //   organization,
  // });

  const onCreateGroupChatClick = () => {
    console.log("selectedUsers", selectedUsers);
    console.log("selectedDrivers", selectedDrivers);

    let data = {
      organizationUsers: selectedUsers,
      drivers: selectedDrivers,
      organization: organization._id,
    };

    if (currentUser.role === "admin") {
      data.organizationUsers = [currentUser.userId, ...data.organizationUsers];
    } else if (currentUser.role === "user") {
      data.organizationUsers = [currentUser.userId, ...data.organizationUsers];
    }

    createGroupChat(data, () => {
      setOpen(false);
      dispatch(
        setSnackbarAction({
          message: "Group Chat Created Successfully",
          severity: "success",
        })
      );
    });
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <GroupChatCreateModalStyle isMobile={isMobile}>
        <HeaderContainer>
          <Text center fontSize={22} fontWeight={600} mb={2}>
            Create Group Chat
          </Text>
        </HeaderContainer>

        <Text fontSize={14} fontWeight={600} mb={1}>
          All Organization Members
        </Text>

        <GroupChatCreateModalContentContainer>
          {[
            ...organizationUsers.filter((user) => user.role !== "admin"),
            ...organizationDrivers,
          ].map((user) => (
            <Flex
              key={user.id}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={3}
            >
              <Flex flexDirection="column">
                <Text fontSize={18} fontWeight={500}>
                  {user.name}
                </Text>
                <Text fontSize={12} fontWeight={400} color={Colors.grey}>
                  {user.email}
                </Text>
              </Flex>
              <CustomCheckbox
                value={
                  selectedUsers.includes(user._id) ||
                  selectedDrivers.includes(user._id)
                }
                setValue={(value) => {
                  if (value && user?.driverLicenseNumber) {
                    setSelectedDrivers([...selectedDrivers, user._id]);
                  } else if (value) {
                    setSelectedUsers([...selectedUsers, user._id]);
                  } else {
                    setSelectedUsers(
                      selectedUsers.filter((id) => id !== user._id)
                    );
                    setSelectedDrivers(
                      selectedDrivers.filter((id) => id !== user._id)
                    );
                  }
                }}
              ></CustomCheckbox>
            </Flex>
          ))}
        </GroupChatCreateModalContentContainer>

        <Flex justifyContent="center">
          <CustomButton
            onClick={onCreateGroupChatClick}
            height={50}
            width="50%"
            //  disabled={!email}
            disabled={!(selectedUsers.length + selectedDrivers.length > 1)}
          >
            <Text center fontSize={18} fontWeight={600}>
              Create
            </Text>
          </CustomButton>
        </Flex>
      </GroupChatCreateModalStyle>
    </CustomModal>
  );
};
export default GroupChatCreateModal;
