// material
import { CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { useMemo } from "react";
import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "./../theme/theme";
import { deepmerge } from "@material-ui/utils";

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    []
  );

  const MUItheme = createTheme(themeOptions);
  MUItheme.components = componentsOverride(MUItheme);

  const theme = deepmerge(chakraTheme, MUItheme);


  return (
    <ChakraProvider theme={theme} resetCSS>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </ChakraProvider>
  );
}
