import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../components/Text";
import CustomModal from "../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../hooks/useResponsive";

import { useAPIActions } from "../../hooks/useActions";
import { Flex } from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";

const PodsDisplayModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",

  minWidth: !isMobile && 500,

  width: isMobile && "90vw",
}));

const PodsDisplayModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const PodsDisplayModal = ({ open, setOpen, podUrls }) => {
  const isMobile = useResponsive("down", "600");

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <PodsDisplayModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          Proofs of Delivery
        </Text>

        <PodsDisplayModalContentContainer>
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {podUrls.map((url, index) => (
              <Flex key={index} border="1px solid #000" mb={10}>
                <img src={url} style={{ height: 200 }} />
              </Flex>
            ))}
          </Flex>
        </PodsDisplayModalContentContainer>
        <Flex display="flex" justifyContent="center" pt={2}>
          <CustomButton
            large
            width={250}
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CustomButton>
        </Flex>
      </PodsDisplayModalStyle>
    </CustomModal>
  );
};
export default PodsDisplayModal;
