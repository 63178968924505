// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/avatar5.png";
import React, { useState } from "react";
import {
  Banner,
  Projects,
  Notifications,
} from "../../sharedComponents/profile";
import { useSelector } from "react-redux";
import { cleanDriverDataForProfile } from "../../sharedComponents/profile/util";
import { useAPIActions } from "../../../hooks/useActions";
import ProfileEditModal from "./ProfileEditModal";
import { cleanDriverData } from "../../sharedComponents/home/util";

export default function Profile() {
  const { driver } = useSelector((state) => state.driver);
  const { shipments } = useSelector((state) => state.shipment);

  const [showProfileEditModal, setShowProfileEditModal] = useState(false);

  const { updateDriver } = useAPIActions();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={driver?.profilePicture}
          name={driver?.name}
          job="Driver"
          shipments={shipments?.length}
          invoices="0"
          followers="15"
          following="274"
        />
      </Grid>
      {driver && (
        <Grid
          mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "2fr 1fr",
            "2xl": "2fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <Projects
            data={cleanDriverDataForProfile(driver)}
            onProfileEdit={() => setShowProfileEditModal(true)}
          />
          {/* <Notifications used={25.6} total={50} /> */}
        </Grid>
      )}

      {driver && showProfileEditModal && (
        <ProfileEditModal
          open={showProfileEditModal}
          setOpen={setShowProfileEditModal}
          driverData={cleanDriverDataForProfile(driver)}
          driverId={driver?._id}
        />
      )}
    </Box>
  );
}
