import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthLayout from "./layouts/auth";
import AppLayout from "./layouts/app";

import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Provider } from "react-redux";
import store from "./redux/store";
import ThemeProvider from "./muiTheme";
import LoadingOverlay from "react-loading-overlay";
import App from "./App";
import SnackbarAlert from "./components/SnackbarAlert";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      {/* <ChakraProvider theme={theme}> */}
      <ThemeEditorProvider>
        <App />
        <ThemeProvider>
          <SnackbarAlert />
        </ThemeProvider>
      </ThemeEditorProvider>
      {/* </ChakraProvider> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
