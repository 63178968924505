import * as actions from "../actions/uiActions";

const initialState = {
  snackbar: {
    title: "",
    description: "",
    open: false,
    severity: "success",
  },
  currentScreen: "",
  loading: false,
  socket: null,
  currentUser: null,

  searchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SNACKBAR: {
      return {
        ...state,
        snackbar: action.payload,
      };
    }

    case actions.SET_CURRENT_SCREEN: {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }

    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case actions.SET_SOCKET: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    case actions.SET_CURRENT_USER_UI: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case actions.SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload,
      };
    }

    case actions.RESET_UI_DATA:
      return initialState;

    default:
      return state;
  }
};
