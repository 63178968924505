import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DefaultAuth from "../../../../layouts/auth/Default";
import Text from "../../../../components/Text";
import illustration from "../../../../assets/img/logo.png";
import { Colors } from "../../../../components/config";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { MIN_PASSWORD_LENGTH } from "../../../../redux/constants";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getOrganizationResetPasswordLinkData,
  resetPassword,
} from "../../../../redux/actionCreators/organizationActionCreators";
import { setSnackbarAction } from "../../../../redux/actionCreators/uiActionCreators";
import { useDispatch } from "react-redux";
import { appRoutes } from "../../../../assets/constants";

const ResetPassword = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const dispatch = useDispatch();

  const params = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const [showContent, setShowContent] = useState(false);

  const [linkData, setLinkData] = useState(null);
  const history = useHistory();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      ),
    passwordRepeat: Yup.string()
      .required("Repeat Password is required")
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      ),
  });

  const defaultValues = {
    password: "",
    passwordRepeat: "",
  };

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    console.log("submit");
    const { password } = methods.getValues();

    try {
      await dispatch(
        resetPassword(linkData.email, password, linkData.link, () => {
          dispatch(
            setSnackbarAction({
              message: "Password reset successfully",
              severity: "success",
            })
          );
          history.push(appRoutes.organizationSignIn);
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setSnackbarAction({
          message: "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (params?.id) {
        let data = await getOrganizationResetPasswordLinkData(params.id);
        if (!data.error) {
          console.log(data);
          setLinkData({
            email: data.link.email,
            link: data.link._id,
          });
          setShowContent(true);
        } else {
          setShowContent(false);
          dispatch(
            setSnackbarAction({
              message: "Invalid reset password link",
              severity: "error",
            })
          );

          setTimeout(() => {
            history.push(appRoutes.organizationForgotPassword);
          }, 3000);
        }
      }
    })();
  }, []);

  if (!showContent) return <></>;

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="70vh"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={5}
        >
          <Image
            src={illustration}
            height={"100px"}
            width={"100px"}
            marginBottom={"20px"}
          />
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text color={Colors.lightgrey2} fontSize={16}>
            Enter your new password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "600px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box mb={2}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={10}>
                <RHFTextField
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  name="passwordRepeat"
                  label="Repeat Password"
                  type={showPasswordRepeat ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setShowPasswordRepeat(!showPasswordRepeat)
                          }
                        >
                          <Iconify
                            icon={
                              showPasswordRepeat
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="theme"
                  loading={isSubmitting}
                >
                  Reset Password
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Box>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default ResetPassword;
