import { RESET_CUSTOMER_DATA } from "../actions/customerActions";
import { RESET_DRIVER_DATA } from "../actions/driverActions";
import { RESET_INVOICE_DATA } from "../actions/invoiceActions";
import { RESET_ORGANIZATION_DATA } from "../actions/organizationActions";
import { RESET_SHIPMENT_DATA } from "../actions/shipmentActions";
import * as actions from "../actions/uiActions";

export const setSnackbarAction = (snackbarData) => async (dispatch) => {
  console.log("setSnackbarAction => ", snackbarData);
  dispatch({
    type: actions.SET_SNACKBAR,
    payload: {
      severity: snackbarData.severity,
      open: !!snackbarData.message,
      message: snackbarData.message,
    },
  });
};

export const setCurrentScreenAction = (screen) => ({
  type: actions.SET_CURRENT_SCREEN,
  payload: screen,
});

export const setLoadingAction = (loading) => ({
  type: actions.SET_LOADING,
  payload: loading,
});

export const setSocket = (socket) => ({
  type: actions.SET_SOCKET,
  payload: socket,
});

export const resetEverything = () => async (dispatch) => {
  console.log("resetEverything");
  dispatch({
    type: RESET_ORGANIZATION_DATA,
  });
  dispatch({
    type: RESET_SHIPMENT_DATA,
  });
  dispatch({
    type: RESET_CUSTOMER_DATA,
  });
  dispatch({
    type: RESET_DRIVER_DATA,
  });
  dispatch({
    type: RESET_INVOICE_DATA,
  });
  dispatch({
    type: actions.RESET_UI_DATA,
  });
};
