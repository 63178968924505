// Chakra imports
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "../../components/footer/FooterAuth";
import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";
// Custom components
// Assets

function PrivacyIllustration(props) {
  const { children } = props;
  // Chakra color mode
  return (
    <Flex position="relative" height="100vh">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          // lg: "100vh",
          // xl: "97vh",
        }}
        w="100%"
        maxW={{ md: "66%", lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        <Flex height="100%" overflow={"scroll"}>{children}</Flex>
        {/* <Box
          display={{ base: "none", md: "block" }}
          h="100%"
          minH="100vh"
          w={{ lg: "100vw", "2xl": "44vw" }}
          position="absolute"
          right="0px"
        ></Box> */}
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

PrivacyIllustration.propTypes = {};

export default PrivacyIllustration;
