// Chakra imports
import { Portal, Box, useDisclosure, Button } from "@chakra-ui/react";
import Footer from "../../components/footer/FooterAdmin.js";
// Layout components
import Navbar from "../../components/navbar/NavbarAdmin.js";
import Sidebar from "../../components/sidebar/Sidebar.js";
import { SidebarContext } from "../../contexts/SidebarContext";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useAPIActions } from "../../hooks/useActions.js";
import { useSelector } from "react-redux";
import AdminNavbar from "../../components/organization/navbar/NavbarAdmin.js";
import {
  getDefaultRoutesForOrganizationUser,
  getRoutesBasedOnPermissions,
} from "../../utils.js";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // by just using below line like this, it fixes the route name not changing issue in the header
  const location = useLocation();

  const {
    getShipmentsByUserId,
    getCurrentOrganizationUser,
    getOrganizationChatRooms,
    getAllTransports,
    getUsersByOrganization,
    getDriversByOrganization,
    getInvoicesByUserId,
    getPendingOrganizationRequests,
    getOrganizationConnections,
    getAllGroupChats,
    getShipmentsOrganization,
  } = useAPIActions();

  const { organizationUser, organization } = useSelector(
    (state) => state.organization
  );

  const { currentUser } = useSelector((state) => state.ui);
  const history = useHistory();

  const [routesToBeRendered, setRoutesToBeRendered] = useState([]);
  console.log("organization layout currentUser", {
    currentUser,
    routesToBeRendered,
  });
  useEffect(() => {
    if (currentUser && currentUser.permissions) {
      let permissibleRoutes = getRoutesBasedOnPermissions(
        routes,
        "/organization",
        currentUser.permissions
      );

      console.log("permissibleRoutes", permissibleRoutes);

      if (permissibleRoutes && permissibleRoutes.length > 0) {
        setRoutesToBeRendered(permissibleRoutes);
      }
    } else if (currentUser && currentUser.role === "admin") {
      setRoutesToBeRendered(routes);
    } else if (currentUser && currentUser.type === "driver") {
      setRoutesToBeRendered(routes);
    } else if (currentUser && currentUser.role === "user") {
      let permissibleRoutes = getDefaultRoutesForOrganizationUser(
        routes,
        "/organization"
      );
      console.log("permissibleRoutes", permissibleRoutes);
      setRoutesToBeRendered(permissibleRoutes);
    }
  }, [currentUser]);

  useEffect(() => {
    //    getAllShipments();
    // getAllTransports();
    getCurrentOrganizationUser(() => {
      localStorage.removeItem("tisnix_user_signed_in");
      localStorage.removeItem("tisnix_user_access_token");
      localStorage.removeItem("tisnix_user");
      history.push("/auth/organization");
    });
  }, []);

  useEffect(() => {
    if (organization && currentUser) {
      getOrganizationChatRooms(organization._id);
      getAllGroupChats(undefined, undefined, currentUser?.userId);

      getShipmentsByUserId("organization", organization._id);
      getInvoicesByUserId("organization", organization._id);

      getUsersByOrganization(organization._id);
      getDriversByOrganization(organization._id);

      getPendingOrganizationRequests(organization._id);
      getOrganizationConnections(organization._id);
      getShipmentsOrganization(organization._id);
    }
  }, [organization, currentUser]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Home";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/organization") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  if (!currentUser || routesToBeRendered.length === 0) return <></>;
  return (
    <Box height={"100%"}>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          height="100%"
          overflow="auto"
          position="relative"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <AdminNavbar
                state={{ user: organizationUser, company: organization }}
                onOpen={onOpen}
                logoText={"TixNix"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            //height="100%"
            pt="50px"
            width="100%"
          >
            <Switch>
              {getRoutes(routesToBeRendered)}
              <Redirect from="/organization" to="/organization/home" />
            </Switch>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
