// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "../../../components/calendar/MiniCalendar";
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useEffect } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import RecentShipmentsTable from "../../sharedComponents/home/RecentShipmentsTable";

import { useAPIActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  columnsDataComplex,
  organizationDataComplex,
} from "../../sharedComponents/home/variables/columnsData";
import {
  calculateInvoiceBalance,
  cleanOrganizationData,
  cleanShipmentData,
} from "../../sharedComponents/home/util";
import { SHIPMENT } from "../../../redux/constants";

const Home = () => {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { shipments } = useSelector((state) => state.shipment);
  const { organizations } = useSelector((state) => state.organization);

  const { customer } = useSelector((state) => state.customer);
  const { currentUser } = useSelector((state) => state.ui);
  const { invoices } = useSelector((state) => state.invoice);

  const chats = useSelector((state) => state.customer.chats);

  const history = useHistory();

  const { createChatroomForCustomer } = useAPIActions();

  console.log("organizations", organizations);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Shipments"
          value={shipments.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Active Shipments"
          value={
            shipments.filter(
              (item) =>
                item.status !== SHIPMENT.DELIVERED &&
                item.status !== SHIPMENT.POD_ATTACHED &&
                item.status !== SHIPMENT.CANCELLED
            ).length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Outstanding Invoice"
          value={`€${calculateInvoiceBalance(invoices)}`}
        />
      </SimpleGrid>

      {shipments.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            columnsData={columnsDataComplex}
            tableData={cleanShipmentData(shipments).reverse().splice(0, 7)}
          />
        </SimpleGrid>
      )}

      {/* {organizations.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentShipmentsTable
            dataType="Organizations"
            currentUser={currentUser}
            organization={true}
            columnsData={organizationDataComplex}
            tableData={cleanOrganizationData(organizations)}
            onChatClick={(chatId) => {
              console.log("chatId", { chatId, chats });
              if (
                Object.values(chats).find(
                  (item) => item.organization === chatId
                )
              ) {
                history.push(`/customer/chat`);
              } else {
                createChatroomForCustomer(customer._id, chatId, () => {
                  history.push(`/customer/chat`);
                });
              }
            }}
          />
        </SimpleGrid>
      )} */}
    </Box>
  );
};

export default Home;
