export const themeColor = "#ff33a0";

export const greyBackground = "#F4F4F4";
export const greyBackground_light = "#F9F9F9";
export const greenButton = ["#45b248", "#6cbb45", "#82bf43"];
export const gradientFade = [
  "rgba(255, 255, 255, 0.9)",
  "rgba(255, 255, 255, 0)",
];
export const greenButtonOrigNewer = ["#47b248", "#6cbb45", "#45b248"];
export const greenButtonOrig = ["#47b248", "#6cbb45", "#82bf43"];
export const whiteButton = ["#ffffff", "#ffffff", "#ffffff"];
export const grey = "#818181";
export const greenFlat = "#7DC13D";
export const greyText = "#818181";
export const darkgreyText = "#707070";

export const blackText = "#000000";
export const lightgrey = "#D3D3D3";
export const red = "#FF0000";

export const smileyHappy = "#45b248";
export const smileyNeutral = "#ff9d00";
export const smileyUnhappy = "red";
export const extraDarkGreyText = "#7a7a7a";

export const lightgrey2 = "#adadad";
export const greenText2 = "#7cbe43";
export const lightgrey3 = "#EFEFEF";

export const greenWallet = "#86c351";
export const greenText3 = "#a2d07b";
export const greenText4 = "#45b248";
export const chatGreen = "#49B34C";
export const greenMenuBar = "#80C932";

export const greyQr = "#676767";

export const businessBlue1 = "#77b1ff";
export const businessBlue2 = "#767bff";
export const blueButton = [businessBlue1, businessBlue2];
