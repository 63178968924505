// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "../../../components/calendar/MiniCalendar";
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import React, { useState } from "react";
import { MdAttachMoney, MdBarChart } from "react-icons/md";
import { columnsDataInvoice } from "../../sharedComponents/invoice/variables/columnsData";
import tableDataInvoice from "../../sharedComponents/invoice/variables/tableDataInvoice.json";
import RecentInvoicesTable from "../../sharedComponents/invoice/RecentInvoicesTable";
import Text from "../../../components/Text";
import { Colors } from "../../../components/config";
import { useSelector } from "react-redux";
import {
  calculateInvoiceBalance,
  cleanInvoiceData,
} from "../../sharedComponents/home/util";
import CreateNewInvoiceModal from "./CreateNewInvoiceModal";

export default function Invoices() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { currentUser } = useSelector((state) => state.ui);

  const [showCreateNewInvoiceModal, setShowCreateNewInvoiceModal] =
    useState(false);

  const { invoices } = useSelector((state) => state.invoice);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 1, "2xl": 1 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Outstanding Invoice"
          value={`€${calculateInvoiceBalance(invoices)}`}
        />
      </SimpleGrid>

      {currentUser.role === "admin" && (
        <SimpleGrid
          columns={{ base: 2, sm: 1, md: 2, lg: 4, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Button
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            onClick={() => setShowCreateNewInvoiceModal(true)}
          >
            Create new Invoice
          </Button>
        </SimpleGrid>
      )}

      {invoices.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <RecentInvoicesTable
            columnsData={columnsDataInvoice}
            tableData={cleanInvoiceData(invoices)}
            self={currentUser}
          />
        </SimpleGrid>
      ) : (
        <Flex justifyContent="center">
          <Text fontSize={18} fontWeight={500} color={Colors.lightgrey2}>
            No Invoices found
          </Text>
        </Flex>
      )}

      <CreateNewInvoiceModal
        open={showCreateNewInvoiceModal}
        setOpen={setShowCreateNewInvoiceModal}
        onClick={() => {}}
      />
    </Box>
  );
}
