import { Typography } from "@mui/material";
import React from "react";

const Text = ({
  children,
  fontSize,
  color,
  fontWeight,
  lineHeight,
  center,
  letterSpacing,
  uppercase,
  sx,
  mb,
  mr,
  ml,
  mt,
}) => {
  return (
    <Typography
      color={color}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
      sx={sx}
      mb={mb}
      mr={mr}
      ml={ml}
      mt={mt}
      textTransform={uppercase ? "uppercase" : "none"}
      align={center ? "center" : "left"}
    >
      {children}
    </Typography>
  );
};

export default Text;
