import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import Text from "../../components/Text";
import CustomModal from "../../components/CustomModal";
import { useSelector } from "react-redux";

import useResponsive from "../../hooks/useResponsive";
import { getMimeType } from "../../utils";
import CustomButton from "../../components/CustomButton";
import { Flex } from "@chakra-ui/react";
import { useAPIActions } from "../../hooks/useActions";

const DriverPodModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",

  minWidth: !isMobile && 500,

  width: isMobile && "95vw",
}));

const DriverPodModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,

  display: "flex",

  alignItems: "center",
  flexDirection: "column",

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const DriverPodModal = ({ shipment, open, setOpen, self }) => {
  const { currentUser } = useSelector((state) => state.ui);

  const { uploadShipmentPODs, sendShipmentStatusEmail } = useAPIActions();
  const [pictures, setPictures] = useState([]);
  const isMobile = useResponsive("down", "600");

  const onChangeImage = (e) => {
    console.log("e.target.files", e.target.files[0]);

    let extension = e.target.files[0].type.split("/")[1];
    extension = extension === "jpeg" ? "jpg" : extension;

    const result = {
      extension,
      mime: getMimeType(extension),
      name: e.target.files[0].name,
      localUri: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };

    console.log("result", result);

    setPictures((data) => [...data, result]);
  };

  const onUploadProofs = (e) => {
    if (pictures.length > 0) {
      uploadShipmentPODs(pictures, shipment, currentUser, () => {
        setOpen(false);
        sendShipmentStatusEmail(
          {
            shipmentNumber: shipment.shipmentNumber,
            status: "POD Uploaded",
            to: self.email,
            customer: shipment.customer._id,
            organization2: shipment.organization2?._id,
            organization: shipment.organization._id,
            notificationData: {
              customer: shipment.customer._id,
              organization2: shipment.organization2?._id,
              organization: shipment.organization._id,
              heading: "Shipment POD Uploaded",
              message: `Shipment#${shipment.shipmentNumber} POD has been uploaded`,
            },
          },
          true
        );
      });
    }
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <DriverPodModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          Upload Proof Of Delivery
        </Text>

        <DriverPodModalContentContainer>
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {pictures.map((picture, index) => (
              <Flex key={index} border="1px solid #000" mb={10}>
                <img src={picture.localUri} style={{ height: 200 }} />
              </Flex>
            ))}
          </Flex>
        </DriverPodModalContentContainer>
        <Flex display="flex" justifyContent="center" pt={2}>
          <CustomButton variant="outlined" component="label" large mr={2}>
            <input type="file" hidden onChange={onChangeImage} />
            Add Proofs
          </CustomButton>
          {pictures.length > 0 && (
            <CustomButton large onClick={onUploadProofs}>
              Upload
            </CustomButton>
          )}
        </Flex>
      </DriverPodModalStyle>
    </CustomModal>
  );
};
export default DriverPodModal;
