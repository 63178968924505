import { Flex, Input, Text as ChakraText } from "@chakra-ui/react";
import { LoadingButton } from "@mui/lab";
import { Box, styled } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Colors } from "../../../components/config";
import CustomButton from "../../../components/CustomButton";
import Iconify from "../../../components/Iconify";
import Text from "../../../components/Text";

import { useAPIActions } from "../../../hooks/useActions";
import { SHIPMENT } from "../../../redux/constants";
import { getColor } from "../utils";

const ShipmentChatBoxStyle = styled(Box)(({ theme, type, status }) => ({
  width: type === "customer" ? 260 : 300,

  borderRadius: "20px",
  padding: "10px 20px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",

  background: "#fff",

  display: "flex",

  cursor: "pointer",
}));

const OrganizationPriceInputField = ({
  shipment,
  type,
  currentType,
  priceInput,
  setPriceInput,
  editState,
  setEditState,
  organization2,
  currentUser,
  other,
}) => {
  console.log("organization2 => ", {
    organization2,
    currentUser,
    other,
    shipment,
    type,
    currentType,
  });

  if (
    (type === "customer" || other.receiverId === shipment.customer) &&
    currentType === "organization" &&
    shipment.status !== SHIPMENT.PENDING
  ) {
    return (
      <>
        {
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              {shipment.organization === currentUser.id
                ? "Earnings"
                : "Organization Cost:"}
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.customerCost}
              {" €"}
            </Text>
          </Flex>
        }
        {shipment.organizationCost &&
          shipment.organization === currentUser.id && (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Cost:
              </Text>
              <Text fontSize={14} fontWeight={600} mr={1}>
                {shipment.organizationCost}
                {" €"}
              </Text>
            </Flex>
          )}

        {shipment.transportCost &&
          shipment.organization === currentUser.id &&
          !shipment.organization2 && (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Driver Cost:
              </Text>
              <Text fontSize={14} fontWeight={600} mr={1}>
                {shipment.transportCost}
                {" €"}
              </Text>
            </Flex>
          )}
      </>
    );
  } else if (type === "customer" && shipment.customerCost && false) {
    return (
      <Flex mt={1} alignItems="center">
        <Text fontSize={14} mr={1}>
          Delivery Cost:
        </Text>
        <Text fontSize={14} fontWeight={600} mr={1}>
          {shipment.customerCost}
          {" €"}
        </Text>
        {shipment.status === SHIPMENT.PENDING && (
          <Iconify
            onClick={() => {
              setEditState(true);
              setPriceInput(shipment.customerCost);
            }}
            icon="material-symbols:edit"
            sx={{
              cursor: "pointer",
              mb: "4px",
            }}
          />
        )}
      </Flex>
    );
  } else if (type === "customer" || shipment.customer === other.receiverId) {
    return (
      <Flex mt={1} alignItems="center">
        <Text fontSize={14} mr={1}>
          Organization Cost:
        </Text>
        <Input
          // variant="unstyled"
          placeholder="Price"
          value={priceInput}
          onChange={(e) => setPriceInput(e.target.value)}
          type="number"
          sx={{
            fontSize: 14,

            height: "30px",
            width: "100px",
            padding: "0px 10px",

            "&:focus": {
              borderColor: Colors.themeColor,
              boxShadow: `0 0 0 1px ${Colors.themeColor}`,
            },
          }}
        />

        <Text ml={1}>€</Text>
      </Flex>
    );
  }

  if (organization2) {
    if (type === "driver" && shipment.transportCost && !editState) {
      return (
        <>
          {" "}
          {/* <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.transportCost + " €"}
            </Text>
            {shipment.status === SHIPMENT.ASSIGNED && (
              <Iconify
                onClick={() => {
                  setEditState(true);
                  setPriceInput(shipment.organizationCost);
                }}
                icon="material-symbols:edit"
                sx={{
                  cursor: "pointer",
                  mb: "4px",
                }}
              />
            )}
          </Flex> */}
        </>
      );
    } else if (type === "driver" && !shipment.transportCost) {
      return (
        <>
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Input
              // variant="unstyled"
              placeholder="Price"
              value={priceInput}
              onChange={(e) => setPriceInput(e.target.value)}
              type="number"
              sx={{
                fontSize: 14,

                height: "30px",
                width: "100px",
                padding: "0px 10px",

                "&:focus": {
                  borderColor: Colors.themeColor,
                  boxShadow: `0 0 0 1px ${Colors.themeColor}`,
                },
              }}
            />
          </Flex>
        </>
      );
    } else if (type === "driver" && shipment.transportCost) {
      return (
        <>
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.transportCost}
              {" €"}
            </Text>
          </Flex>

          {shipment.transportCost && (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Driver Cost:
              </Text>
              <Text fontSize={14} fontWeight={600} mr={1}>
                {shipment.transportCost}
                {" €"}
              </Text>
            </Flex>
          )}
        </>
      );
    }
  }

  if (type === "driver" && shipment.organizationCost && !editState) {
    return (
      <>
        {" "}
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Customer Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.customerCost}
            {" €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.organizationCost + " €"}
          </Text>
          {shipment.status === SHIPMENT.PENDING && (
            <Iconify
              onClick={() => {
                setEditState(true);
                setPriceInput(shipment.organizationCost);
              }}
              icon="material-symbols:edit"
              sx={{
                cursor: "pointer",
                mb: "4px",
              }}
            />
          )}
        </Flex>
      </>
    );
  } else if (type === "driver" && !shipment.organizationCost) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Customer Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.customerCost}
            {" €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Input
            // variant="unstyled"
            placeholder="Price"
            value={priceInput}
            onChange={(e) => setPriceInput(e.target.value)}
            type="number"
            sx={{
              fontSize: 14,

              height: "30px",
              width: "100px",
              padding: "0px 10px",

              "&:focus": {
                borderColor: Colors.themeColor,
                boxShadow: `0 0 0 1px ${Colors.themeColor}`,
              },
            }}
          />
        </Flex>
      </>
    );
  } else if (
    type === "driver" &&
    shipment.organizationCost &&
    shipment.customerCost
  ) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Customer Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.customerCost}
            {" €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.organizationCost}
            {" €"}
          </Text>
        </Flex>

        {shipment.transportCost && (
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.transportCost}
              {" €"}
            </Text>
          </Flex>
        )}
      </>
    );
  }

  if (type === "organization2" && shipment.organizationCost && !editState) {
    return (
      <>
        {" "}
        {shipment.organization2 !== currentUser.id && (
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              {shipment.organization === currentUser.id
                ? "Earnings"
                : "Organization Cost:"}
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.customerCost}
              {" €"}
            </Text>
          </Flex>
        )}
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            {shipment.organization2 === currentUser.id
              ? "Organization Cost:"
              : "Cost:"}
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.organizationCost + " €"}
          </Text>
          {shipment.status === SHIPMENT.PENDING && (
            <Iconify
              onClick={() => {
                setEditState(true);
                setPriceInput(shipment.organizationCost);
              }}
              icon="material-symbols:edit"
              sx={{
                cursor: "pointer",
                mb: "4px",
              }}
            />
          )}
        </Flex>
      </>
    );
  } else if (
    type === "organization2" &&
    !shipment.organizationCost &&
    shipment.organization === currentUser.id
  ) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Organization Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.customerCost}
            {" €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Organization2 Cost:
          </Text>
          <Input
            // variant="unstyled"
            placeholder="Price"
            value={priceInput}
            onChange={(e) => setPriceInput(e.target.value)}
            type="number"
            sx={{
              fontSize: 14,

              height: "30px",
              width: "100px",
              padding: "0px 10px",

              "&:focus": {
                borderColor: Colors.themeColor,
                boxShadow: `0 0 0 1px ${Colors.themeColor}`,
              },
            }}
          />
        </Flex>
      </>
    );
  } else if (
    type === "organization2" &&
    shipment.organizationCost &&
    shipment.customerCost
  ) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Customer Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.customerCost}
            {" €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Text fontSize={14} fontWeight={600} mr={1}>
            {shipment.organizationCost}
            {" €"}
          </Text>
        </Flex>

        {shipment.transportCost && (
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Text fontSize={14} fontWeight={600} mr={1}>
              {shipment.transportCost}
              {" €"}
            </Text>
          </Flex>
        )}
      </>
    );
  }

  return <></>;
};

const TransportPriceInputField = ({
  shipment,
  type,
  priceInput,
  setPriceInput,
  editState,
  setEditState,
}) => {
  console.log("TransportPriceInputField", {
    type,
    priceInput,
    setPriceInput,
    editState,
    setEditState,
  });
  if (type === "driver" && shipment.transportCost && false) {
    return (
      <Flex mt={1} alignItems="center">
        <Text fontSize={14} mr={1}>
          Transportation Cost:
        </Text>
        <Text fontSize={14} fontWeight={600} mr={1}>
          {shipment.transportCost}
        </Text>
        <Iconify
          onClick={() => {
            setEditState(true);
            setPriceInput(shipment.transportCost);
          }}
          icon="material-symbols:edit"
          sx={{
            cursor: "pointer",
            mb: "4px",
          }}
        />
      </Flex>
    );
  } else if (type === "driver" && shipment.transportCost) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Transportation Cost:
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {shipment.organizationCost + " €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {shipment.transportCost + " €"}
          </Text>
        </Flex>
      </>
    );
  } else if (type === "driver") {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Transportation Cost:
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {shipment.organizationCost + " €"}
          </Text>
        </Flex>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Driver Cost:
          </Text>
          <Input
            // variant="unstyled"
            placeholder="Price"
            value={priceInput}
            onChange={(e) => setPriceInput(e.target.value)}
            type="number"
            sx={{
              fontSize: 14,

              height: "30px",
              width: "100px",
              padding: "0px 10px",

              "&:focus": {
                borderColor: Colors.themeColor,
                boxShadow: `0 0 0 1px ${Colors.themeColor}`,
              },
            }}
          />
          <Text ml={1}>€</Text>
        </Flex>
      </>
    );
  }

  if (type === "organization" && shipment.organizationCost) {
    return (
      <>
        <Flex mt={1} alignItems="center">
          <Text fontSize={14} mr={1}>
            Transportation Cost:
          </Text>
          <Text fontSize={14} fontWeight={600}>
            {shipment.organizationCost + " €"}
          </Text>
        </Flex>

        {shipment.transportCost && (
          <Flex mt={1} alignItems="center">
            <Text fontSize={14} mr={1}>
              Driver Cost:
            </Text>
            <Text fontSize={14} fontWeight={600}>
              {shipment.transportCost + " €"}
            </Text>
          </Flex>
        )}
      </>
    );
  }

  return <></>;
};

const ShipmentChatBox = ({ shipment, other, self, setCurrentShipment }) => {
  const history = useHistory();
  const location = useLocation();

  const [priceInput, setPriceInput] = useState(shipment.amount);

  const [editState, setEditState] = useState(false);

  const { currentUser } = useSelector((state) => state.ui);
  const {
    updateShipment,
    createInvoiceForOrganization,
    sendShipmentStatusEmail,
    setSnackbarAction,
  } = useAPIActions();

  console.log("ShipmentChatBox", {
    other,
    self,
    shipment,
    currentUser,
  });

  useEffect(() => {
    if (other.type === "customer" && !shipment.customerCost) {
      setEditState(true);
    } else if (other.type === "driver" && !shipment.organizationCost) {
      setEditState(true);
    } else if (other.type === "driver" && !shipment.transportCost) {
      setEditState(true);
    }
  }, [shipment]);

  const onAcceptClick = () => {
    console.log("onSaveClick =>", { priceInput, other, shipment });

    let updatedShipmentObject = {};
    let emailObject = {};

    if (other.type === "customer" || shipment.customer === other.receiverId) {
      updatedShipmentObject = {
        customerCost: priceInput,
        status: SHIPMENT.ACCEPTED,
      };

      emailObject = {
        shipmentNumber: shipment.shipmentNumber,
        to: other.email,
        status: "Accepted",
        notificationData: {
          organization: shipment.customer,
          heading: "Shipment Accepted",
          message: `Shipment#${shipment.shipmentNumber} has been accepted`,
        },
      };

      setSnackbarAction({
        open: true,
        message: "Shipment Accepted",
        severity: "success",
      });
    } else if (other.type === "transport") {
      updatedShipmentObject = {
        organizationCost: priceInput,
        status: SHIPMENT.ASSIGNED,
        transport: other.receiverId,
      };

      emailObject = {
        shipmentNumber: shipment.shipmentNumber,
        to: other.email,
        status: "Assigned",
      };

      setSnackbarAction({
        open: true,
        message: "Shipment Assigned",
        severity: "success",
      });
    } else if (other.type === "driver") {
      if (
        (shipment.organization2?._id || shipment.organization2) ===
        currentUser.id
      ) {
        updatedShipmentObject = {
          transportCost: priceInput,
          status: SHIPMENT.DRIVER_ASSIGNED,
          driver: other.receiverId,
        };
      } else {
        updatedShipmentObject = {
          organizationCost: priceInput,
          status: SHIPMENT.DRIVER_ASSIGNED,
          driver: other.receiverId,
        };
      }

      emailObject = {
        shipmentNumber: shipment.shipmentNumber,
        to: other.email,
        status: "Assigned",
        notificationData: {
          driver: other.receiverId,
          heading: "Shipment Assigned",
          message: `Shipment#${shipment.shipmentNumber} has been assigned`,
        },
      };

      console.log("emailObject", { emailObject });

      setSnackbarAction({
        open: true,
        message: "Shipment Assigned to Driver",
        severity: "success",
      });
    } else if (other.type === "organization2") {
      updatedShipmentObject = {
        organizationCost: priceInput,
        status: SHIPMENT.ASSIGNED,
        organization2: other.receiverId,
      };
      emailObject = {
        shipmentNumber: shipment.shipmentNumber,
        to: other.email,
        status: "Assigned",
        notificationData: {
          driver: other.receiverId,
          heading: "Shipment Assigned",
          message: `Shipment#${shipment.shipmentNumber} has been assigned`,
        },
      };

      console.log("emailObject", { emailObject, updatedShipmentObject });

      setSnackbarAction({
        open: true,
        message: "Shipment Assigned to Organization",
        severity: "success",
      });
    }

    updateShipment(
      shipment._id,
      updatedShipmentObject,
      currentUser,
      (response) => {
        setCurrentShipment(response);
        setEditState(false);

        sendShipmentStatusEmail({
          ...emailObject,
        });

        if (other.type === "driver") {
          //createInvoiceForOrganization(response);
        }

        //  createInvoice()
      }
    );
  };

  const onCancelClick = () => {};

  const onRejectClick = () => {
    updateShipment(
      shipment._id,
      {
        status: SHIPMENT.CANCELLED,
      },
      currentUser,
      (response) => {
        setCurrentShipment(response);
        setEditState(false);
      }
    );
  };

  // console.log("ShipmentChatBox shipment", shipment);

  return (
    <ShipmentChatBoxStyle type={other.type} status={shipment.status}>
      <Flex flexDirection="column" justifyContent="center" flex={1}>
        <Flex justifyContent="center" mb={4}>
          <Iconify
            icon="carbon:delivery-add"
            sx={{ height: 25, width: 25, mr: 1 }}
          />
          <Text fontSize={20} fontWeight={500}>
            Shipment
          </Text>
        </Flex>

        <Flex flexDirection="column" mb={4}>
          <Flex mb={1}>
            <Text fontSize={14} mr={1}>
              Shipment No:
            </Text>
            <Text fontSize={14} fontWeight={600}>
              #{shipment.shipmentNumber}
            </Text>
          </Flex>
          <Flex mb={1}>
            <Text fontSize={14} mr={1}>
              Status :
            </Text>
            <ChakraText
              fontSize={14}
              fontWeight={600}
              color={getColor(shipment.status)}
              textTransform="uppercase"
            >
              {shipment.status === SHIPMENT.POD_ATTACHED
                ? "POD-Attached"
                : shipment.status === SHIPMENT.DRIVER_ASSIGNED
                ? "Driver-Assigned"
                : shipment.status}
            </ChakraText>
          </Flex>

          <Flex mb={1}>
            <Text fontSize={14} mr={1}>
              Goods :
            </Text>
            <Text fontSize={14} fontWeight={600}>
              {shipment.goods.join(",")}
            </Text>
          </Flex>
          <Flex mb={1}>
            <Text fontSize={14} mr={1}>
              Weight :
            </Text>
            <Text fontSize={14} fontWeight={600}>
              {shipment.weight} Kg
            </Text>
          </Flex>
          {(currentUser.type === "customer" ||
            currentUser.id === shipment.customer) && (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Cost :
              </Text>
              <Text fontSize={14} fontWeight={600}>
                {shipment.customerCost ? shipment.customerCost + " €" : "[TBD]"}
              </Text>
            </Flex>
          )}
          {currentUser.type === "driver" && shipment.organization2 ? (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Driver Cost :
              </Text>
              <Text fontSize={14} fontWeight={600}>
                {shipment.transportCost
                  ? shipment.transportCost + " €"
                  : "[TBD]"}
              </Text>
            </Flex>
          ) : currentUser.id !== shipment.customer &&
            shipment.transportCost &&
            shipment.organization !== currentUser.id ? (
            <Flex mt={1} alignItems="center">
              <Text fontSize={14} mr={1}>
                Driver Cost :
              </Text>
              <Text fontSize={14} fontWeight={600}>
                {shipment.transportCost
                  ? shipment.transportCost + " €"
                  : "[TBD]"}
              </Text>
            </Flex>
          ) : (
            shipment.organization === currentUser.id &&
            !shipment.organization2 &&
            shipment.transportCost && (
              <>
                <Flex mt={1} alignItems="center">
                  <Text fontSize={14} mr={1}>
                    Driver Cost :
                  </Text>
                  <Text fontSize={14} fontWeight={600}>
                    {shipment.transportCost
                      ? shipment.transportCost + " €"
                      : "[TBD]"}
                  </Text>
                </Flex>
              </>
            )
          )}

          {currentUser.type === "organization" &&
            currentUser.id !== shipment.customer && (
              <OrganizationPriceInputField
                shipment={shipment}
                organization2={
                  (shipment.organization2?._id || shipment.organization2) ===
                  currentUser.id
                }
                currentType={currentUser.type}
                type={other.type}
                currentUser={currentUser}
                other={other}
                priceInput={priceInput}
                setPriceInput={setPriceInput}
                editState={editState}
                setEditState={setEditState}
              />
            )}

          {currentUser.type === "transport" && (
            <TransportPriceInputField
              shipment={shipment}
              type={other.type}
              priceInput={priceInput}
              setPriceInput={setPriceInput}
              editState={editState}
              setEditState={setEditState}
            />
          )}
        </Flex>

        {(currentUser.type === "organization" && other.type === "customer") ||
        (shipment.customer === other.receiverId &&
          shipment.status === SHIPMENT.PENDING) ? (
          <Flex direction="row" width="100%">
            <CustomButton
              width="100%"
              onClick={onRejectClick}
              mr={1}
              variant={"outlined"}
            >
              <Text fontSize={14} fontWeight={600}>
                Reject
              </Text>
            </CustomButton>
            <CustomButton
              disabled={!priceInput}
              width="100%"
              onClick={onAcceptClick}
            >
              <Text fontSize={14} fontWeight={600}>
                Accept
              </Text>
            </CustomButton>
          </Flex>
        ) : currentUser.type === "organization" &&
          (other.type === "driver" ||
            (other.type === "organization2" &&
              other.receiverId === shipment.customer)) &&
          shipment.organization !== currentUser.id &&
          shipment.status === SHIPMENT.ACCEPTED ? (
          <Flex direction="row" width="100%">
            <CustomButton
              disabled={!priceInput}
              width="100%"
              onClick={onAcceptClick}
            >
              <Text fontSize={14} fontWeight={600}>
                Assign
              </Text>
            </CustomButton>
          </Flex>
        ) : currentUser.type === "organization" &&
          other.type === "organization2" &&
          other.receiverId !== shipment.customer &&
          (other.receiverId === currentUser.id ||
            shipment.organization === currentUser.id) &&
          shipment.status === SHIPMENT.ACCEPTED ? (
          <Flex direction="row" width="100%">
            <CustomButton
              disabled={!priceInput}
              width="100%"
              onClick={onAcceptClick}
            >
              <Text fontSize={14} fontWeight={600}>
                Assign
              </Text>
            </CustomButton>
          </Flex>
        ) : currentUser.type === "organization" &&
          other.type === "driver" &&
          (shipment.organization2?._id || shipment.organization2) ===
            currentUser.id &&
          shipment.status === SHIPMENT.ASSIGNED ? (
          <Flex direction="row" width="100%">
            <CustomButton
              disabled={!priceInput}
              width="100%"
              onClick={onAcceptClick}
            >
              <Text fontSize={14} fontWeight={600}>
                Assign
              </Text>
            </CustomButton>
          </Flex>
        ) : currentUser.type === "organization" &&
          other.type === "driver" &&
          shipment.status === SHIPMENT.ACCEPTED ? (
          <Flex direction="row" width="100%">
            {/* <CustomButton
              width="100%"
              onClick={onRejectClick}
              mr={1}
              variant={"outlined"}
            >
              <Text fontSize={14} fontWeight={600}>
                Reject
              </Text>
            </CustomButton> */}
            <CustomButton
              disabled={!priceInput}
              width="100%"
              onClick={onAcceptClick}
            >
              <Text fontSize={14} fontWeight={600}>
                Assign
              </Text>
            </CustomButton>
          </Flex>
        ) : (
          <CustomButton
            onClick={() =>
              history.push(
                `${location.pathname
                  .toLowerCase()
                  .split("/")
                  .slice(0, 2)
                  .join("/")}/shipments/${shipment._id}`
              )
            }
          >
            <Text>View Details</Text>
          </CustomButton>
        )}
      </Flex>
    </ShipmentChatBoxStyle>
  );
};

export default ShipmentChatBox;
