import {
  RESET_DRIVER_DATA,
  SET_ALL_DRIVERS,
  SET_DRIVER,
  SET_DRIVER_CHATS,
  SET_DRIVER_CHATS_MESSAGES,
} from "../actions/driverActions";

const initialState = {
  driver: null,
  drivers: [],

  chats: {},
  chatsMessages: {},
};

export const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_DRIVERS:
      return { ...state, drivers: action.payload };
    case SET_DRIVER:
      return { ...state, driver: action.payload };

    case SET_DRIVER_CHATS:
      return { ...state, chats: action.payload };

    case SET_DRIVER_CHATS_MESSAGES:
      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [action.payload.chatroomId]: action.payload.messages,
        },
      };

    case RESET_DRIVER_DATA:
      return initialState;

    default:
      return state;
  }
};
