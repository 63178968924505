import React, { useEffect } from "react";
import { Box, styled } from "@mui/material";
import Text from "../../components/Text";
import CustomModal from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { Avatar, Flex } from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import { useHistory } from "react-router-dom";
import { useAPIActions } from "../../hooks/useActions";
import useResponsive from "../../hooks/useResponsive";

const TransportSearchModalStyle = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  borderRadius: theme.spacing(2),
  minHeight: 150,
  maxHeight: 500,
  padding: "20px",
  minWidth: !isMobile && 500,

  width: isMobile && "95vw",

  //commenting it for now
  // [theme.breakpoints.down("500")]: {
  //   width: "90vw",
  // },
}));

const TransportSearchModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 400,
  paddingTop: 10,

  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
const TransportSearchModal = ({ shipmentId, open, setOpen, search }) => {
  const { transports } = useSelector((state) => state.transport);
  const { organization, chats } = useSelector((state) => state.organization);

  const { currentUser, searchResults } = useSelector((state) => state.ui);

  const isMobile = useResponsive("down", "600");

  const {
    createChatroomForOrganization,
    updateShipment,
    setShipmentIdToBeAssigned,
  } = useAPIActions();

  const history = useHistory();

  // console.log("transportsModal", {
  //   transports,
  //   currentUser,
  //   searchResults,
  //   search,
  // });

  const onChatClick = (transportId) => {
    setOpen(false);
    if (Object.values(chats).find((item) => item.transport === transportId)) {
      const chatItem = Object.values(chats).find(
        (item) => item.transport === transportId
      );

      console.log("onSendClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id },
      });
    } else {
      createChatroomForOrganization(organization._id, transportId, (chatId) => {
        console.log("createChatroomForOrganization chatId", chatId);
        setTimeout(() => {
          history.push({
            pathname: "/organization/chat",
            state: { chatId },
          });
        }, 1000);
      });
    }
  };

  const onSendClick = (transportId) => {
    setOpen(false);

    setShipmentIdToBeAssigned(shipmentId);
    console.log("chatId", { transportId, chats, organization });
    if (Object.values(chats).find((item) => item.transport === transportId)) {
      const chatItem = Object.values(chats).find(
        (item) => item.transport === transportId
      );

      console.log("onSendClick chatItem", chatItem);
      history.push({
        pathname: "/organization/chat",
        state: { chatId: chatItem._id, shipmentId },
      });
    } else {
      createChatroomForOrganization(organization._id, transportId, (chatId) => {
        console.log("createChatroomForOrganization chatId", chatId);
        setTimeout(() => {
          history.push({
            pathname: "/organization/chat",
            state: { chatId, shipmentId },
          });
        }, 1000);
      });
    }

    // updateShipment(
    //   shipmentId,
    //   {
    //     status: "assigned",
    //     transport: transportId,
    //   },
    //   currentUser,

    //   () =>

    //   {}
    // );
  };

  const RenderSingleTransport = ({ transport }) => {
    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Flex alignItems="center">
          <Avatar src={transport.logo} border="1px solid #000" mr={3} />
          <Flex flexDirection="column" justifyContent="center">
            <Text>{transport.companyName}</Text>
            {/* <Text>{transport._id}</Text> */}
          </Flex>
        </Flex>
        {search ? (
          <CustomButton onClick={() => onChatClick(transport._id)}>
            <Text>Chat</Text>
          </CustomButton>
        ) : (
          <CustomButton onClick={() => onSendClick(transport._id)}>
            <Text>Send Shipment</Text>
          </CustomButton>
        )}
      </Flex>
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <TransportSearchModalStyle isMobile={isMobile}>
        <Text center fontSize={22} fontWeight={600} mb={2}>
          {search ? `Search Results for "${search}"` : "All Transports"}
        </Text>

        <TransportSearchModalContentContainer>
          {!search && transports.length > 0 ? (
            transports.map((transport, index) => (
              <RenderSingleTransport key={index} transport={transport} />
            ))
          ) : searchResults.length > 0 ? (
            searchResults.map((transport, index) => (
              <RenderSingleTransport key={index} transport={transport} />
            ))
          ) : (
            <Text center>No Transport Found</Text>
          )}
        </TransportSearchModalContentContainer>
      </TransportSearchModalStyle>
    </CustomModal>
  );
};
export default TransportSearchModal;
