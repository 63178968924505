export const organizationUsersDataComplex = [
  // {
  //   Header: "#",
  //   accessor: "count",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Role",
    accessor: "role",
  },
];

export const cleanOrganizationUsersData = (data) => {
  const cleanData = data.map((item, index) => {
    console.log("cleanOrganizationUsersData", item);
    const { name, email } = item;
    return {
      count: `User ${index + 1}`,
      name,
      email,
      role: item.role,
      permissions: item.permissions,
      role: item?.role ? item.role.toUpperCase() : "USER",
    };
  });
  return cleanData;
};

export const cleanOrganizationRequestsData = (data) => {
  const cleanData = data.map((item, index) => {
    console.log("cleanOrganizationRequestsData", item);
    const { organization1, organization2, status } = item;
    return {
      from: organization1,
      to: organization2,
      status,
      action: {
        organization1: organization1._id,
        organization2: organization2._id,
        requestId: item._id,
      },
    };
  });
  return cleanData;
};

export const cleanOrganizationConnectionsData = (data) => {
  const cleanData = data.map((item, index) => {
    console.log("cleanOrganizationConnectionsData", item);
    const { companyName, logo, address, hourlyRate, rateList, _id } = item;
    return {
      organization: {
        name: companyName,
        logo,
        hourlyRate,
        rateList,
        address,
      },

      address: address,
      action: {
        organizationId: _id,
      },
    };
  });
  return cleanData;
};
