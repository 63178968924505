// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
import React, { useEffect, useState } from "react";

import ChatList from "./ChatList";
import useResponsive from "../../../hooks/useResponsive";
import { useSelector } from "react-redux";
import { useAPIActions } from "../../../hooks/useActions";
import {
  transformOrganizationChats,
  transformTransportChats,
} from "../../../utils";
import { styled, Box as MuiBox } from "@mui/material";
import ChatView from "../../sharedComponents/chat/ChatView";
import { useLocation } from "react-router-dom";

const MobileChatStyle = styled(MuiBox)(({ theme, currentChat }) => ({
  position: "absolute",
  top: !currentChat ? 130 : 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: currentChat && 1000,
  backgroundColor: "#fff",
}));

export default function Chat() {
  const onChatHistoryOpen = () => {};
  const onChatFilesOpen = () => {};

  const [currentChat, setCurrentChat] = useState(null);
  const isDesktop = useResponsive("up", "900");

  const socket = useSelector((state) => state.ui.socket);

  const location = useLocation();

  const { chatsMessages, transport, chats } = useSelector(
    (state) => state.transport
  );

  const { shipmentIdToBeAssigned } = useSelector((state) => state.shipment);

  console.log("location", { location, chatsMessages });

  const {
    listenToTransportSocketEvents,
    getTransportChatroomMessages,
    sendTransportChatMessage,
    setShipmentIdToBeAssigned,
  } = useAPIActions();

  useEffect(() => {
    console.log("chat socket", socket);
    if (socket) {
      listenToTransportSocketEvents();
    }
  }, [socket]);

  useEffect(() => {
    if (
      Object.keys(chats).length > 0 &&
      location.state &&
      location.state.chatId
    ) {
      const selectedChat = transformTransportChats([
        chats[location.state.chatId],
      ])[0];

      console.log("location.state", location.state);

      getTransportChatroomMessages(selectedChat.chatroomId, () => {
        console.log("messages fetched");
        setCurrentChat(selectedChat);

        if (location.state && shipmentIdToBeAssigned) {
          sendTransportChatMessage(
            "",
            transport._id,
            selectedChat.receiverId,
            selectedChat.chatroomId,
            "shipment",
            null,
            shipmentIdToBeAssigned
          );

          setShipmentIdToBeAssigned(null);
        }
      });
    } else if (Object.keys(chats).length > 0) {
      console.log("init chats", chats);

      const firstChat = transformTransportChats([
        chats[Object.keys(chats)[0]],
      ])[0];
      console.log("first chat", firstChat);
      getTransportChatroomMessages(firstChat.chatroomId, () => {
        console.log("messages fetched");
        setCurrentChat(firstChat);
      });
    }
  }, [chats, location]);

  const onSingleChatClick = (chat) => {
    console.log("chat", { chat, currentChat });

    if (!currentChat || currentChat?.chatroomId !== chat.chatroomId) {
      getTransportChatroomMessages(chat.chatroomId, () => {
        console.log("messages fetched");
        setCurrentChat(chat);
      });
    }
  };

  const onSendChatMessage = (message) => {
    if (message.type === "image") {
      sendTransportChatMessage(
        message.chatInput,
        transport._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "image",
        message.url
      );
    } else if (message.type === "shipment") {
      sendTransportChatMessage(
        message.chatInput,
        transport._id,
        currentChat.receiverId,
        currentChat.chatroomId,
        "shipment",
        null,
        message.shipmentId
      );
    } else {
      sendTransportChatMessage(
        message.chatInput,
        transport._id,
        currentChat.receiverId,
        currentChat.chatroomId
      );
    }
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="100%">
        {isDesktop ? (
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={4}
            height="100%"
            width="100%"
          >
            <GridItem colSpan={currentChat ? 3 : 12}>
              <ChatList
                chatsList={
                  Object.keys(chats).length > 0
                    ? transformTransportChats(Object.values(chats))
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            </GridItem>

            {currentChat && (
              <GridItem colSpan={9}>
                <ChatView
                  other={currentChat}
                  self={transport}
                  onSendChatMessage={onSendChatMessage}
                  onChatHistoryOpen={onChatHistoryOpen}
                  onChatFilesOpen={onChatFilesOpen}
                  currentChat={currentChat}
                  messages={
                    Object.keys(chatsMessages).length > 0
                      ? chatsMessages[currentChat.chatroomId]
                      : []
                  }
                />
              </GridItem>
            )}
          </Grid>
        ) : (
          <MobileChatStyle currentChat={currentChat}>
            {currentChat ? (
              <ChatView
                other={currentChat}
                self={transport}
                onSendChatMessage={onSendChatMessage}
                mobile={true}
                onChatHistoryOpen={onChatHistoryOpen}
                onChatFilesOpen={onChatFilesOpen}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                messages={
                  Object.keys(chatsMessages).length > 0
                    ? chatsMessages[currentChat.chatroomId]
                    : []
                }
              />
            ) : (
              <ChatList
                chatsList={
                  Object.keys(chats).length > 0
                    ? transformTransportChats(Object.values(chats))
                    : []
                }
                //chatsList={chatsList}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                onSingleChatClick={onSingleChatClick}
              />
            )}
          </MobileChatStyle>
        )}
      </Box>
    </>
  );
}
