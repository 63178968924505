export const SET_ALL_ORGANIZATIONS = "SET_ALL_ORGANIZATIONS";
export const SET_ORGANIZATION_USERS = "SET_ORGANIZATION_USERS";
export const SET_ORGANIZATION_DRIVERS = "SET_ORGANIZATION_DRIVERS";
export const SET_ORGANIZATION_USER = "SET_ORGANIZATION_USER";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_ORGANIZATION_CHATS = "SET_ORGANIZATION_CHATS";
export const SET_ORGANIZATION_CHATS_MESSAGES =
  "SET_ORGANIZATION_CHATS_MESSAGES";
export const SET_GROUP_CHATS_MESSAGES = "SET_GROUP_CHATS_MESSAGES";

export const SET_ORGANIZATION_REQUESTS = "SET_ORGANIZATION_REQUESTS";
export const SET_ORGANIZATION_CONNECTIONS = "SET_ORGANIZATION_CONNECTIONS";

export const RESET_ORGANIZATION_DATA = "RESET_ORGANIZATION_DATA";

export const SET_GROUP_CHATS = "SET_GROUP_CHATS";
