// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";

import PropTypes from "prop-types";
import React, { useState } from "react";
// Assets
import navImage from "../../../assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";

import { ThemeEditor } from "./ThemeEditor";
import { NavLink } from "react-router-dom";
import { IconButton } from "@mui/material";

import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Iconify from "../../Iconify";
import routes from "../../../routes";
import { SidebarResponsive } from "../../sidebar/Sidebar";
import { SearchBar } from "../../navbar/searchBar/SearchBar";
import { ItemContent } from "../../menu/ItemContent";
import useResponsive from "../../../hooks/useResponsive";
import TransportSearchModal from "../../../views/shipment/TransportSearchModal";
import { useAPIActions } from "../../../hooks/useActions";
import OrganizationSearchModal from "../../../views/shipment/OrganizationSearchModal";
export default function NavbarLinksAdmin(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const history = useHistory();
  const location = useLocation();
  const isMobile = useResponsive("down", "600");

  const [searchInput, setSearchInput] = useState("");

  const [showTransportSearchModal, setShowTransportSearchModal] =
    useState(false);

  const { getOrganizationByName, resetEverything } = useAPIActions();

  const { organization } = useSelector((state) => state.organization);

  const { currentUser } = useSelector((state) => state.ui);

  const onSearchClick = () => {
    console.log("Search");
    setShowTransportSearchModal(true);
    //  if (searchInput) getTransportByName(searchInput);
    if (searchInput) getOrganizationByName(searchInput, organization._id);
  };

  return (
    <Flex flexDirection="row">
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        {/* <SearchBar
          mb={secondary ? { base: "10px", md: "unset" } : "unset"}
          me="10px"
          borderRadius="30px"
          onSearchClick={onSearchClick}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        /> */}
        {/* <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Mark all read
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Shipment 1001" aName="1001" />
              </MenuItem>
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Shipment 1001" aName="1002" />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu> */}

        {/* <Menu>
          <MenuButton p='0px'>
          <Icon
            mt='6px'
            as={MdInfoOutline}
            color={navbarIcon}
            w='18px'
            h='18px'
            me='10px'
          />
        </MenuButton> 
          <MenuList
            boxShadow={shadow}
            p="20px"
            me={{ base: "30px", md: "unset" }}
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            minW={{ base: "unset" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Image src={navImage} borderRadius="16px" mb="28px" />
            <Flex flexDirection="column">
              <Link w="100%" href="https://horizon-ui.com/pro">
                <Button w="100%" h="44px" mb="10px" variant="brand">
                  Buy Horizon UI PRO
                </Button>
              </Link>
              <Link
                w="100%"
                href="https://horizon-ui.com/documentation/docs/introduction"
              >
                <Button
                  w="100%"
                  h="44px"
                  mb="10px"
                  border="1px solid"
                  bg="transparent"
                  borderColor={borderButton}
                >
                  See Documentation
                </Button>
              </Link>
              <Link
                w="100%"
                href="https://github.com/horizon-ui/horizon-ui-chakra"
              >
                <Button
                  w="100%"
                  h="44px"
                  variant="no-hover"
                  color={textColor}
                  bg="transparent"
                >
                  Try Horizon Free
                </Button>
              </Link>
            </Flex>
          </MenuList>
        </Menu>

        <ThemeEditor navbarIcon={navbarIcon} /> */}

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              // name="Richard Vermeer"
              src={
                currentUser.role === "user"
                  ? currentUser.profilePicture
                  : props.state.company
                  ? props.state.company.logo
                  : "https://bit.ly/dan-abramov"
              }
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp;{" "}
                {currentUser.role === "user"
                  ? currentUser.name
                  : props.state.company
                  ? props.state.company.companyName
                  : "Richard Vermeer"}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
                onClick={() => history.push("/organization/profile")}
              >
                <Text fontSize="sm">Profile</Text>
              </MenuItem>
              {/* <NavLink to="/auth/sign-in">
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                >
                  <Text fontSize="sm">Log out</Text>
                </MenuItem>
              </NavLink> */}
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
      <SidebarResponsive routes={routes} />
      <IconButton
        color="theme"
        sx={{
          ml: !isMobile && 2,
          borderRadius: 5,
        }}
        onClick={() => {
          history.push(
            `/auth${location.pathname.split("/").slice(0, 2).join("/")}/sign-in`
          );
          localStorage.removeItem("tisnix_user_signed_in");
          localStorage.removeItem("tisnix_user_access_token");
          localStorage.removeItem("tisnix_user");
          resetEverything();
        }}
      >
        {!isMobile && (
          <Text fontSize={16} fontWeight={500}>
            Logout
          </Text>
        )}
        <Iconify icon="ic:baseline-logout" sx={{ ml: 1 }} />
      </IconButton>

      <OrganizationSearchModal
        open={showTransportSearchModal}
        setOpen={setShowTransportSearchModal}
        search={searchInput}
      />
    </Flex>
  );
}

NavbarLinksAdmin.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
